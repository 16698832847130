export const types = {
  // Login
  login: "[Auth] Login",
  logInUser: "[Auth] LogInUser",
  isLoadingAuth: "[Auth] isLoadingAuth",
  logout: "[Auth] Logout",
  registerUser: "[Auth] Register",
  isDoingLogIn: "[Auth] Register",
  errorMsg: "[Auth] Error",

  //users
  getUserData: "[User] Get current user data",
  getAllUsers: "[User] Get all users data",
  resetUserData: "[User] Reset current user data",
  resetUserNotifications: "[User] Reset current user notifications",
  isLoadingUserData: "[User] isLoadingUserData",
  isLoading: "[User] isLoadingUser",
  getMakerUsers: "[Dreamer] Get maker users",
  getFollows: "[User] getFollows",
  getOneFollow: "[User] get one follow",
  cleanFollows: "[User] cleanFollows",
  getUserNotifications: "[User] get userNotifications",
  setLanguage: "[User] Language",

  setUserSelection: "[Auth] Set user selection",
  setUserCategory: "[User] Select category",

  //Maker
  toggleMenu: "[Maker] toggleMenu",
  getAllMakers: "[Makers] Get all makers",
  isLoading: "[Makers] isLoading",
  setDreamFilters: "[Makers] set dream filters",

  //Dreamer
  getPosts: "[Dreamer] Get posts",
  getDreamsBatchSize: "[Dreamer] Get dreams batch size",
  getAllDreams: "[Dreamer] Get all dreams",
  getMakersByDream: "[Dreamer] Get makers from one dreams",
  getFilterType: "[Dreamer] Get filter type",
  isLoadingDreamer: "[User] isLoadingDreamer",
  isLoadingDreams: "[Dreamer] isLoadingDreams",
  isLoadingFollowers: "[Dreamer] isLoadingFollowers",
  isCreatingImages: "[Dreamer] isCreatingImages",
  getJustCreatedPost: "[Dreamer] get recently created post",
  getFollowers: "[Dreamer] Get followers",
  getDreamers: "[Dreamer] Get dreamers",

  //Events
  getEvents: "[Event] Get events",
  getEventsByMonth: "[Event] Get events by month",
  getEventsByWeek: "[Event] Get events by week",
  isLoadingEvents: "[Event] isLoadingEvents",
};
