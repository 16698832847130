import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import dunmmyImage from "../../../assets/img/bg-dreamer-card.png";
import { FiMoreVertical, FiCheck } from "react-icons/fi";
import { useSelector } from "react-redux";

const DreamCard = ({ dreamData }) => {
  const theme = useTheme();
  const { general } = useSelector((state) => state.loggedUser.language);
  const [dreamDay, setDreamDay] = useState("");
  const [dreamWeekDay, setDreamWeekDay] = useState("");
  const [dreamMonthAndYear, setDreamMonthAndYear] = useState("");
  const [dreamTime, setDreamTime] = useState("");
  const [dreamCurrentState, setDreamCurrentState] = useState(general?.pending);

  useEffect(() => {
    if (dreamData) {
      const dateJSformat = new Date(
        dreamData.cdate.seconds * 1000 + dreamData.cdate.nanoseconds / 100000
      );
      //const dateJSformat = eventData.eventDate.toDate();
      setDreamDay(dateJSformat.getDate());
      setDreamWeekDay(
        dateJSformat.toLocaleDateString("CR", {
          weekday: "long",
        })
      );
      setDreamMonthAndYear(
        dateJSformat.toLocaleDateString("CR", {
          month: "short",
          year: "numeric",
        })
      );

      setDreamTime(
        dateJSformat.toLocaleTimeString("es-CR", {
          hour12: true,
          hour: "2-digit",
        })
      );

      if (dreamData.state === "active") {
        setDreamCurrentState(general?.active);
      } else if (dreamData.state === "rejected") {
        setDreamCurrentState(general?.rejected);
      }
    }
  }, [dreamData]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: "none",
        borderRadius: "12px",
        width: "100%",
        display: "flex",
        alignContent: "space-between",
        flexDirection: "column",
        minWidth: "100%",
        height: "500px",
        maxHeight: "500px",
        [theme.breakpoints.up("sm")]: {
          height: "500px",
          maxHeight: "500px",
        },
        [theme.breakpoints.up("md")]: {
          height: "500px",
          maxHeight: "500px",
        },
      }}
    >
      <CardMedia
        sx={{
          padding: { xs: "20px", md: "40px" },
          flex: 1,
          maxHeight: "250px",
        }}
      >
        <Box
          component="img"
          alt={dreamData.name || "event"}
          src={dreamData.imgURL || dunmmyImage}
          sx={{
            width: "100%",
            objectFit: "cover",
            height: "100%",
            borderRadius: "12px",
          }}
        />
      </CardMedia>

      <CardContent
        sx={{
          padding: { xs: "20px", md: "40px" },
          flex: 1,
          position: "relative",
        }}
      >
        <Stack direction="row">
          <Stack direction="row" gap={1} mr="auto" alignItems="center">
            <span
              style={{
                border: "2px solid rgba(143, 146, 161, 0.2)",
                borderRadius: "15px",
                padding: "10px 15px",
              }}
            >
              <Typography variant="h5">{dreamDay}</Typography>
            </span>
            <Stack>
              <Typography textTransform="capitalize">{dreamWeekDay}</Typography>
              <Typography textTransform="capitalize">
                {dreamMonthAndYear}
              </Typography>
            </Stack>
          </Stack>

          {/* <span>
            <FiMoreVertical size={22} color="#8F92A1" />
          </span> */}
        </Stack>

        <Stack
          gap={1}
          my={2}
          sx={{
            display: "flex",
            overflowX: "hidden",
            minHeight: "50px",
            overflow: "hidden",
          }}
        >
          {/* <Link
            to={`/home/dreamer/event/${dreamData.id}`}
            style={{ textDecoration: "none" }}
          > */}
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              cursor: "pointer",
              color: "#000",
              wordWrap: "break-word", // Ensures words break and wrap correctly
              whiteSpace: "normal", // Allows text to wrap and not stay in a single line
            }}
          >
            {dreamData.name}
          </Typography>
          {/* </Link> */}
          {/* <Typography sx={{ color: "#8F92A1" }}>
            {`Inicia a las ${dreamTime}, online`}
          </Typography> */}
        </Stack>

        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "4px",
                color: "#000",
                borderColor: "rgba(143, 146, 161, 0.2)",
              }}
            >
              <FiCheck />
              {dreamCurrentState}
            </Button>
          </Grid>
          <Grid
            item
            container
            justifyContent={"flex-end"}
            xs={8}
            alignItems={"center"}
          >
            <span style={{ width: "28px", height: "28px" }}>
              <img
                src="https://picsum.photos/id/96/200/300"
                width="100%"
                height="100%"
                alt="Usuario participante"
                style={{ borderRadius: "8px", objectFit: "cover" }}
              />
            </span>
            <span style={{ width: "28px", height: "28px" }}>
              <img
                src="https://picsum.photos/id/96/200/300"
                width="100%"
                height="100%"
                alt="Usuario participante"
                style={{ borderRadius: "8px", objectFit: "cover" }}
              />
            </span>
            <span
              style={{
                width: "28px",
                height: "28px",
                borderRadius: "8px",
                backgroundColor: "#1E1F20",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontSize="12px" sx={{ color: "#fff" }}>
                +9
              </Typography>
            </span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DreamCard;
