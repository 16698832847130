import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { debounce } from "lodash";

import { BiComment } from "react-icons/bi";
import { FaShare } from "react-icons/fa";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getPostImages,
  getRecentComments,
} from "../../../store/actions/dreamers";
import {
  createPostLike,
  deletePostLike,
} from "../../../store/actions/userActions";
import LikeHeartBtn from "../../likeHeartBtn/LikeHeartBtn";
import Visualizer from "../../visualizer/Visualizer";
import PostCommentSection from "../postCommentSection/PostCommentSection";
import PostHeader from "../postHeader/PostHeader";
import { timestampCreador } from "../../../firebase/firebase-config";
import Colors from "../../../utils/Colors";
import ShareModal from "../../share/ShareModal";

const PhotoCollectionPostCard = ({ data, elementIndex }) => {
  const theme = useTheme();
  const [postData, setPostData] = useState({ postImages: [] });
  const [postImages, setPostImages] = useState([]);
  const [postFirstComments, setPostFirstComments] = useState([]);
  const [openVisualizer, setOpenVisualizer] = useState(false);
  const [commentsNumber, setCommentsNumber] = useState(0);
  const [likesNumber, setLikesNumber] = useState(0);
  const [hasLike, setHasLike] = useState(false);
  const [newPostLikeId, setNewPostLikeId] = useState(false);
  const [postOwnerData, setPostOwnerData] = useState(null);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const currentUser = useSelector((state) => state.loggedUser.data);

  const isCreatingImages = useSelector(
    (state) => state.dreamers.isCreatingImages
  );
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const getImages = async () => {
      const images = await getPostImages(data.userOwnerId, data.id);
      setPostImages(images);
    };

    if (!!isCreatingImages === false) {
      getImages();
    }
  }, [isCreatingImages]);

  useEffect(() => {
    setPostData(data);
    setCommentsNumber(data?.commentsCount || 0);
    setLikesNumber(data?.likesCount || 0);

    const { postLikes } = data;

    // Check if the current user has liked the post
    const userLiked = postLikes?.some(
      (like) => like.userRef === currentUser.id
    );
    setHasLike(userLiked);
  }, [data]);

  useEffect(() => {
    const postId = data.id;
    const setNewComments = (comments) => {
      setPostFirstComments(comments);
    };

    const unsubscribe = getRecentComments(
      postId,
      setNewComments,
      data.userOwnerId,
      2,
      currentUser.id
    );

    return () => {
      unsubscribe();
    };
  }, [postData]);

  const handleLike = debounce(async () => {
    const postId = postData.id;
    const userId = postData.userOwnerId;
    const today = new Date();

    if (hasLike) {
      // Find the like to delete
      const likeId =
        postData.postLikes.find((like) => like.userRef === currentUser.id)
          ?.id || newPostLikeId;

      await deletePostLike(userId, postId, likeId);

      setHasLike(false);
      setLikesNumber((prevValue) => Math.max(prevValue - 1, 0));
      setPostData((prevData) => ({
        ...prevData,
        postLikes: prevData.postLikes.filter((like) => like.id !== likeId),
      }));
    } else {
      const cdate = timestampCreador.fromDate(today);
      const likeData = { userRef: currentUser.id, cdate };
      const fullName = `${currentUser.name} ${
        currentUser.lastname || ""
      }`.trim();

      const newLikeId = await createPostLike(
        userId,
        postId,
        likeData,
        fullName
      );

      setNewPostLikeId(newLikeId);
      setHasLike(true);
      setLikesNumber((prevValue) => prevValue + 1);
      setPostData((prevData) => ({
        ...prevData,
        postLikes: [
          ...prevData.postLikes,
          { id: newLikeId, userRef: currentUser.id },
        ],
      }));
    }
  }, 500);

  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Close the modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };
  //Removes scroll on mobile to avoid weird behaviors

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (openVisualizer) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }

    return () => {
      htmlElement.style.overflow = "auto";
    };
  }, [openVisualizer]);

  const createGrid = (postImages) => {
    if (postImages.length === 5) {
      return [6, 6, 6, 6];
    }

    if (postImages.length === 4) {
      return [12, 6, 6];
    }

    if (postImages.length === 3) {
      return [12, 12];
    }

    if (postImages.length === 2) {
      return 6;
    }
  };
  const shareUrl = `https://foundation-smileup.web.app/home/dreamer/profile/${postOwnerData?.id}`;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        marginTop: data?.type === "image" ? "40px" : "20px",
        padding: { md: "40px", xs: "25px" },
        borderRadius: "12px",
      }}
    >
      {data?.isLoading && elementIndex === 0 ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <PostHeader
            postData={data}
            postOwnerData={postOwnerData}
            setPostOwnerData={setPostOwnerData}
          />

          <Grid container spacing={2} mt={1}>
            {postImages?.length === 1 && (
              <Grid item xs={12}>
                <img
                  src={postImages[0]?.imgURL}
                  onClick={() => setOpenVisualizer(true)}
                  alt=""
                  style={{
                    borderRadius: "12px",
                    width: "100%",
                    maxWidth: "600px",
                    height: "auto",
                    objectFit: "cover",
                    cursor: "pointer",
                    maxHeight: "400px",
                  }}
                />
              </Grid>
            )}

            {postImages?.length === 2 && (
              <>
                {postImages.map((img, index) => (
                  <Grid item xs={6} key={index}>
                    <img
                      src={img.imgURL}
                      onClick={() => setOpenVisualizer(true)}
                      alt=""
                      style={{
                        borderRadius: "12px",
                        width: "100%",
                        height: "auto",
                        maxHeight: "400px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                ))}
              </>
            )}

            {postImages?.length === 3 && (
              <>
                <Grid item xs={12}>
                  <img
                    src={postImages[0]?.imgURL}
                    onClick={() => setOpenVisualizer(true)}
                    alt=""
                    style={{
                      borderRadius: "12px",
                      width: "100%",
                      maxHeight: "300px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    src={postImages[1]?.imgURL}
                    onClick={() => setOpenVisualizer(true)}
                    alt=""
                    style={{
                      borderRadius: "12px",
                      width: "100%",
                      maxHeight: "300px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    src={postImages[2]?.imgURL}
                    onClick={() => setOpenVisualizer(true)}
                    alt=""
                    style={{
                      borderRadius: "12px",
                      width: "100%",
                      maxHeight: "300px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            )}

            {postImages?.length > 3 && (
              <>
                <Grid item xs={8}>
                  <img
                    src={postImages[0]?.imgURL}
                    onClick={() => setOpenVisualizer(true)}
                    alt=""
                    style={{
                      borderRadius: "12px",
                      width: "100%",
                      maxHeight: "400px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    {postImages.slice(1, 3).map((img, index) => (
                      <Grid item xs={12} key={index}>
                        <img
                          src={img.imgURL}
                          onClick={() => setOpenVisualizer(true)}
                          alt=""
                          style={{
                            borderRadius: "12px",
                            width: "100%",
                            height: "auto",
                            maxHeight: "200px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} container>
              <Typography
                onClick={() => setIsExpanded((prev) => !prev)}
                sx={{
                  color: "#8F92A1",
                  fontWeight: "bold",
                  margin: "1rem 0",
                  overflow: isExpanded ? "visible" : "hidden",
                  display: isExpanded ? "block" : "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: isExpanded ? "none" : 3,
                  textOverflow: isExpanded ? "unset" : "ellipsis",
                  lineHeight: "1.5",
                  cursor: "pointer", // Add a pointer cursor for interactivity
                  wordWrap: "break-word",
                  fontSize: { xs: "14px", md: "16px" },
                }}
                variant="body1"
              >
                {postData?.description}
              </Typography>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent="space-between"
            marginTop="40px"
          >
            <Stack direction="row" gap={3}>
              <Stack direction="row" alignItems="center">
                <Box onClick={handleLike} component={"span"}>
                  <LikeHeartBtn hasLike={hasLike} />
                </Box>

                <Typography fontWeight="bold" ml={1}>
                  {likesNumber}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenVisualizer(true);
                  }}
                >
                  <BiComment />
                </span>
                <Typography fontWeight="bold" ml={1}>
                  {commentsNumber}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography
                component={"a"}
                fontWeight="bold"
                sx={{ color: Colors.secondary, cursor: "pointer", pr: 1 }}
                onClick={handleOpenShareModal}
              >
                {general?.share}
              </Typography>
              <FaShare color={Colors.secondary} />
            </Stack>
          </Stack>
        </>
      )}
      <PostCommentSection
        postFirstComments={postFirstComments}
        postId={data.id}
        postOwner={data.userOwnerId}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={handleCloseShareModal}
        shareUrl={shareUrl}
        shareTitle="Check out this awesome content!"
      />

      {openVisualizer && (
        <Visualizer
          openVisualizer={openVisualizer}
          handleClose={setOpenVisualizer}
          slides={postImages}
          post={postData}
          setCommentsNumber={setCommentsNumber}
          commentsNum={commentsNumber}
        />
      )}
    </Box>
  );
};

export default PhotoCollectionPostCard;
