import { Box, Stack, Typography } from "@mui/material";
import { BiHeart } from "react-icons/bi";
import LikeHeartBtn from "../../likeHeartBtn/LikeHeartBtn";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  createCommentLike,
  deleteCommentLike,
} from "../../../store/actions/userActions";
import { useSelector } from "react-redux";
import { timestampCreador } from "../../../firebase/firebase-config";
import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";

const PostComment = ({ postOwnerId, data, postId }) => {
  const [commentData, setCommentData] = useState({});
  const [hasLike, setHasLike] = useState(false);
  const [likesNumber, setLikesNumber] = useState(0);
  const language = useSelector((state) => state.loggedUser.language);

  const { english, spanish, lang } = language;
  const currentUser = useSelector((state) => state.loggedUser.data);
  console.log(lang);
  useEffect(() => {
    setCommentData(data);

    // Initialize likesNumber and hasLike
    const likes = data?.commentLikes || [];
    setLikesNumber(likes.length);

    const userLiked = likes.some((like) => like.userRef === currentUser.id);
    setHasLike(userLiked);
  }, [data, currentUser.id]);

  const calculateTimeSinceComment = () => {
    const today = new Date();
    const offsetTime = commentData.cdate?.toDate() || new Date();
    const timeDifference = today - offsetTime;

    const translations = {
      en: {
        justNow: "Just now",
        seconds: "seconds",
        minutes: "minutes",
        hours: "hours",
        days: "days",
      },
      es: {
        justNow: "Justo ahora",
        seconds: "segundos",
        minutes: "minutos",
        hours: "horas",
        days: "días",
      },
    };

    const t = translations[lang]; // Dynamically select the language

    if (timeDifference < 1000) return t.justNow;
    if (timeDifference < 60 * 1000)
      return `${Math.floor(timeDifference / 1000)} ${t.seconds}`;
    if (timeDifference < 60 * 60 * 1000)
      return `${Math.floor(timeDifference / (60 * 1000))} ${t.minutes}`;
    if (timeDifference < 24 * 60 * 60 * 1000)
      return `${Math.floor(timeDifference / (60 * 60 * 1000))} ${t.hours}`;
    return `${Math.floor(timeDifference / (24 * 60 * 60 * 1000))} ${t.days}`;
  };

  const handleLike = debounce(async () => {
    const commentId = commentData.id;
    const userId = postOwnerId;

    if (hasLike) {
      // Find the like object for the current user
      const userLike = commentData.commentLikes.find(
        (like) => like.userRef === currentUser.id
      );

      if (userLike) {
        await deleteCommentLike(userId, postId, commentId, userLike.id);
        setCommentData((prev) => ({
          ...prev,
          commentLikes: prev.commentLikes.filter(
            (like) => like.id !== userLike.id
          ),
        }));
        setLikesNumber((prev) => prev - 1);
        setHasLike(false);
      }
    } else {
      // Add a new like
      const cdate = timestampCreador.fromDate(new Date());
      const likeData = { userRef: currentUser.id, cdate };
      const fullName = `${currentUser.name} ${
        currentUser.lastname || ""
      }`.trim();

      const newLikeId = await createCommentLike(
        userId,
        postId,
        commentData,
        likeData,
        fullName
      );

      setCommentData((prev) => ({
        ...prev,
        commentLikes: [
          ...prev.commentLikes,
          { id: newLikeId, userRef: currentUser.id },
        ],
      }));
      setLikesNumber((prev) => prev + 1);
      setHasLike(true);
    }
  }, 500);

  const timeSinceComment = calculateTimeSinceComment();

  return (
    <Box>
      <Stack direction="row" alignItems="center" margin="20px 0">
        <Box
          sx={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img
            src={commentData?.commentOwnerData?.imgURL || noImagePlaceholder}
            alt="Comment Owner"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
          {commentData?.commentOwnerData?.name || "Usuario"}
        </Typography>
        <Typography
          sx={{ marginLeft: "auto", color: "#8F92A1", fontSize: "12px" }}
        >
          {timeSinceComment}
        </Typography>
      </Stack>

      <Typography color="#8F92A1" fontSize="14px">
        {commentData.content}
      </Typography>

      <Stack direction="row" gap={1} marginTop="20px" alignItems="center">
        <Box onClick={handleLike} component={"span"} sx={{ cursor: "pointer" }}>
          <LikeHeartBtn hasLike={hasLike} />
        </Box>
        <Typography fontWeight="bold">{likesNumber}</Typography>
      </Stack>
    </Box>
  );
};

export default PostComment;
