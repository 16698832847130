import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

const WeAre = ({ footer }) => {
  const links = [
    { url: "", label: footer?.links?.aboutUs?.link1 },
    { url: "#", label: footer?.links?.aboutUs?.link2 },
    { url: "#", label: footer?.links?.aboutUs?.link3 },
    { url: "#", label: footer?.links?.aboutUs?.link4 },
  ];

  return (
    <>
      <List dense={true}>
        <p className="text-white">{footer?.section2}</p>
        {links.map((link, index) => {
          return (
            <ListItem sx={{ paddingLeft: 0 }} key={index}>
              <ListItemText
                primary={
                  index === 0 ? (
                    <Link
                      to="/about-us"
                      style={{ color: "#ADABB7", textDecoration: "none" }}
                    >
                      Fundación
                    </Link>
                  ) : (
                    <a
                      href={link.url}
                      style={{ color: "#ADABB7", textDecoration: "none" }}
                    >
                      {link.label}
                    </a>
                  )
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default WeAre;
