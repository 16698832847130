import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/actions/userActions";
import { FlagIcon } from "react-flag-kit";
import TranslateIcon from "@mui/icons-material/Translate"; // 🆕 Import Translate Icon

export const LanguageDropdown = ({ color, borderColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.loggedUser.language);

  const { english, spanish, lang } = language;

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (lang) => {
    setAnchorEl(null);
    dispatch(setLanguage(lang));
  };

  return (
    <Box>
      {/* Mobile: Show an IconButton */}
      <IconButton
        aria-label="language"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleLanguageClick}
        sx={{
          display: { xs: "flex", md: "none" }, // 🆕 Show only on mobile
          color: color || "#fff",
          border: borderColor ? `solid 1px ${borderColor}` : "solid 1px white",
        }}
      >
        <FlagIcon code={lang === "en" ? "GB-ENG" : "ES"} size={22} />
      </IconButton>

      {/* Desktop: Show Button with Flag & Text */}
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleLanguageClick}
        sx={{
          display: { xs: "none", md: "flex" }, // 🆕 Hide on mobile
          color: color || "#fff",
          backgroundColor: "#00045",
          border: borderColor ? `solid 1px ${borderColor}` : "solid 1px white",
          px: 2,
          fontSize: 14,
        }}
      >
        <FlagIcon code={lang === "en" ? "GB-ENG" : "ES"} size={22} />
        <Typography
          variant="body1"
          sx={{ fontSize: 14, ml: 2, display: { xs: "none", sm: "flex" } }}
        >
          {lang === "en" ? english : spanish}
        </Typography>
      </Button>

      {/* Dropdown Menu */}
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleLanguageClose(lang)}
        sx={{ backgroundColor: "#00045 !important" }}
      >
        {lang === "es" && (
          <MenuItem onClick={() => handleLanguageClose("en")}>
            <FlagIcon code="GB-ENG" size={22} />
            <Box ml={2}>{english}</Box>
          </MenuItem>
        )}
        {lang === "en" && (
          <MenuItem onClick={() => handleLanguageClose("es")}>
            <FlagIcon code="ES" size={22} />
            <Box ml={2}>{spanish}</Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default LanguageDropdown;
