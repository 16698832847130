import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const AboutUs = ({ footer }) => {
  const links = [
    { url: "#", label: footer?.links?.legal?.link1 },
    { url: "#", label: footer?.links?.legal?.link2 },
    { url: "#", label: footer?.links?.legal?.link3 },
    { url: "#", label: footer?.links?.legal?.link4 },
  ];

  return (
    <>
      <List dense={true}>
        <p className="text-white">{footer?.section3}</p>
        {links.map((link, index) => {
          return (
            <ListItem sx={{ paddingLeft: 0 }} key={index}>
              <ListItemText
                primary={
                  <a
                    href={link.url}
                    style={{ color: "#ADABB7", textDecoration: "none" }}
                  >
                    {link.label}
                  </a>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default AboutUs;
