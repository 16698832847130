import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import {
  FaWhatsapp,
  FaInstagram,
  FaFacebook,
  FaTelegram,
  FaCopy,
} from "react-icons/fa";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";

const ShareModal = ({ isOpen, onClose, shareUrl, shareTitle }) => {
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const handleClose = () => {
    onClose();
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      console.log("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy link to clipboard:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {general?.shareOn}:
        </Typography>

        <Stack direction="row" spacing={2}>
          <WhatsappShareButton url={shareUrl} title={shareTitle}>
            <FaWhatsapp
              style={{ color: "#25D366", cursor: "pointer" }}
              size={24}
            />
          </WhatsappShareButton>

          <InstapaperShareButton url={shareUrl} title={shareTitle}>
            <FaInstagram
              style={{ color: "#E4405F", cursor: "pointer" }}
              size={24}
            />
          </InstapaperShareButton>

          <FacebookShareButton url={shareUrl} quote={shareTitle}>
            <FaFacebook
              style={{ color: "#1877F2", cursor: "pointer" }}
              size={24}
            />
          </FacebookShareButton>

          <TelegramShareButton url={shareUrl} title={shareTitle}>
            <FaTelegram
              style={{ color: "#0088cc", cursor: "pointer" }}
              size={24}
            />
          </TelegramShareButton>

          <IconButton
            color="primary"
            aria-label="copy"
            onClick={handleCopyLink}
          >
            <FaCopy size={24} />
          </IconButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;
