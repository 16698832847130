import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
} from "@mui/material";

import formImg from "../../assets/img/intro-section-animals.png";
import { useFormik } from "formik";
import { contactFormSchema } from "../../schema/contactForm/contactFormSchema";

import Footer from "../../components/footer/Footer";
import NavigationBar from "../../components/landingPage/navbar/NavigationBar";
import { sendMessage } from "../../store/actions/contact";
import { callSuccessAlert } from "../../customAlert/SuccessAlert";
import LogInModal from "../../components/modals/LogInModal";
import RegisterModal from "../../components/modals/RegisterModal";
import PasswordRecoveryModal from "../../components/modals/PasswordRecoveryModal";
import SignUpModal from "../../components/SignUp/SignUpModal";
import SignInModal from "../../components/signIn/SignInModal";
import { useState } from "react";
import { timestampCreador } from "../../firebase/firebase-config";
import { useSelector } from "react-redux";

const ContactView = () => {
  const [openLogIn, setOpenLogIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openPassRecovery, setOpenPassRecovery] = useState(false);
  const [openSignUpSteps, setOpenSignUpSteps] = useState(false);
  const [openSignInSteps, setOpenSignInSteps] = useState(false);
  const { aboutPage, login, register, footer, contactPage } = useSelector(
    (state) => {
      // console.log(state); // Log the entire state
      return state.loggedUser.language;
    }
  );
  const { handleSubmit, errors, touched, getFieldProps } = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
    },
    validationSchema: contactFormSchema,
    onSubmit: (values, { resetForm }) => {
      const data = {
        cdate: timestampCreador.fromDate(new Date()),
        name: values.name,
        email: values.email,
        message: values.description,
      };

      const afterSendingMsg = () => {
        callSuccessAlert("Gracias por ponerte en contacto con nosotros");
      };

      sendMessage(data, afterSendingMsg);
      resetForm();
    },
  });

  return (
    <>
      <Box
        bgcolor="#260055"
        paddingLeft={{ xs: "20px", sm: "40px", md: "40px" }}
        paddingRight={{ xs: "20px", sm: "40px", md: "40px" }}
      >
        <NavigationBar
          setOpenLogIn={setOpenLogIn}
          setOpenRegister={setOpenRegister}
        />
      </Box>
      <Grid container width={{ xs: "80%", lg: "70%" }} margin="40px auto">
        <Grid item md={6} alignItems="center">
          <Box maxWidth="650px" margin="0 auto">
            <img src={formImg} alt="Form" width="100%" height="100%" />
          </Box>
        </Grid>
        <Grid item md={6} alignItems="center" padding="20px">
          <Stack direction="row" alignItems="center" height="100%">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <FormControl
                variant="outlined"
                sx={{ width: "100%", padding: 0, mb: 3 }}
              >
                <label htmlFor="outlined-name" style={{ fontWeight: "bold" }}>
                  {contactPage?.name}
                </label>
                <OutlinedInput
                  {...getFieldProps("name")}
                  id="outlined-name"
                  error={touched.name && Boolean(errors.name)}
                  type="text"
                />

                <FormHelperText error id="outlined-adornment-name">
                  {touched.name && errors.name ? errors.name : " "}
                </FormHelperText>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: "100%", padding: 0, mb: 3 }}
              >
                <label htmlFor="outlined-email" style={{ fontWeight: "bold" }}>
                  {contactPage?.email}
                </label>
                <OutlinedInput
                  id="outlined-email"
                  {...getFieldProps("email")}
                  error={touched.email && Boolean(errors.email)}
                  type="email"
                />

                <FormHelperText error id="outlined-email">
                  {touched.email && errors.email ? errors.email : " "}
                </FormHelperText>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: "100%", padding: 0, mb: 3 }}
              >
                <label
                  htmlFor="outlined-description"
                  style={{ fontWeight: "bold" }}
                >
                  {contactPage?.description}
                </label>
                <OutlinedInput
                  multiline
                  id="outlined-description"
                  {...getFieldProps("description")}
                  error={touched.description && Boolean(errors.description)}
                  type="textarea"
                />

                <FormHelperText error id="outlined-description">
                  {touched.description && errors.description
                    ? errors.description
                    : " "}
                </FormHelperText>
              </FormControl>

              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "100%",
                  backgroundColor: "#79d0f1",
                  borderRadius: "8px",
                  padding: "10px 15px",
                }}
              >
                Enviar
              </Button>
            </form>
          </Stack>
        </Grid>
      </Grid>
      <Footer footer={footer} />

      {/* Modals */}
      <LogInModal
        open={openLogIn}
        handleOpen={setOpenLogIn}
        handleOpenRegister={setOpenRegister}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSingInStep={setOpenSignInSteps}
        login={login}
      />
      <RegisterModal
        open={openRegister}
        handleOpenLogIn={setOpenLogIn}
        handleOpenPassRecovery={setOpenPassRecovery}
        handleOpenSignUpSteps={setOpenSignUpSteps}
        handleOpenSignIn={setOpenLogIn}
        handleOpenSignInStep={setOpenSignInSteps}
        handleOpenSignUp={setOpenRegister}
        register={register}
      />

      <PasswordRecoveryModal
        open={openPassRecovery}
        handleOpen={setOpenPassRecovery}
        handleOpenLogIn={setOpenLogIn}
      />

      <SignUpModal
        open={openSignUpSteps}
        handleOpen={setOpenSignUpSteps}
        login={login}
      />
      <SignInModal
        open={openSignInSteps}
        handleOpen={setOpenSignInSteps}
        register={register}
      />
    </>
  );
};

export default ContactView;
