export default {
  primary: "#8828ff",
  secondary: "#0659FD",
  green: "#00ff54",
  red: "#ff0000",
  second: "#d9b25f",
  third: "#392D8B",
  fourth: "#0F1014",
  dark: "#16223a",
  gold: "#BAA06C",
  grey: "#707070",
  greyLight: "#dcdcdc",
  text2: "#2d2d2d",
  text: "#707070",
  bg: "#112744",
  bgAcademy: "#001d35 !important",
  blue: "#00223E",
  bgLight: "#273756 !important",
  darkBlue: "#1F2C44",
  light: "#FFFFFF",
  grey2: "#959595",
  first2: "#7724b2",
  link: "#00BEFF",
  facebook: "#3b5998",
  google: "#de5246",
  lightBlue: "#00aeff",
  green: "#00ff54",
  red: "#ff0000",
  smileUpYellow: "#F7AF41",
  transparent: "rgba(0,0,0,0.0)",
  semiTransparent: "rgba(0,0,0,0.8)",
};
