import { Box, Grid } from "@mui/material";

import { BiExit, BiHomeAlt2, BiUser } from "react-icons/bi";
import { BsColumnsGap } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";

import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import CloseSessionModal from "../../modals/CloseSessionModal";
import NavigationMenu from "../../navigationMenu/NavigationMenu";
import { useSelector } from "react-redux";

const DreamersHome = () => {
  const [openEndSessionModal, setOpenEndSessionModal] = useState(false);

  const userData = useSelector((state) => state.loggedUser.data);
  const { dreamersPage } = useSelector((state) => state.loggedUser.language);
  useEffect(() => {
    const shouldOpenEndSessionModa = () => {
      setOpenEndSessionModal(true);
    };

    if (!userData.isActive) {
      shouldOpenEndSessionModa();
    }
  }, [userData]);

  const iconsList = [
    {
      icon: <BiHomeAlt2 size={24} />,
      url: "/home/dreamer",
      caption: dreamersPage?.navbar?.link1,
    },
    {
      icon: <BsColumnsGap size={24} />,
      url: "/home/dreamer/my-dreams",
      caption: dreamersPage?.navbar?.link2,
    },
    // {
    //   icon: <BiEnvelope size={24} />,
    //   url: "#",
    //   caption: "",
    // },
    {
      icon: <BiUser size={24} />,
      url: "/home/dreamer/account",
      caption: dreamersPage?.navbar?.link3,
    },
    {
      icon: <FiSettings size={24} />,
      url: "/home/dreamer/account/notifications-config",
      caption: dreamersPage?.navbar?.link4,
    },
    {
      icon: <BiExit size={24} />,
      url: "#",
      caption: dreamersPage?.navbar?.link5,
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#f7f7f7" }}>
      <Box
        component="header"
        sx={{
          display: { lg: "none", xs: "block" },
          paddingLeft: "25px",
          paddingRight: "25px",
          background: "#0659fd",
        }}
      >
        <NavigationMenu iconsList={iconsList} mode="header" route={"dreamer"} />
      </Box>
      <Grid container>
        <Grid
          item
          md={1}
          sx={{ display: { md: "block", xs: "none" } }}
          width="200px"
          height="100vh"
          position="fixed"
        >
          <Box height="100vh" sx={{ display: { lg: "block", xs: "none" } }}>
            <NavigationMenu
              iconsList={iconsList}
              bgColor="#0659fd"
              route={"dreamer"}
            />
          </Box>
        </Grid>
        <Grid item md={1} xs={0}></Grid>

        <Outlet />
      </Grid>

      <CloseSessionModal
        open={openEndSessionModal}
        handleOpen={setOpenEndSessionModal}
      />
    </Box>
  );
};

export default DreamersHome;
