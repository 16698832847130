import { Stack, Typography } from "@mui/material";

import { BiUserPlus, BiCheck } from "react-icons/bi";

import noImagePlaceHolder from "../../assets/img/avatar-no-image.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFollower,
  createNotification,
  getOneFollow,
} from "../../store/actions/userActions";
import { timestampCreador } from "../../firebase/firebase-config";

const AddUser = ({ user, location, followedUsers }) => {
  const [userFullName, setUserFullName] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.loggedUser.data);

  useEffect(() => {
    const setUserData = () => {
      const fullName = `${user?.name ?? ""} ${user?.lastname ?? ""}`;
      setUserFullName(fullName);

      const currentUser = followedUsers.find(
        (followedUser) => followedUser.id === user.id
      );

      if (currentUser) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    };

    if (followedUsers) {
      setUserData();
    }
  }, [user, followedUsers]);

  const handleClick = () => {
    const today = new Date();
    const cdate = timestampCreador.fromDate(today);
    const followerData = {
      cdate,
      userRef: currentUser?.id,
    };

    let fullName = `${currentUser.name} `;

    if (currentUser.lastname) fullName = fullName + currentUser.lastname;

    const notificationData = {
      cdate,
      fromUserId: currentUser.id,
      fromUserName: fullName,
      isRead: false,
      subject: "A comenzado a seguirte",
      type: "follower",
    };

    dispatch(getOneFollow(user));
    createFollower(user.id, followerData);
    createNotification(user.id, notificationData);
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" gap={1}>
        <span style={{ width: "40px", height: "40px" }}>
          <img
            src={user?.imgURL || noImagePlaceHolder}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
            }}
          />
        </span>
        <div style={{ color: "#fff" }}>
          <Typography fontWeight="bold">{userFullName}</Typography>
          <Typography fontSize="12px">{location}</Typography>
        </div>
      </Stack>

      <span
        style={{
          cursor: "pointer",
          padding: "0px 10px",
          borderRadius: "8px",
          backgroundColor: "#0659FD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isFollowing ? (
          <BiCheck color="#fff" size={19} />
        ) : (
          <BiUserPlus color="#fff" size={19} onClick={handleClick} />
        )}
      </span>
    </Stack>
  );
};

export default AddUser;
