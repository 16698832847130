import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

const DreamerFriends = ({ friends = [] }) => {
  const isDown1440 = useMediaQuery("(max-width:1439px)");

  return (
    <Stack width="90%" margin="0 auto">
      <Typography variant={isDown1440 ? "h5" : "h6"} fontWeight="bold">
        Amigos
      </Typography>
      <Grid container justifyContent="center" spacing={isDown1440 ? 8 : ""}>
        {friends.map((friend) => {
          return (
            <Grid item lg={3} xs={4} key={friend.id}>
              <Box
                width={isDown1440 ? "auto" : "45px"}
                height={isDown1440 ? "auto" : "45px"}
                minWidth="45px"
                minHeight="45px"
                maxWidth="180px"
                maxHeight="180px"
              >
                {" "}
                {/**width="45px" height="45px" */}
                <img
                  src="https://picsum.photos/id/248/300/300"
                  alt=""
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "14px" }}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default DreamerFriends;
