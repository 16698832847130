import { Box } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import noImagePlaceHolder from "../../assets/img/avatar-no-image.png";

const Story = ({ user }) => {
  const handleDragStart = (e) => e.preventDefault();

  const navigate = useNavigate();
  const location = useLocation();
  const [profileURL, setProfileURL] = useState("");

  useEffect(() => {
    const setUrl = () => {
      let url;
      if (location.pathname.includes("maker")) {
        //go to maker profile
        url = `/home/maker/profile/${user.id}`;
      } else {
        //go to dreamer profile
        url = `/home/dreamer/profile/${user.id}`;
      }

      setProfileURL(url);
    };

    if (user) {
      setUrl();
    }
  }, [user]);

  return (
    <Box
      borderRadius="14px"
      border="2px solid #0659FD"
      width="65px"
      height="65px"
      onDragStart={handleDragStart}
      component={"div"}
      onClick={() => navigate(profileURL)}
      sx={{ cursor: "pointer" }}
    >
      <img
        src={user?.imgURL || noImagePlaceHolder}
        alt=""
        width="100%"
        onDragStart={handleDragStart}
        style={{ padding: "4px", borderRadius: "14px" }}
      />
    </Box>
  );
};

export default Story;
