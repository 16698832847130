import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useCallback, useEffect, useState } from "react";
import Dragndrop from "../dropzone/DropZone";
import { useFormik } from "formik";
import Preview from "../dropzone/Preview";
import { timestampCreador } from "../../../firebase/firebase-config";
import {
  createVideoForPosts,
  createVideoPost,
} from "../../../store/actions/dreamers";
import { useSelector } from "react-redux";
import { callSuccessAlert } from "../../../customAlert/SuccessAlert";
import { createVideoPostSchema } from "../../../schema/createVidePostSchema/createVidePostSchema";

const acceptedFileType = { "video/*": [".mp4", ".MP4"] };

const CreateVideoPostForm = ({ handleOpen, formik, setPostType }) => {
  const [files, setFiles] = useState([]);
  const [userDreamList, setUserDreamList] = useState([]);

  const currenUser = useSelector((state) => state.loggedUser.data);
  const dreams = useSelector((state) => state.dreamers.dreams);

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        postName: "",
        description: "",
        currentDreamId: "",
        videoURL: "",
        //file: files,
      },
      validationSchema: createVideoPostSchema,
      onSubmit: async (values) => {
        formik.setFieldValue("videoURL", values.videoURL);
        setPostType(false);
        handleOpen(false);
        // const currentDream = dreams.find(
        //   (dream) => dream.id === values.currentDreamId
        // );

        // const postData = {
        //   name: currentDream.name,
        //   description: values.description,
        //   type: "video",
        //   cdate: timestampCreador.fromDate(new Date()),
        //   videoURL: values.videoURL,
        // };

        // const newPostId = await createVideoPost(
        //   currenUser.uid,
        //   values.currentDreamId,
        //   postData
        // );
        // // createVideoForPosts(
        // //   files[0],
        // //   currenUser.uid,
        // //   values.currentDreamId,
        // //   newPostId
        // // );
        // handleOpen(false);
        // callSuccessAlert("Nueva publicación creada");
      },
    });

  const onDrop = useCallback(
    (acceptedFiles) => {
      const imagesArray = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: typeof file === "string" ? file : URL.createObjectURL(file),
        })
      );

      // if (
      //   imagesArray.length === 2 &&
      //   imagesArray[0] !== "" &&
      //   imagesArray[1] !== ""
      // ) {
      //   setImgFiles(imagesArray);
      // } else {
      //   const files = [...imgFiles];
      //   if (imgFiles[0] === "") {
      //     files[0] = imagesArray[0];
      //     setImgFiles(files);
      //     return;
      //   } else if (imgFiles[1] === "") {
      //     files[1] = imagesArray[0];
      //     setImgFiles(files);
      //     return;
      //   }
      // }

      setFiles(files.concat(imagesArray));
    },
    [setFiles, files]
  );

  useEffect(() => {
    const getDreamList = () => {
      const userDreamList = dreams.filter(
        (dream) => dream.userOwnerId === currenUser.id
      );

      setUserDreamList(userDreamList);
    };

    if (dreams) {
      getDreamList();
    }
  }, [dreams, currenUser]);

  const removeItem = (index) => {
    const newFileList = [...files];

    newFileList.splice(index, 1);
    setFiles(newFileList);
  };

  return (
    <Container>
      <form
        onSubmit={(event) => {
          event.preventDefault(event);
          handleSubmit();
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="h5" component="h1" mb={7}>
              Creando una publicación
            </Typography>

            {/* <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
              <label htmlFor="outlined-name" style={{ fontWeight: "bold" }}>
                NOMBRE
              </label>
              <OutlinedInput
                id="outlined-name"
                name="dreamName"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                error={touched.dreamName && Boolean(errors.dreamName)}
                type={"text"}
              />

              <FormHelperText error id="outlined-name">
                {touched.dreamName && errors.dreamName ? errors.dreamName : " "}
              </FormHelperText>
            </FormControl> */}
            {/* <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
              <label
                htmlFor="outlined-description"
                style={{ fontWeight: "bold" }}
              >
                DRESCRIPCION
              </label>
              <OutlinedInput
                id="outlined-description"
                name="description"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                type={"textarea"}
              />

              <FormHelperText error id="outlined-description">
                {touched.description && errors.description
                  ? errors.description
                  : " "}
              </FormHelperText>
            </FormControl> */}
            {/* <FormControl sx={{ width: "100%", padding: 0 }}>
              <label htmlFor="dream-list" style={{ fontWeight: "bold" }}>
                LISTA DE SUEÑOS
              </label>
              <Select
                labelId="dream-list"
                id="demo-simple-select"
                value={values.currentDreamId}
                onChange={handleChange}
                onBlur={handleBlur}
                name="currentDreamId"
                fullWidth
              >
                {userDreamList.map((dream) => {
                  return (
                    <MenuItem key={dream.id} value={dream.id}>
                      {dream.name}
                    </MenuItem>
                  );
                })}
              </Select>

              <FormHelperText error id="outlined-dream-list">
                {touched.currentDreamId && errors.currentDreamId
                  ? errors.currentDreamId
                  : " "}
              </FormHelperText>
            </FormControl> */}
            <FormControl
              variant="outlined"
              sx={{ width: "100%", padding: 0, mt: 3 }}
            >
              <label
                htmlFor="outlined-description"
                style={{ fontWeight: "bold" }}
              >
                URL DE VIDEO
              </label>
              <OutlinedInput
                id="outlined-url"
                name="videoURL"
                onChange={(event) => {
                  handleChange(event);
                }}
                value={values.videoURL}
                onBlur={handleBlur}
                error={touched.videoURL && Boolean(errors.videoURL)}
                type={"text"}
              />

              <FormHelperText error id="outlined-url">
                {touched.videoURL && errors.videoURL ? errors.videoURL : " "}
              </FormHelperText>
            </FormControl>

            <Box>
              <Button
                type="submit"
                variant="contained"
                sx={{ margin: "auto", display: "block" }}
              >
                Cargar enlace
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CreateVideoPostForm;
