import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";

import OutlinedInput from "@mui/material/OutlinedInput";
import { useFormik } from "formik";

import { useSelector } from "react-redux";
import { callUpdateAlert } from "../../customAlert/UpdateElement";
import { profileSchema } from "../../schema/profileInfo/ProfileInfo";
import { updateUserPassword } from "../../store/actions/auth";
import {
  createImageForUser,
  updateProfile,
} from "../../store/actions/userActions";
import { useState } from "react";
import CreateImageProfileModal from "../modals/CreateImageProfileModal";

const ProfileInfo = () => {
  const userData = useSelector((state) => state.loggedUser.data);
  const user = useSelector((state) => state.loggedUser.data);
  const { general, lang, profileSettings } = useSelector(
    (state) => state.loggedUser.language
  );

  const [openUploadImgModal, seOpenUploadImgModal] = useState(false);
  const [files, setFiles] = useState([]);

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        name: userData?.name ?? "",
        lastname: userData?.lastname ?? "",
        secondLastname: userData?.secondLastname ?? "",
        user: userData?.user ?? "",
        phone: userData?.phone ?? "",
        email: userData?.email ?? "",
        password: "",
        confirmPassword: "",
        description: "",
      },
      validationSchema: profileSchema,
      onSubmit: (values) => {
        const userData = {
          name: values.name,
          lastname: values.lastname,
          secondLastname: values.secondLastname,
          user: values.user,
          phone: values.phone,
          description: values.description,
        };

        const uploadFile = () => {
          createImageForUser(files[0], user.id);
          setFiles([]);
        };

        const updateUserInfo = () => {
          updateProfile(user.id, userData, uploadFile);

          if (values.password !== "") {
            updateUserPassword(values.confirmPassword);
          }
        };

        callUpdateAlert(
          updateUserInfo,
          undefined,
          "Perfil actualizado",
          "¿Desea modifcar su información?"
        );
      },
    });

  const handleDelete = () => {
    setFiles([]);
  };

  return (
    <Box component="main">
      <Container>
        {lang === "es" ? (
          <Typography variant="h3" fontWeight="bold" mb={4}>
            {general?.info}{" "}
            <span style={{ color: "#864bf1" }}> {general?.profile}</span>
          </Typography>
        ) : (
          <Typography variant="h3" fontWeight="bold" mb={4}>
            <span style={{ color: "#864bf1" }}> {general?.profile}</span>{" "}
            {general?.info}
          </Typography>
        )}

        <form onSubmit={(event) => handleSubmit(event)}>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.name}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                />

                <FormHelperText error id="outlined-name">
                  {touched.name && errors.name ? errors.name : " "}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {" "}
                  {profileSettings?.sections?.profile?.lastname}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                  name="lastname"
                />

                <FormHelperText error id="outlined-lastname">
                  {touched.lastname && errors.lastname ? errors.lastname : " "}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.secondLastname}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.secondLastname}
                  name="secondLastname"
                />

                <FormHelperText error id="outlined-secondLastname">
                  {touched.secondLastname && errors.secondLastname
                    ? errors.secondLastname
                    : " "}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.email}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  disabled
                />

                <FormHelperText error id="outlined-email">
                  {touched.email && errors.name ? errors.email : " "}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.phone}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  name="phone"
                />

                <FormHelperText error id="outlined-phone">
                  {touched.phone && errors.phone ? errors.phone : " "}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              {/* <FormControl fullWidth>
              <label style={{ fontWeight: "bold" }}>
                {profileSettings?.sections?.profile?.user}
              </label>
              <OutlinedInput
                sx={{ borderRadius: "50px", mt: 2 }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user}
                name="user"
              />

              <FormHelperText error id="outlined-user">
                {touched.user && errors.user ? errors.user : " "}
              </FormHelperText>
            </FormControl> */}
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.password}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  type="password"
                />

                <FormHelperText error id="outlined-password">
                  {touched.password && errors.password ? errors.password : " "}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.repeatPassword}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "50px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  type="password"
                />

                <FormHelperText error id="outlined-confirmPassword">
                  {touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : " "}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontWeight: "bold" }}>
                  {profileSettings?.sections?.profile?.aboutMe}
                </label>
                <OutlinedInput
                  sx={{ borderRadius: "40px", mt: 2 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  name="description"
                  type="textarea"
                  rows={5}
                  multiline
                />

                <FormHelperText error id="outlined-password">
                  {touched.password && errors.password ? errors.password : " "}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Stack direction="row" gap={2} alignItems="center">
            {files.length ? (
              <Chip
                label={files[0].name}
                onDelete={handleDelete}
                sx={{ ml: "auto", marginTop: "20px" }}
              />
            ) : (
              <Button
                variant="outlined"
                onClick={() => seOpenUploadImgModal(true)}
                sx={{
                  padding: "10px 25px",
                  marginLeft: "auto",
                  marginTop: "20px",
                }}
              >
                {profileSettings?.sections?.profile?.profilePic}
              </Button>
            )}
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#000",
                padding: "10px 25px",
                marginTop: "20px",
                minWidth: "150px",
              }}
            >
              {profileSettings?.sections?.profile?.saveChanges}
            </Button>
          </Stack>
        </form>
      </Container>
      <CreateImageProfileModal
        openModal={openUploadImgModal}
        handleOpen={seOpenUploadImgModal}
        setImgFiles={setFiles}
      />
    </Box>
  );
};

export default ProfileInfo;
