import { types } from "../types/types";

const initialState = {
  toggleMenu: false,
  makers: [],
  isLoading: false,
  dreamFilters: ["recent", "close", "dreams", "ascending"],
};

export const makersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.toggleMenu:
      return {
        ...state,
        toggleMenu: !state.toggleMenu,
      };

    case types.getAllMakers:
      return {
        ...state,
        makers: action.payload.makers,
      };

    case types.isLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case types.setDreamFilters:
      return {
        ...state,
        dreamFilters: action.payload.dreamFilters,
      };

    default:
      return state;
  }
};
