export const testimonies = [
  {
    id: 1,
    name: "Clínica Dr Mario Blanco",
    association: "",
    textEng:
      "I am familiar with the origin and development of the ERES MI SONRISA Foundation and its SMILE UP platform, so I can attest that it has been developed with great passion and the noble purpose of helping. I remember the first case we treated today; it was a teenager with different problems, including a dental situation that required various specialties. I consider SmileUP+ to be a platform that simplifies the process of giving and receiving help.",

    text: "Conozco el origen y el desarrollo de la Fundación ERES MI SONRISA y su plataforma SMILE UP por lo que puedo dar fe que ha sido desarrollado con mucha pasión y con el fin noble de ayudar, y recuerdo como hoy el primer caso que tratamos; era un adolescente con diferentes problemas y entre ellos una situación buco dental que requería de diferentes especialidades. Considero que SmileUP+ es una plataforma que simplifica el proceso de dar y recibir ayuda",
  },
  {
    id: 2,
    name: "Edgar Acuña Matarrita ",
    association: "Asociación Adulto Mayor María Inmaculada Jicaral",
    textEng:
      "I hereby certify that the SmileUP+ Foundation has contributed to the organization I represent, demonstrating its seriousness and commitment to society. In our case, its contribution has been very valuable in pursuit of our main objective of improving the quality of life for elderly people living in poverty, extreme poverty, and social vulnerability in the southern region of the Nicoya Peninsula. The population in need of assistance is increasing, and their needs are growing. Thank you, SMILE UP Foundation, for your solidarity.",

    text: "Hago constar que la Fundación SmileUP+ ha contribuido con la organización que represento con lo cual demuestra su seriedad y compromiso con la sociedad, en nuestro caso su aporte ha  sido muy valioso en aras del cumplimiento de nuestro objetivo principal de brindar calidad de vida a las personas adultas mayores que se encuentran en un estado de pobreza, pobreza extrema y vulnerabilidad social en la región sur de la península de Nicoya; población que además de requerir de ayuda va en aumento y sus necesidades cada vez son mayores. Gracias Fundación SMILE UP por su solidaridad.",
  },
  {
    id: 3,
    name: "Dr. Jorge Cortés",
    association: "Hospital San Rafael Arcángel",
    textEng:
      "Within the realm of innovative ideas accessible in life, those that effectively combine novelty and disruption with the conviction and alignment of principles of their proponents are rarely highlighted. I've had the pleasure of getting to know Don Alexis Alvarado and his extraordinary life story, to which he adds in maturity a project that is not only ambitious but also distinct and bold. Through the Foundation 'Eres Mi Sonrisa' and its app, Wep SmileUP+, he aims to connect needs with those in need.",

    text: "Dentro de las ideas innovadoras a las que se tiene acceso en la vida, con poca frecuencia resaltan aquellas que unen la novedad y disrupción con la convicción y unión de principios de sus proponentes. He tenido el agrado de conocer a Don Alexis Alvarado y su extraordinaria historia de vida a la que agrega en la madurez un proyecto no solo ambicioso sino diferente y audaz, en la que pretende poner en contacto necesidades y necesitados por medio de la Fundación Eres Mi Sonrisa y su App Wep SmileUP+",
  },
];

export const comentaries = [
  {
    id: 1,
    name: "Dra Jessica Blanco,Sra Costa Rica 2024",
  subtitle:"Sra Costa Rica 2024",

    textEng:
      "I am a professional and Eres Mi Sonrisa comfirms you need help sometimes, Thank you SmileUp+",

    text: "Soy profesional y Eres Mi Sonrisa me confirma que a veces necesitas ayuda ¡Gracias SmileUP+! ",
  },
  {
    id: 2,
    name: "Isaac Retana",

    textEng:
      "Thanks SmileUP+ for my shoulder rehab, Following advice, feeling better",

    text: "Gracias SmileUp+ por la rehabilitación de mi hombro.Sigo sus consejos, me siento mejor.",
  },
  {
    id: 3,
    name: "Asociación Adulto Mayor María Inmaculada Jicaral",

    textEng:
      "Priceless Support to improve the quality of life for elderly people in the southern region of Nicoya. Thank you for the commitment!",

    text: "Apoyo invaluable para mejorar la calidad de vide de adultos mayores  en la región sur de Nicoya !Gracias por su compromiso!",
  },
];
