import { db } from "../../firebase/firebase-config";
import { types } from "../types/types";
import { getOneUser } from "./userActions";

export const getAllMakers = () => {
  return async (dispatch) => {
    try {
      isLoadingDreams(true);
      const makers = [];
      const querySnapshot = await db
        .collection("users")
        .where("isMaker", "==", true)
        .get();

      querySnapshot.docs.forEach((doc) => {
        makers.push({ id: doc.id, ...doc.data() });
      });

      dispatch(getMakers(makers));
      isLoadingDreams(false);
    } catch (error) {
      console.log(error);
      isLoadingDreams(false);
    }
  };
};

export const getUserFollows = (userId, limitNum) => {
  return async (dispatch) => {
    try {
      let query = db
        .collectionGroup("followers")
        .orderBy("cdate", "desc")
        .where("userRef", "==", userId);

      if (limitNum) {
        query = query.limit(limitNum);
      }

      const data = await query.get();
      const follows = [];

      for (const doc of data.docs) {
        const followerRef = doc.data().userRef;
        const follower = await getOneUser(followerRef);
        follows.push(follower);
      }

      dispatch(getFollows(follows));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getOneMaker = async (userId) => {
  try {
    const maker = await db.collection("users").doc(userId).get();

    return { id: maker.id, ...maker.data() };
  } catch (error) {
    console.log(error);
  }
};

export const setFilters = (filters) => ({
  type: types.setDreamFilters,
  payload: {
    dreamFilters: filters,
  },
});

export const getMakers = (makers) => ({
  type: types.getAllMakers,
  payload: {
    makers,
  },
});

export const getFollows = (follows) => ({
  type: types.getFollows,
  payload: {
    follows,
  },
});

export const isLoadingDreams = (isLoading) => ({
  type: types.isLoading,
  payload: {
    isLoading,
  },
});
