import * as Yup from "yup";

export const contactFormSchema = Yup.object({
  name: Yup.string().required("Nombre requerido"),
  email: Yup.string()
    .email("Email no valido")
    .typeError("Email no valido")
    .required("Email es requerido"),
  description: Yup.string().required("Campo requerido"),
});
