import { Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import turtleAndTucan from "../../../assets/img/intro-section-animals.png";
import { Fragment } from "react";
import Colors from "../../../utils/Colors";

const IntroSection = ({ setOpenRegister, landing }) => {
  const isDown1500 = useMediaQuery("(max-width:1500px)");
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  const isDown800 = useMediaQuery("(max-width:800px)");
  const isDown550 = useMediaQuery("(max-width:550px)");

  return (
    <section style={isDown550 ? { padding: "20px" } : { padding: "40px" }}>
      <Grid container>
        <Grid item lg={6} md={12}>
          <img
            style={{ display: "block", margin: "0 auto" }}
            src={turtleAndTucan}
            alt="Tortuga y Tucán"
            width={
              isDown1200
                ? isDown1000
                  ? isDown800
                    ? isDown550
                      ? "95%"
                      : "85%"
                    : "70%"
                  : "60%"
                : "80%"
            }
          />
        </Grid>
        {isDown1500 ? null : <Grid item lg={1}></Grid>}
        <Grid
          item
          lg={isDown1500 ? 5 : 4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{ mb: 3, fontWeight: "bold" }}
          >
            {landing.section1.text1.replace(/SmileUp\+/g, (match) => (
              <span style={{ color: "red", fontWeight: "bold" }}>{match}</span>
            ))}
          </Typography>

          <Typography
            variant="h5"
            component="h3"
            sx={{ mb: 3, color: "#666666" }}
          >
            {landing.section1.text2}
          </Typography>

          <Typography
            variant="body1"
            sx={{ mb: 4, color: "#666666", width: "95%" }}
          >
            {landing.section1.text3.split("\n").map((paragraph, index) => (
              <Fragment key={index}>
                {paragraph.includes("SmileUP+") ? (
                  <>
                    {paragraph.split("SmileUP+").map((part, partIndex) => (
                      <Fragment key={partIndex}>
                        {partIndex > 0 && (
                          <span
                            style={{
                              color: Colors.grey,
                              fontWeight: "bold",
                            }}
                          >
                            Smile
                            <span
                              style={{
                                color: Colors.smileUpYellow,
                                fontWeight: "bold",
                              }}
                            >
                              UP+
                            </span>
                          </span>
                        )}
                        {part}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  paragraph
                )}
                <br />
              </Fragment>
            ))}
          </Typography>

          <Typography variant="body1" sx={{ mb: 4, color: "#666666" }}>
            {landing.section1.text4}
          </Typography>

          <Button
            variant="contained"
            onClick={() => setOpenRegister(true)}
            sx={{
              backgroundColor: "#f8f2ff",
              color: "#8828FF",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            {landing.section1.text5}
          </Button>
        </Grid>
      </Grid>
    </section>
  );
};

export default IntroSection;
