import Grid from "@mui/material/Grid";

import { useFormik } from "formik";
import { passwordRecoverySchema } from "../../schema/passwordRecovery/passwordRecovery";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { resetPassword } from "../../store/actions/auth";
import PasswordRecoveryAlert from "./PasswordRecoveryAlert";

export const PasswordRecoveryForm = ({ handleOpen, handleOpenLogIn }) => {
  const [showAlert, setShowAlert] = useState(false);

  const isDown1200 = useMediaQuery("(max-width:1200px)");

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: passwordRecoverySchema,
      onSubmit: (values) => {
        resetPassword(values.email);
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      },
    });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Grid
        container
        rowSpacing={4}
        mt={3}
        display="flex"
        justifyContent="center"
      >
        <Grid item md={10}>
          <div style={{ marginBottom: "20px" }}>
            <Typography fontWeight="bold" variant="h5" component="h1">
              Password Recovery
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "rgba(143, 146, 161, 1)" }}
            >
              Ingrese su email para recuperar la contraseña
            </Typography>
          </div>
          <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
            <label
              htmlFor="outlined-adornment-email"
              style={{ fontWeight: "bold" }}
            >
              EMAIL
            </label>
            <OutlinedInput
              id="outlined-adornment-email"
              name="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              type={"email"}
              endAdornment={
                <InputAdornment position="end" sx={{ color: "#53D769" }}>
                  {errors.email ? (
                    <ErrorOutlineIcon />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                </InputAdornment>
              }
            />

            <FormHelperText error id="outlined-adornment-email">
              {touched.email && errors.email ? errors.email : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={10}>
          <Stack gap={4}>
            <Button
              variant="contained"
              type="submit"
              disabled={showAlert}
              sx={{
                width: "100%",
                backgroundColor: "#53d769",
                borderRadius: "8px",
                padding: "10px 15px",
              }}
            >
              Enviar email
            </Button>

            {isDown1200 && (
              <Button
                variant="contained"
                onClick={() => {
                  handleOpen(false);
                  handleOpenLogIn(true);
                }}
                sx={{
                  borderRadius: "8px",
                  padding: "10px 15px",
                  width: "100%",
                  backgroundColor: "#0659fd",
                }}
              >
                Ir a login
              </Button>
            )}

            {showAlert && (
              <PasswordRecoveryAlert
                msg={
                  "Se ha enviado un correo electrónico para restablecer la contraseña. Comprueba tu bandeja de entrada"
                }
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
