// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_H90QKiBaw4dKqDQEZ_GNtFDjcWfWsYA",
  authDomain: "foundation-smileup.firebaseapp.com",
  projectId: "foundation-smileup",
  storageBucket: "foundation-smileup.appspot.com",
  messagingSenderId: "96940761368",
  appId: "1:96940761368:web:c0437bbf2dcddeab94fe6d",
  measurementId: "G-08CXY37L8Y",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account", // Forces the account selection prompt
});
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();
const storageRef = firebase.storage();
const timestampCreador = firebase.firestore.Timestamp;
const firebaseFieldVal = firebase.firestore.FieldValue;

export { db, auth, storageRef, timestampCreador, firebaseFieldVal };

/**
 * {
  "hosting": {
    "site": "adminsmileup",

    "public": "public",
    ...
  }
}
 */
