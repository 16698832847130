import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { useLocation } from "react-router-dom";
import ToggleAccount from "../toggleAccount/ToggleAccount";
import LanguageDropdown from "./LanguageDropdown";
import Colors from "../../utils/Colors";
import { set } from "lodash";

const HeaderControls = ({ rel }) => {
  const location = useLocation();
  const [isDreamer, setIsDreamer] = useState(true);

  useEffect(() => {
    const userisDreamer = location.pathname.includes("dreamer");
    if (userisDreamer) {
      setIsDreamer(true);
    } else {
      setIsDreamer(false);
    }
  }, [isDreamer, location.pathname]);

  return (
    <Grid
      item
      container
      xl={12}
      lg={12}
      md={12}
      xs={12}
      justifyContent="end"
      alignItems="center"
      mr={2}
      gap={2}
      sx={
        rel
          ? { mb: 2 }
          : {
              position: "absolute",
              right: { xs: 10, sm: 10, md: 50 },
              top: { xs: 100, sm: 100, md: 50 },
            }
      }
    >
      <ToggleAccount
        fontColor="#fff"
        bgColor={isDreamer ? Colors.secondary : Colors.primary}
        currentType={
          location.pathname.includes("dreamer") ? "dreamer" : "maker"
        }
      />
      <LanguageDropdown color="black" borderColor={Colors.primary} />
    </Grid>
  );
};

export default HeaderControls;
