import React from "react";
import { Button } from "@mui/material";
import { FiChevronLeft } from "react-icons/fi";

const GoBackButton = ({ onClick, text, bgcolor }) => {
  return (
    <Button
      variant="contained"
      disableElevation
      onClick={onClick}
      sx={{
        borderRadius: "12px",
        backgroundColor: bgcolor ? bgcolor : "#e2e3e6",
        color: "#8F92A1",
        width: "150px",
        fontWeight: "bold",
        my: 3,
        mr: "auto",
        py: 2,
        display: "flex",
        alignItems: "center",
        "&:hover": {
          color: "white",
        },
        zIndex: 100,
      }}
    >
      <FiChevronLeft />
      {text}
    </Button>
  );
};

export default GoBackButton;
