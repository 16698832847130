import { Box, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";

import numbersBg from "../../../assets/img/about-us-numbers-bg.png";

const FundationNumbers = ({ aboutPage }) => {
  const isDown800 = useMediaQuery("(max-width:800px)");
  const statistics = [
    {
      title: aboutPage.section3.text1,
      description:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
      num: "20+",
      separation: -10,
    },
    {
      title: aboutPage.section3.text2,
      description:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
      num: "$100K",
      separation: -20,
    },
    {
      title: aboutPage.section3.text3,
      description:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
      num: "+650",
      separation: -30,
    },
    {
      title: aboutPage.section3.text4,
      description:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
      num: "250K",
      separation: -40,
    },
  ];
  return (
    <Box
      component="section"
      sx={{
        backgroundImage: `url(${numbersBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: isDown800 ? "auto" : "75vh",
        display: "flex",
        alignItems: "center",
        paddingLeft: isDown800 && "20px",
        paddingRight: isDown800 && "20px",
      }}
    >
      <Grid container>
        {statistics.map((data, index) => {
          return (
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              key={index}
              alignItems={isDown800 ? "" : "center"}
              display={isDown800 ? "" : "flex"}
              flexDirection={isDown800 ? "" : "column"}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                position="relative"
                marginBottom={10}
                bottom={isDown800 ? "" : index * 30}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: "160px",
                    height: "160px",
                    backgroundColor: "#8828ff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography color="#fff" fontSize="24px">
                    {data.num}
                  </Typography>
                </Box>

                <div
                  style={{
                    width: "55%",
                    marginTop: "20px",
                  }}
                >
                  <Typography fontWeight="bold" textAlign="center">
                    {data.title}
                  </Typography>
                  {/* <Typography textAlign="center">{data.description}</Typography> */}
                </div>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default FundationNumbers;
