export const styles = {
  cardStyle: {
    backgroundColor: "#fdfdfd",
    boxShadow: "5px 5px 15px rgba(117, 117, 117, 0.182) !important",
    borderRadius: 1,
    width: "100%",
  },
  graphCardStyle: {
    backgroundColor: "#fdfdfd",
    boxShadow: "5px 5px 15px rgba(117, 117, 117, 0.182) !important",
    borderRadius: 1,
    width: "100%",
  },
  cardStyle2: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: { xs: "secondary.light", sm: `${white}` },
    backgroundColor: "#F3F3F3",
    minHeight: "75vh",
    boxShadow: 3,
    borderRadius: 1,
    justifyContent: "flex-start",

    padding: 5,

    // background:"white"
  },

  cardTitleStyle: {
    fontWeight: "800 !important",
    fontSize: "1.4rem !important",
  },
  tableCard: {
    width: "95%",
    backgroundColor: "#fafafa",
    boxShadow: 2,
    // p: 3,
    marginBottom: 3,
    borderRadius: "18px",
  },
  innerCardStyle: {
    margin: "5px auto",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    // justifyContent:"space-around",
    padding: 1,
    width: "100%",
  },
  smallCardStyle: {
    backgroundColor: "#fafafa",
    boxShadow: 3,
    borderRadius: 1,
    p: 1,
    display: "flex",
    flexDirection: "column",
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "24px",
    borderColor: "#eeeeee",
    // borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    marginTop: 5,
    maxHeight: 140,
    justifyContent: "center",
    width: "100%",
  },

  modalThumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "24px",
    borderColor: "#eeeeee",
    width: "100%",
    // borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    marginTop: 5,
    //maxHeight: 140,
    justifyContent: "center",
  },

  thumb: {
    display: "inline-flex",
    borderRadius: "24px",
    border: "1px solid #eaeaea",
    marginBottom: 4,
    marginRight: 2,
    width: "100%",
    height: 100,
    padding: 4,
    boxSizing: "border-box",
    justifyContent: "center",
  },
  dropzoneStyle: {
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    justifyContent: "center",
  },

  img: {
    display: "block",
    width: "auto",
    height: "100%",
    borderRadius: "24px",
  },

  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    border: "none",
    // borderWidth: 2,
    // borderRadius: "24px",
    // borderColor: "#eeeeee",
    //borderStyle: "dashed",
    //backgroundColor: "#fafafa",
    backgroundColor: "#fff",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },

  acceptStyle: {
    borderColor: "#00e676",
  },

  rejectStyle: {
    borderColor: "#ff1744",
  },

  uploadBoxStyle: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "150px",
    minHeight: "150px",
    margin: "5px 5px",
  },
  mockupStyle: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "150px",
    minHeight: "150px",
    margin: "20px",
  },
};
