import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export const callLogOutAlert = (
  logOutFn,
  alertMessage,
  swals,
  ...logOutParameters
) => {
  Swal.fire({
    title: swals?.alertTitle,
    text: alertMessage,
    icon: "warning",
    confirmButtonText: swals?.confirmButtonText,
    showDenyButton: true,
    denyButtonText: swals?.denyButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      logOutFn(...logOutParameters);
    }
  });
};
