import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import worldInHands from "../../../assets/img/world-in-your-hands.png";

const MakerCard = ({ selected, setSelected, signUp }) => {
  return (
    <Card
      variant="outline"
      onClick={() => setSelected("maker")}
      sx={{
        //backgroundImage: `url(${bgMakerCard})`,
        backgroundColor: "#7340cf",
        backgroundSize: "100% 100%",
        transform: selected === "maker" ? "scale(1.1)" : "scale(1)",
        transition: "transform 0.3s",
        cursor: "pointer",
        borderRadius: "32px",
      }}
    >
      <CardContent sx={{ color: "#fff" }}>
        <Grid container>
          <Grid
            item
            sm={5}
            xs={12}
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={worldInHands} alt="" width="70%" />
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography fontWeight="bold" mb={1}>
                {signUp?.makerTitle}
              </Typography>
              <Typography>{signUp?.makerText}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MakerCard;
