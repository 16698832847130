import * as Yup from "yup";

export const createDreamSchema = Yup.object({
  dreamName: Yup.string().required("Campo requerido"),
  typeOfDream: Yup.string().required("Campo requerido"),
  typeOfHelp: Yup.string().required("Campo requerido"),
  deliveryPlace: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
  availableTime: Yup.string().required("Campo requerido"),

  categories: Yup.array()
    .min(1, "Seleccione al menos una categoria")
    .required("Campo requerido"),
});
