import { Fragment, useState } from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import BlogSlide from "../blogSlide/BlogSlide";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import "./blogSection.scss";

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

import Colors from "../../../utils/Colors";

const BlogSection = ({ landing }) => {
  const theme = useTheme();
  const handleDragStart = (e) => e.preventDefault();
  const isDown1100 = useMediaQuery("(max-width:1100px)");
  const isDown899 = useMediaQuery("(max-width:899px)");

  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    <Box
      className="item"
      sx={{
        padding: "15px",
        width: "100%",
        maxWidth: "625px",
        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
    <Box
      className="item"
      sx={{
        width: "100%",
        maxWidth: "625px",
        padding: "15px",

        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
    <Box
      className="item"
      sx={{
        width: "100%",
        maxWidth: "625px",
        padding: "15px",
        //padding: "15px 30px",
        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
    <Box
      className="item"
      sx={{
        width: "100%",
        maxWidth: "625px",
        padding: "15px",
        //padding: "15px 30px",
        margin: "auto",
      }}
      onDragStart={handleDragStart}
    >
      <BlogSlide />
    </Box>,
  ];
  const slidePrev = () => setActiveIndex((index) => Math.max(index - 1, 0));
  const slideNext = () =>
    setActiveIndex((index) =>
      Math.min(index + 1, items.length - (responsive[1450]?.items || 1))
    );
  const responsive = {
    0: { items: 1 },
    //568: { items: 1 },
    1200: { items: 2 },
    1450: { items: 3 },
  };

  return (
    <Box
      component={"section"}
      sx={{
        backgroundColor: "#f7f1ff",

        paddingRight: 0,
        paddingLeft: 0,

        paddingBottom: "130px",
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
          paddingBottom: 0,
        },

        overflow: "hidden",
      }}
    >
      {/* <Stack
        direction={isDown1100 ? "column" : "row"}
        justifyContent="space-between"
        paddingLeft={{ xs: "20px", sm: "40px" }}
        paddingRight={{ xs: "20px", sm: "40px" }}
        sx={{ padding: "40px" }}
        alignItems="center"
        textAlign={isDown1100 && "center"}
        width="100%"
        //width={isDown1100 ? "100%" : "80%"}
        marginRight="auto"
        marginLeft="auto"
        rowGap={isDown1100 ? 2 : ""}
      >
        <Typography variant="h3" fontWeight="bold">
          {landing.blogSection.title}
        </Typography>
        <Typography variant="body1">
          {landing.blogSection.text2 &&
            landing.blogSection.text2.split("\n").map((paragraph, index) => (
              <Fragment key={index}>
                {paragraph.includes("SmileUP+") ? (
                  <>
                    {paragraph.split("SmileUP+").map((part, partIndex) => (
                      <Fragment key={partIndex}>
                        {partIndex > 0 && (
                          <span
                            style={{
                              color: Colors.grey,
                              fontWeight: "bold",
                            }}
                          >
                            Smile
                            <span
                              style={{
                                color: Colors.smileUpYellow,
                                fontWeight: "bold",
                              }}
                            >
                              UP+
                            </span>
                          </span>
                        )}
                        {part}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  paragraph
                )}
                <br />
              </Fragment>
            ))}
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: "#8828FF" }}>
          {landing.blogSection.btnText}
        </Button>
      </Stack> */}

      {/* <AliceCarousel
        mouseTracking
        disableDotsControls
        items={items}
        responsive={responsive}
        paddingLeft={25}
        paddingRight={25}
        activeIndex={activeIndex}
        onSlideChanged={({ item }) => setActiveIndex(item)}
        renderPrevButton={() => (
          <IconButton
            className="control-button"
            onClick={slidePrev}
            disabled={activeIndex === 0}
            style={{
              backgroundColor: activeIndex === 0 ? "#ccc" : "#8828FF",
              color: "#fff",
              borderRadius: "50%",
              padding: "5px 5px",
              display: isDown899 ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px",
            }}
          >
            <FiChevronLeft />
          </IconButton>
        )}
        renderNextButton={() => (
          <IconButton
            className="control-button"
            onClick={slideNext}
            disabled={
              activeIndex === items.length - (responsive[1450]?.items || 1)
            }
            style={{
              backgroundColor:
                activeIndex === items.length - (responsive[1450]?.items || 1)
                  ? "#ccc"
                  : "#8828FF",
              color: "#fff",
              borderRadius: "50%",
              padding: "5px 5px",
              display: isDown899 ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px",
            }}
          >
            <FiChevronRight />
          </IconButton>
        )}
      /> */}
    </Box>
  );
};

export default BlogSection;
