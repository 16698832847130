import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";

import SmileUpDetails from "../smileUpDetails/SmileUpDetails";
import InterestLinks from "../smileUpLinks/InterestLinks";
import WeAre from "../smileUpLinks/WeAre";
import AboutUs from "../smileUpLinks/AboutUs";

const Footer = ({ footer }) => {
  return (
    <Box
      component="footer"
      margin={{ sm: "0 auto", md: "" }}
      sx={{
        backgroundColor: "#260055",
        paddingLeft: { xs: "20px", sm: "40px", lg: "90px" },
        paddingRight: { xs: "20px", sm: "40px", lg: "90px" },
        paddingTop: "90px",
        paddingBottom: "90px",
        margin: { sm: "0 auto", md: "" },
      }}
    >
      <Grid container spacing={10}>
        <Grid item md={3} xs={12}>
          <SmileUpDetails footer={footer} />
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <InterestLinks footer={footer} />
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <WeAre footer={footer} />
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <AboutUs footer={footer} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
