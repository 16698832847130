import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { timestampCreador } from "../../../firebase/firebase-config";
import { createDreamSchema } from "../../../schema/createDreamSchema/createDreamSchema";
import {
  createDream,
  createImageForDream,
} from "../../../store/actions/dreamers";
import DreamDetails from "../dreamDetails/DreamDetails";
import Dragndrop from "../dropzone/DropZone";
import Preview from "../dropzone/Preview";
import HelpType from "../helpType/HelpType";
import ConfirmDreamModal from "../../makerPage/confirmDream/ConfirmDream";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { useTheme } from "@emotion/react";

const MySwal = withReactContent(Swal);

const CreateDreamForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { dreamersPage } = useSelector((state) => state.loggedUser.language);
  const [imgFiles, setImgFiles] = useState([]);
  const [openDreammodal, setOpenDreamModal] = useState(false);
  const navigate = useNavigate();
  const currenUser = useSelector((state) => state.loggedUser.data);
  const { dreamForm } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const formik = useFormik({
    initialValues: {
      dreamName: "",
      typeOfDream: "",
      typeOfHelp: "",
      deliveryPlace: "",
      description: "",
      availableTime: "",
      moneyHelp: "",
      categories: [],
      file: imgFiles,
    },

    // validateOnMount: true,
    validationSchema: createDreamSchema,
    onSubmit: async (values) => {
      const dreamData = {
        name: values.dreamName,
        description: values.description,
        categories: values.categories,
        imgURL: "",
        cdate: timestampCreador.fromDate(new Date()),
        typeOfDream: values.typeOfDream,
        typeOfHelp: values.typeOfHelp,
        availableTime: values.availableTime,
        deliveryPlace: values.deliveryPlace,
        state: "pending",
        //moneyHelp: values.moneyHelp,
      };

      const confirmResult = await Swal.fire({
        title: dreamForm?.confirmCreate,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: dreamForm?.yes,
        cancelButtonText: dreamForm?.cancel,
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Creando",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const newDreamId = await createDream(currenUser.id, dreamData);
          createImageForDream(imgFiles[0], currenUser.id, newDreamId);

          setOpenDreamModal(true);

          formik.resetForm();
          removeItem();
          loadingSwal.close();
        } catch (error) {
          loadingSwal.close();
          MySwal.fire("Error", dreamForm?.error, "error");
          console.log(error);
        }
      }
    },
  });
  const navigateTo = (link) => {
    navigate(link);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const imagesArray = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: typeof file === "string" ? file : URL.createObjectURL(file),
        })
      );

      setImgFiles(imgFiles.concat(imagesArray));
    },
    [setImgFiles, imgFiles]
  );

  const removeItem = (index) => {
    const newFileList = [...imgFiles];

    newFileList.splice(index, 1);
    setImgFiles(newFileList);
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
          padding: 0,
        },
      }}
    >
      <form
        style={{ marginTop: "20px", marginBottom: "20px" }}
        onSubmit={(event) => {
          event.preventDefault(event);
          formik.handleSubmit();
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 2,
              }}
            >
              <IconButton
                onClick={() => navigateTo("/home/dreamer/my-dreams")} // Replace "/your-back-route" with the appropriate route
                sx={{ ml: 0, pl: 0, mr: { xs: 0, md: 1 } }} // No margin-right on small screens and down
              >
                <MdArrowBack />
              </IconButton>
              <Typography
                fontWeight="bold"
                textAlign={{ xs: "center", md: "left" }}
                variant={isSmallScreen ? "h4" : "h3"} // Conditionally set the variant
              >
                <span style={{ color: "#7d4af0" }}> {dreamForm?.create}</span>{" "}
                {dreamForm?.dream}
              </Typography>
            </Box>

            <FormControl
              sx={{
                width: "100%",
                backgroundColor: "white",
                padding: 0,
                mt: 2,
                mb: 2,
                borderRadius: "24px",
                border: "1px solid rgba(143, 146, 161, 0.2)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {imgFiles < 1 && (
                <Dragndrop
                  onDrop={onDrop}
                  accept={" 'image/*': ['.jpeg', '.png','.jpg']"}
                  maxFiles={1}
                />
              )}

              <div className="d-flex justify-content-center">
                <Preview files={imgFiles} removeOne={removeItem} />
              </div>
            </FormControl>

            <DreamDetails
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              dreamForm={dreamForm}
            />
            <HelpType
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              formik={formik}
              dreamForm={dreamForm}
            />

            <FormControl fullWidth sx={{ padding: 0, mt: 3 }}>
              <label htmlFor="description" style={{ fontWeight: "bold" }}>
                {dreamForm?.description}
              </label>
              <OutlinedInput
                id="description"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                rows={4}
                multiline
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                type={"textarea"}
                sx={{ borderRadius: "24px", backgroundColor: "#fff", mt: 1 }}
              />

              <FormHelperText error id="description">
                {formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : " "}
              </FormHelperText>
            </FormControl>

            <Stack>
              <Button
                type="submit"
                // disabled={!formik.isValid}
                variant="contained"
                sx={{
                  marginLeft: "auto",
                  display: "block",
                  width: { md: "25%", xs: "55%" },
                  mt: 2,
                  backgroundColor: "#252831",
                }}
              >
                {dreamForm?.createDream}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>

      <ConfirmDreamModal
        open={openDreammodal}
        handleOpen={setOpenDreamModal}
        content={dreamersPage.dreamPosted || "Tu sueño ha sido publicado"}
        dreamForm={dreamForm}
      />
    </Container>
  );
};

export default CreateDreamForm;
