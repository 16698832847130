import { CircularProgress, Grid, Stack, useMediaQuery } from "@mui/material";
import DreamGrid from "../DreamsGrid/DreamsGrid";
import CausesChipRow from "../causesChipRow/CausesChipRow";
import Filters from "../filters/Filters";

import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import useFilterCategDreams from "../../../customHooks/useFilterCategDreams";
import { getDreamers, getDreamsBatch } from "../../../store/actions/dreamers";
import Hero from "../hero/Hero";
import useFilterDreams from "../../../customHooks/useFilterDreams";
import ToggleAccount from "../../toggleAccount/ToggleAccount";
import { useLocation } from "react-router-dom";
import LanguageDropdown from "../../general/LanguageDropdown";
import Colors from "../../../utils/Colors";
import HeaderControls from "../../general/HeaderControls";

const Dreams = () => {
  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown580 = useMediaQuery("(max-width:580px)");
  const location = useLocation();
  const dispatch = useDispatch();

  const dreams = useSelector((state) => state.dreamers.dreams);
  const dreamers = useSelector((state) => state.dreamers.dreamers);

  const selectedCategory = useSelector(
    (state) => state.loggedUser.selectedCategory
  );
  const filteredDreamsByCateg = useFilterCategDreams(selectedCategory, dreams);
  const dreamFilters = useSelector((state) => state.makers.dreamFilters);
  const filteredDreamsByOpt = useFilterDreams(
    filteredDreamsByCateg,
    dreamFilters?.[0] || ["recent"]
  );

  // const filteredDreamsByOpt = useFilterDreams(filteredDreamsByCateg, [
  //   "recent",
  //   "close",
  //   "dreams",
  //   "ascending",
  // ]);
  const [lastDream, setLastDream] = useState(null);
  const [dreamsNumber, setDreamsNumber] = useState(100);
  //const [triggerNextDreams, setTriggerNextDreams] = useState(false);

  const BATCH_SIZE = 5;

  const getDreams = async () => {
    const setDreamsData = (lastDream, dreamsNum) => {
      setLastDream(lastDream);
      setDreamsNumber(dreamsNum);
    };

    dispatch(getDreamsBatch(lastDream, BATCH_SIZE, setDreamsData));
  };

  useEffect(() => {
    if (dreams) {
      dispatch(getDreamers(dreams, dreamers));
    }
  }, [dreams]);

  useEffect(() => {
    if (dreamsNumber >= BATCH_SIZE) {
      getDreams();
    }
  }, []);

  return (
    <>
      <Grid
        item
        container
        xl={11}
        lg={11}
        md={12}
        xs={12}
        mt={7}
        minHeight="100vh"
      >
        <HeaderControls rel={false} />
        <section
          style={{
            padding: isDown700 ? (isDown580 ? "0 20px" : "0 40px") : "0 40px",
          }}
        >
          <Hero />

          <CausesChipRow />
          <Filters />

          <InfiniteScroll
            style={{ scrollbarWidth: "none" }}
            dataLength={filteredDreamsByCateg.length} //This is important field to render the next data
            next={getDreams}
            hasMore={dreamsNumber >= BATCH_SIZE}
            loader={
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <CircularProgress />
              </Stack>
            }
            endMessage={
              <p style={{ textAlign: "center", marginTop: "40px" }}>
                <b>Parece que no hay más sueños 😐 </b>
              </p>
            }
          >
            <DreamGrid dreams={filteredDreamsByOpt} />
          </InfiniteScroll>
        </section>
      </Grid>
    </>
  );
};

export default Dreams;
