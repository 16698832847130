export const Provinces = [
  {
    value: "San José",
    provinceId: 1,
    label: "San José",
    provinceName: "San José",
  },
  {
    value: "Alajuela",
    provinceId: 2,
    provinceName: "Alajuela",
    label: "Alajuela",
  },
  {
    value: "Cartago",
    provinceId: 3,
    provinceName: "Cartago",
    label: "Cartago",
  },
  {
    value: "Heredia",
    provinceId: 4,
    provinceName: "Heredia",
    label: "Heredia",
  },
  {
    value: "Guanacaste",
    provinceId: 5,
    provinceName: "Guanacaste",
    label: "Guanacaste",
  },
  {
    value: "Puntarenas",
    provinceId: 6,
    provinceName: "Puntarenas",
    label: "Puntarenas",
  },
  { value: "Limón", provinceId: 7, provinceName: "Limón", label: "Limón" },
];
