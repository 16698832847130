import { FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NeedAnAccountModal from "../needAnAccountModal/NeedAnAccountModal";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ToggleAccount = ({ currentType, fontColor, bgColor }) => {
  const userData = useSelector((state) => state.loggedUser.data);
  const navigate = useNavigate();
  const { general, lang } = useSelector((state) => state.loggedUser.language);
  const [openAskForAccountModal, setAskForAccountModal] = useState(false);
  const location = useLocation();

  const [isUserAbleToChange, setIsUserAbleToChange] = useState("");

  useEffect(() => {
    if (userData) {
      const type =
        userData.isDreamer || (userData.isMaker && userData.isDreamer);
      setIsUserAbleToChange(type);
    }
  }, [userData]);

  const handleChange = () => {
    if (location.pathname.includes("dreamer")) {
      navigate("/home/maker");
    } else {
      navigate("/home/dreamer");
    }
  };

  return (
    <>
      <div style={{ minWidth: 120, borderRadius: "12px" }}>
        <FormControl
          fullWidth
          sx={{
            background: bgColor ? bgColor : "transparent",
            color: "#fff",
            borderRadius: "12px",
            borderWidth: 0,
          }}
        >
          <Select
            IconComponent={ArrowDropDownIcon}
            value={currentType}
            onChange={handleChange}
            sx={{
              borderRadius: "12px",
              color: fontColor ? fontColor : "#8F92A1",
              "& .MuiSvgIcon-root": {
                color: fontColor ? fontColor : "#8F92A1",
              },
              border: "none",
            }}
          >
            <MenuItem value={"dreamer"}>{general?.dreamer}</MenuItem>
            <MenuItem value={"maker"}>{general?.maker}</MenuItem>
          </Select>
        </FormControl>
      </div>

      <NeedAnAccountModal
        open={openAskForAccountModal}
        handleOpen={setAskForAccountModal}
      />
    </>
  );
};

export default ToggleAccount;
