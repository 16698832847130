import { Box, Card, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";

const FinancialCard = ({ iconSource, title, content }) => {
  return (
    <Card
      variant="outline"
      sx={{ width: "286px", height: "250px", borderRadius: "20px" }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          sx={{ flex: 1 }}
          alignItems={"center"}
        >
          <Box
            sx={{
              backgroundColor: "#F8F2FF",
              borderRadius: "50%",
              width: "72px",
              height: "72px",
              padding: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <img src={iconSource} alt="" />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          sx={{ flex: 1 }}
        >
          <Typography textAlign="center" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        {/* <Typography textAlign="center" sx={{ fontSize: "12px" }}>
          {" "}
          {title}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default FinancialCard;
