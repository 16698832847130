import React from "react";
import { ThemeProvider } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";

import Router from "./app/routes/Router";

function App() {
  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
