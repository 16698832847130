import { Drawer, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import RightMenuEvents from "../../../components/RightMenu/RightMenuEvents";

import { useDispatch, useSelector } from "react-redux";
import {
  getEventBatch,
  getEventsFromCurrentMonth,
  getEventsFromCurrentWeek,
} from "../../../store/actions/events";
import EventsMainContent from "../../../components/dreamerPage/eventsMainContent/EventsMainContent";
import RightNotificationMenu from "../../../components/RightMenu/RightNotificationMenu";
import { Animated } from "react-animated-css";

const Events = () => {
  const [openRightSidebar, setOpenRightSidebar] = useState(false);
  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [lastEvent, setLastEvent] = useState(null);
  const [lastMonthlyEvent, setLastMonthlyEvent] = useState(null);
  const [lastWeekEvent, setLastWeekEvent] = useState(null);

  const [eventsNumber, setEventsNumber] = useState(100);
  const [monthlyEventsNumber, setMonthlyEventsNumber] = useState(100);
  const [weekEventsNumber, setWeekEventsNumber] = useState(100);

  const [events, setEvents] = useState([]);
  const [monthEvents, setMonthEvents] = useState([]);
  const [weekEvents, setWeekEvents] = useState([]);

  const dispatch = useDispatch();

  const { events: reduxEvents } = useSelector((state) => state.events);
  const { monthlyEvents: reduxMonthlyEvents } = useSelector(
    (state) => state.events
  );
  const { weekEvents: reduxWeekEvents } = useSelector((state) => state.events);

  const BATCH_SIZE = 5;

  const handleAllEventsScroll = () => {
    dispatch(getEventBatch(lastEvent, BATCH_SIZE));
  };

  const handleMonthlyEventsScroll = () => {
    dispatch(getEventsFromCurrentMonth(lastMonthlyEvent, BATCH_SIZE));
  };

  const handleWeekEventsScroll = () => {
    dispatch(getEventsFromCurrentWeek(lastWeekEvent, BATCH_SIZE));
  };

  //Sets up events state: All tab
  useEffect(() => {
    if (reduxEvents?.length) {
      setEvents(reduxEvents);
      setLastEvent(reduxEvents[reduxEvents.length - 1]);
      setEventsNumber(reduxEvents.length);
    } else {
      if (eventsNumber >= BATCH_SIZE) {
        handleAllEventsScroll();
      }
    }
  }, []);

  //Sets up events state: Month tab
  useEffect(() => {
    if (reduxMonthlyEvents?.length) {
      setMonthEvents(reduxMonthlyEvents);
      setLastWeekEvent(reduxMonthlyEvents[reduxMonthlyEvents.length - 1]);
      setWeekEventsNumber(reduxMonthlyEvents.length);
    } else {
      if (weekEventsNumber >= BATCH_SIZE) {
        handleMonthlyEventsScroll();
      }
    }
  }, []);

  //Sets up events state: Week tab
  useEffect(() => {
    if (reduxWeekEvents?.length) {
      setWeekEvents(reduxWeekEvents);
      setLastMonthlyEvent(reduxWeekEvents[reduxWeekEvents.length - 1]);
      setMonthlyEventsNumber(reduxWeekEvents.length);
    } else {
      if (monthlyEventsNumber >= BATCH_SIZE) {
        handleWeekEventsScroll();
      }
    }
  }, []);

  //Tracks events changes through scroll: All tab
  useEffect(() => {
    if (reduxEvents) {
      setEvents(reduxEvents);
      setLastEvent(reduxEvents[reduxEvents.length - 1]);
      setEventsNumber(reduxEvents.length);
    }
  }, [reduxEvents]);

  //Tracks events changes through scroll: Month tab
  useEffect(() => {
    if (reduxMonthlyEvents) {
      setMonthEvents(reduxMonthlyEvents);
      setLastMonthlyEvent(reduxMonthlyEvents[reduxMonthlyEvents.length - 1]);
      setMonthlyEventsNumber(reduxMonthlyEvents.length);
    }
  }, [reduxMonthlyEvents]);

  //Tracks events changes through scroll: Week tab
  useEffect(() => {
    if (reduxWeekEvents) {
      setWeekEvents(reduxWeekEvents);
      setLastWeekEvent(reduxWeekEvents[reduxWeekEvents.length - 1]);
      setWeekEventsNumber(reduxWeekEvents.length);
    }
  }, [reduxWeekEvents]);

  return (
    <>
      <Grid
        item
        minHeight="100vh"
        xl={9}
        lg={8}
        md={11}
        xs={12}
        sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
      >
        <EventsMainContent
          allEvents={events}
          nextEventsAllTab={handleAllEventsScroll}
          eventsNumber={eventsNumber}
          monthEvents={monthEvents}
          nextEventsMonthTab={handleMonthlyEventsScroll}
          monthlyEventsNumber={monthlyEventsNumber}
          weekEvents={weekEvents}
          nextEventsWeekTab={handleWeekEventsScroll}
          weekEventsNumber={weekEventsNumber}
          setOpenRightSidebar={setOpenRightSidebar}
        />
      </Grid>
      <Grid item xl={2} lg={3} position="fixed" right={0}>
        <Drawer
          anchor="right"
          open={openRightSidebar}
          onClose={() => setOpenRightSidebar(false)}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "32px",
              borderBottomLeftRadius: "32px",
              width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
            },
          }}
        >
          {openNotificationsMod ? (
            <RightNotificationMenu
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
            />
          ) : (
            <RightMenuEvents
              events={events}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
              setOpenRightSidebar={setOpenRightSidebar}
            />
          )}
        </Drawer>

        <Grid
          item
          xl={2}
          lg={3}
          position="fixed"
          sx={{ right: 0, width: "100%" }}
          display={{ xs: "none", lg: "block" }}
        >
          {!openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightMenuEvents
                events={events}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
                setOpenRightSidebar={setOpenRightSidebar}
              />
            </Animated>
          )}

          {openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightNotificationMenu
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
              />
            </Animated>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Events;
