import { FaShare } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Button, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const EventUserActions = () => {
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  return (
    <>
      <Stack direction="row" alignItems="center" gap={3}>
        <Button variant="text">
          <FaShare />
          <Typography fontWeight="bold">{general?.share}</Typography>
        </Button>

        <Button
          variant="outlined"
          sx={{
            borderRadius: "4px",
            color: "#000",
            borderColor: "rgba(143, 146, 161, 0.2)",
          }}
        >
          <FiCheck />
          {general?.registerText}
        </Button>

        {/* <BiDotsVerticalRounded size={21} /> */}
      </Stack>
    </>
  );
};

export default EventUserActions;
