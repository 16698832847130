import { useState, useEffect, Fragment } from "react";
import { Box, CardContent, Stack, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import defaultAvatar from "../../../assets/img/default-avatar.svg";
import { useSelector } from "react-redux";
import Colors from "../../../utils/Colors";

const TestimonialSlide = ({ name, association, text, textEng }) => {
  const [fontSize, setFontSize] = useState("13px");
  const language = useSelector((state) => state.loggedUser.language);
  const theme = useTheme();
  const { english, spanish, lang } = language;

  useEffect(() => {
    const textLength = text.length;
    if (textLength > 500) {
      setFontSize("12px"); // Adjust the font size as needed
    } else if (textLength > 50) {
      setFontSize("13px"); // Adjust the font size as needed
    } else {
      setFontSize("15px"); // Default font size
    }
  }, [text]);

  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.75)",
        padding: "15px",
        minHeight: "285px",
        maxHeight: "285px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        borderRadius: "20px",
        boxShadow: "none",
        pr: 1.5,
        [theme.breakpoints.down("md")]: {
          minHeight: "275px",
          maxHeight: "275px",
        },

        [theme.breakpoints.down("sm")]: {
          minHeight: "450px",
          maxHeight: "450px",
          pt: 5,
          mt: 6,
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minHeight: "175px",
            maxHeight: "175px",
            pt: 4,
          }}
        >
          <Typography sx={{ fontSize: fontSize }} color="#8F8F8F">
            {lang === "en"
              ? textEng.split("\n").map((paragraph, index) => (
                  <Fragment key={index}>
                    {paragraph.includes("SmileUP+") ? (
                      <>
                        {paragraph.split("SmileUP+").map((part, partIndex) => (
                          <Fragment key={partIndex}>
                            {partIndex > 0 && (
                              <span
                                style={{
                                  color: "grey",
                                  fontWeight: "bold",
                                }}
                              >
                                Smile
                                <span
                                  style={{
                                    color: "#FFD700", // Your desired color
                                    fontWeight: "bold",
                                  }}
                                >
                                  UP+
                                </span>
                              </span>
                            )}
                            {part}
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      paragraph
                    )}
                  </Fragment>
                ))
              : text.split("\n").map((paragraph, index) => (
                  <Fragment key={index}>
                    <FormatQuoteIcon sx={{ transform: "scaleX(-1)" }} />{" "}
                    {paragraph.includes("SmileUP+") ? (
                      <>
                        {paragraph.split("SmileUP+").map((part, partIndex) => (
                          <Fragment key={partIndex}>
                            {partIndex > 0 && (
                              <span
                                style={{
                                  color: Colors.grey,
                                  fontWeight: "bold",
                                }}
                              >
                                Smile
                                <span
                                  style={{
                                    color: Colors.smileUpYellow,
                                    fontWeight: "bold",
                                  }}
                                >
                                  UP+
                                </span>
                              </span>
                            )}
                            {part}
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      paragraph
                    )}
                  </Fragment>
                ))}
            <FormatQuoteIcon />
          </Typography>
        </Box>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{
            width: "100%",
            mt: 4,
            [theme.breakpoints.down("sm")]: {
              pt: 10,
            },
          }}
        >
          <Avatar alt="Persona" src={defaultAvatar} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "1rem" }}>{name}</Typography>
            <Typography sx={{ fontSize: "0.7rem" }}>{association}</Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TestimonialSlide;
