import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useFormik } from "formik";

import { FiArrowLeft } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import { profileConfigSchema } from "../../../schema/profileConfigOptions/profileConfigOptions";
import ConfigMyAccount from "../configMyAccount/ConfigMyAccount";
import ConfigSocialMedia from "../configSocialMedia/ConfigSocialMedia";
import { updateProfile } from "../../../store/actions/userActions";
import { useSelector } from "react-redux";
import { callSuccessAlert } from "../../../customAlert/SuccessAlert";
import { callUpdateAlert } from "../../../customAlert/UpdateElement";

const ProfileConfigOptions = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.loggedUser.data);
  const userData = useSelector((state) => state.loggedUser.data);
  const { general } = useSelector((state) => state.loggedUser.language);
  const isDown450 = useMediaQuery("(max-width:450px)");
  const language = useSelector((state) => state.loggedUser.language);

  const { english, spanish, lang } = language;

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        language: userData?.profileSettings?.language ?? "",
        currency: userData?.profileSettings?.currency ?? "",
        showEmail: userData?.profileSettings?.showEmail === true ? "Si" : "No",
        showProfiles:
          userData?.profileSettings?.showProfiles === true ? "Si" : "No",
        showSocialProfiles:
          userData?.settings?.showSocial === true ? "Si" : "No",
        facebook: userData?.social?.facebook ?? "",
        twitter: userData?.social?.twitter ?? "",
        twitch: userData?.social?.twitch ?? "",
        instagram: userData?.social?.instagram ?? "",
        youtube: userData?.social?.youtube ?? "",
        discord: userData?.social?.discord ?? "",
      },
      validationSchema: profileConfigSchema,
      onSubmit: (values) => {
        const profileConfig = {
          profileSettings: {
            language: values.language,
            currency: values.currency,
            showEmail: values.showEmail === "Si" ? true : false,
            showProfiles: values.showProfiles === "Si" ? true : false,
            showSocial: values.showSocialProfiles === "Si" ? true : false,
          },
        };

        const profileSocialData = {
          social: {
            facebook: values.facebook,
            twitter: values.twitter,
            twitch: values.twitch,
            instagram: values.instagram,
            youtube: values.youtube,
            discord: values.discord,
          },
        };

        const updateProfileConfiguration = () => {
          const updateSocial = () => {
            updateProfile(currentUser.id, profileSocialData);
          };

          updateProfile(currentUser.id, profileConfig, updateSocial);
        };

        callUpdateAlert(
          updateProfileConfiguration,
          undefined,
          `${general?.updatedProfile}`,

          `${general?.confirmUpdate}`
        );
      },
    });

  return (
    // <Box component="main"> mt={10}
    <Grid
      xl={9}
      lg={8}
      md={11}
      xs={12}
      item
      minHeight="100vh"
      bgcolor="#f7f7f7"
      sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
    >
      <Grid item xs={12} container>
        <Box sx={{ display: "flex", alignItems: "center" }} mb={4}>
          <IconButton
            onClick={() => navigate(-1)}
            disableFocusRipple
            sx={{
              cursor: "pointer",
              display: "block",
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            <FiArrowLeft size={24} />
          </IconButton>

          {lang === "en" ? (
            <Typography variant={isDown450 ? "h4" : "h3"} fontWeight="bold">
              <span style={{ color: "#864bf1" }}>{general?.profile}</span>{" "}
              {general?.config}
            </Typography>
          ) : (
            <Typography variant={isDown450 ? "h4" : "h3"} fontWeight="bold">
              {general?.config} {general?.of}{" "}
              <span style={{ color: "#864bf1" }}>{general?.profile}</span>
            </Typography>
          )}
        </Box>
      </Grid>
      <Box component="main">
        <form onSubmit={(event) => handleSubmit(event)}>
          <ConfigMyAccount
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            general={general}
          />

          <ConfigSocialMedia
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            general={general}
          />

          <Stack direction="row">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#000",
                padding: "10px 25px",
                marginTop: "20px",
              }}
            >
              {general?.saveChanges}
            </Button>
          </Stack>
        </form>
      </Box>
    </Grid>
  );
};

export default ProfileConfigOptions;
