import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import TestimonialSlide from "../testimonialSlide/TestimonialSlide";

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Box, useMediaQuery } from "@mui/material";
import { testimonies } from "../../../utils/otherData";

const TestimonialCarousel = () => {
  const handleDragStart = (e) => e.preventDefault();

  const isDown899 = useMediaQuery("(max-width:899px)");

  const items = testimonies.map((testimonial) => (
    <Box
      key={testimonial.id}
      className="item"
      sx={{ width: "550px", padding: "15px" }}
      onDragStart={handleDragStart}
    >
      <TestimonialSlide
        name={testimonial.name}
        association={testimonial.association}
        text={testimonial.text}
        textEng={testimonial.textEng}
      />
    </Box>
  ));

  const responsive = {
    0: { items: 1 },
    700: { items: 2 },
    1200: { items: 2 },
    1450: { items: 3 },
  };

  return (
    <AliceCarousel
      paddingLeft={0}
      paddingRight={0}
      autoWidth
      disableDotsControls
      mouseTracking
      items={items}
      responsive={responsive}
      infinite={false}
      renderPrevButton={() => {
        return (
          <span
            className="control-button"
            style={{
              backgroundColor: "#8828FF",
              borderRadius: "50%",
              padding: "5px 5px",
              color: "#fff",
              position: "absolute",
              right: "90px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FiChevronLeft />
          </span>
        );
      }}
      renderNextButton={() => {
        return (
          <span
            className="control-button"
            style={{
              backgroundColor: "#8828FF",
              borderRadius: "50%",
              padding: "5px 5px",
              color: "#fff",
              position: "absolute",
              right: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FiChevronRight />
          </span>
        );
      }}
    />
  );
};

export default TestimonialCarousel;
