import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid"; // 🆕 Import Grid
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageDropdown from "../../general/LanguageDropdown";
import { useSelector } from "react-redux";

const NavigationBar = ({ setOpenRegister, setOpenLogIn }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { navbar } = useSelector((state) => state.loggedUser.language);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isWhite, setIsWhite] = useState(false);

  const pages = [
    { label: navbar?.home, url: "/" },
    { label: navbar?.about, url: "/about-us" },
    { label: navbar?.contact, url: "/contact" },
    { label: navbar?.faq, url: "/frequently-ask" },
  ];

  useEffect(() => {
    setIsWhite(
      location.pathname.includes("frequently") ||
        location.pathname.includes("contact")
    );
  }, [location.pathname]);

  return (
    <AppBar
      position="static"
      sx={{ background: "transparent", boxShadow: 0, py: { xs: 3, sm: 2 } }}
    >
      <Toolbar disableGutters>
        {/* Logo - Hidden on mobile */}
        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
          <img
            src={require("../../../assets/img/logoSmile.png")}
            width={{ xs: "170px", md: "250px" }}
            alt="Smile up logo"
          />
        </Box>

        {/* Mobile Menu Icon */}
        <Grid sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
          <IconButton
            size="large"
            aria-label="menu"
            onClick={(e) => setAnchorElNav(e.currentTarget)}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(anchorElNav)}
            onClose={() => setAnchorElNav(null)}
            sx={{ display: { xs: "block", lg: "none" } }}
          >
            {pages.map((page) => (
              <MenuItem key={page.label} onClick={() => navigate(page.url)}>
                <Typography
                  textAlign="center"
                  sx={{
                    color: isWhite ? "#fff" : theme.palette.primary.main,
                  }}
                >
                  {page.label}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Grid>

        {/* Desktop Navigation */}
        <Grid sx={{ display: { xs: "none", lg: "flex" }, flexGrow: 1 }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => navigate(page.url)}
              sx={{
                my: 2,
                color: isWhite ? "#fff" : theme.palette.primary.main,
                fontWeight: 700,
              }}
            >
              {page.label}
            </Button>
          ))}
        </Grid>

        {/* Grid Container for Language, Login, Register */}
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{
            width: "auto",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {/* Language Selector */}
          <Grid item>
            <LanguageDropdown color={"white"} borderColor="white" />
          </Grid>

          {/* Login & Register Buttons */}
          <Grid item sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="text"
              onClick={() => setOpenLogIn(true)}
              sx={{
                color: isWhite ? "#fff" : "#556cd6",
                fontWeight: 700,
                [theme.breakpoints.down("sm")]: {
                  color: "#fff",
                },
              }}
            >
              Iniciar sesión
            </Button>

            <Button
              variant="outlined"
              onClick={() => setOpenRegister(true)}
              sx={{
                borderColor: "#fff",

                color: isWhite ? "#fff" : "#556cd6",
                fontWeight: 700,
                [theme.breakpoints.down("sm")]: {
                  color: "#fff",
                  borderColor: "#fff",
                },
                borderColor: "#fff",
              }}
            >
              Registrate
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
