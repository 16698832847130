import noImagePlaceholder from "../../assets/img/avatar-no-image.png";

import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomArrow from "./CustomArrow";

import { FiChevronLeft } from "react-icons/fi";

//import { iconsList } from "./iconList";
import { iconsList } from "./iconList";
import { makerIconsList } from "./iconList";
import { responsiveBreakpoints } from "./carouselBreakpoints";

import { Animated } from "react-animated-css";
import RightNotificationMenu from "../RightMenu/RightNotificationMenu";
import CommentsRightSection from "../dreamerPage/commentsRightSection/CommentsRightSection";
import { useEffect, useState } from "react";
import NavigationMenu from "../navigationMenu/NavigationMenu";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GoBackButton from "../general/buttons/GoBackButton";

const Visualizer = ({
  slides,
  openVisualizer,
  handleClose,
  post,
  setCommentsNumber,
  commentsNum,
}) => {
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const theme = useTheme();
  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [postOwnerData, setPostOwnerData] = useState(null);
  const [currentIconList, setCurrentIconsList] = useState(iconsList);

  const isDown750 = useMediaQuery("(max-width:751px)");
  const isDown1200 = useMediaQuery("(max-width:1201px)");

  const location = useLocation();
  const dreamers = useSelector((state) => state.dreamers.dreamers);
  const currentUser = useSelector((state) => state.loggedUser.data);

  useEffect(() => {
    const setPostOwner = () => {
      const postOwner = dreamers.find(
        (dreamer) => dreamer.id === post.userOwnerId
      );
      setPostOwnerData(postOwner);
    };

    if (dreamers) {
      setPostOwner();
      makerIconsList[2].url = `/home/maker/account/${currentUser.id}`;
    }
  }, [dreamers]);

  useEffect(() => {
    if (location.pathname.includes("maker")) {
      setCurrentIconsList(makerIconsList);
    } else {
      setCurrentIconsList(iconsList);
    }
  }, [location]);

  return (
    <Dialog
      fullScreen
      open={openVisualizer}
      onClose={() => handleClose(false)}
      disableAutoFocus
      sx={{ margin: 0 }}
      PaperProps={{
        sx: {
          bgcolor: "#1e1f20",
          boxShadow: "none",
          borderRadius: "20px !important",
          width: "100%",
          overflowX: "hidden !important",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "#1e1f20",
          },
        },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#1e1f20",
          overflow: "hidden",
          height: "100vh",
          [theme.breakpoints.down("sm")]: {
            height: "auto",
          },
        }}
      >
        <Grid
          item
          xs={1}
          sx={{ display: { md: "block", xs: "none" } }}
          //width="200px"
          width="100%"
          height="100vh"
          position="fixed"
        >
          <Box height="100vh" sx={{ display: { md: "block", xs: "none" } }}>
            <NavigationMenu
              iconsList={currentIconList}
              bgColor={
                location.pathname.includes("maker") ? "#9140f2" : "#0659fd"
              }
            />
          </Box>
        </Grid>
        <Grid item lg={1} xs={1}></Grid>
        <Grid item xs={12} md={11} bgcolor="#1e1f20">
          <Grid
            container
            height={{ xs: "100%", lg: "100%" }}
            rowGap={10}
            justifyContent={"start"}
          >
            <Grid item xl={9} lg={8} xs={12} alignItems="center">
              <Box
                height="100%"
                bgcolor="#1e1f20"
                position="relative"
                width="100%"
                margin="0 auto"
              >
                <Box height="100%">
                  <Stack
                    direction="row"
                    padding={isDown750 ? "40px 40px" : "40px 80px"}
                    position={{ xs: "static", lg: "absolute" }}
                    width="100%"
                  >
                    <GoBackButton
                      onClick={() => handleClose(false)}
                      text={general?.goBack}
                      color={"#4b4c4d"}
                    />

                    <Stack
                      direction="row"
                      gap={2}
                      alignItems="center"
                      ml="auto"
                    >
                      <Typography variant="body2" sx={{ color: "#fff" }}>
                        {post.name}
                      </Typography>
                      <Box width="38px" height="38px">
                        <img
                          src={postOwnerData?.imgURL || noImagePlaceholder}
                          width="100%"
                          height="100%"
                          style={{ borderRadius: "12px" }}
                        />
                      </Box>
                    </Stack>
                  </Stack>

                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box width="100%">
                      <Carousel
                        responsive={responsiveBreakpoints}
                        removeArrowOnDeviceType="mobile"
                        itemClass="d-flex justify-content-center"
                        customLeftArrow={<CustomArrow direction="left" />}
                        customRightArrow={<CustomArrow direction="right" />}
                      >
                        {slides.map((slide) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {slide?.imgURL ? (
                                <Box
                                  sx={{
                                    width: { xs: "80vw", sm: "60vw" },
                                    height: { xs: "100%", sm: "60vh" },
                                    borderRadius: "8px",
                                  }}
                                >
                                  <img
                                    src={slide.imgURL}
                                    alt=""
                                    style={{
                                      borderRadius: "8px",
                                      objectFit: "contain",
                                      width: "100%",
                                      height: "100%",
                                      maxWidth: isDown1200 ? "100%" : "60vw",
                                      maxHeight: isDown1200 ? "100%" : "60vh",
                                    }}
                                  />
                                </Box>
                              ) : (
                                <ReactPlayer url={slide.videoSource} />
                              )}
                            </div>
                          );
                        })}
                      </Carousel>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={0}
              bgcolor="#1e1f20"
              display={{ lg: "none" }}
            >
              <Box sx={{ height: "20px" }}></Box>
            </Grid>
            <Grid
              item
              xl={2}
              lg={3}
              xs={12}
              flex={1}
              position={{ xs: "static", lg: "fixed" }}
              sx={{ right: 0, width: "100%" }}
            >
              {openNotificationsMod && (
                <Animated
                  animationIn="fadeInRight"
                  animationOut="fadeOutLeft"
                  isVisible={true}
                  animationInDuration={500}
                  animationOutDuration={500}
                >
                  <RightNotificationMenu
                    userPhoto={noImagePlaceholder}
                    openPopupMenu={openPopupMenu}
                    setNotificationsMod={setNotificationsMod}
                    setOpenPopupMenu={setOpenPopupMenu}
                  />
                </Animated>
              )}

              {!openNotificationsMod && (
                <Animated
                  style={{ height: "100%" }}
                  animationIn="fadeInRight"
                  animationOut="fadeOutLeft"
                  isVisible={true}
                  animationInDuration={500}
                  animationOutDuration={500}
                >
                  <CommentsRightSection
                    post={post}
                    setNotificationsMod={setNotificationsMod}
                    setCommentsNumber={setCommentsNumber}
                    commentsNum={commentsNum}
                  />
                </Animated>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Visualizer;
