import {
  FaFacebookF,
  FaTwitter,
  FaInstagramSquare,
  FaDiscord,
  FaTwitch,
} from "react-icons/fa";

export const socialNetworkList = [
  {
    socialNetName: "@smileup",
    socialNetURL: "facebook.com",
    socialMediaIcon: <FaFacebookF size={12} />,
  },
  {
    socialNetName: "@smileup",
    socialNetURL: "facebook.com",
    socialMediaIcon: <FaTwitter size={12} />,
  },
  {
    socialNetName: "@smileup",
    socialNetURL: "facebook.com",
    socialMediaIcon: <FaInstagramSquare size={12} />,
  },
  {
    socialNetName: "@smileup",
    socialNetURL: "facebook.com",
    socialMediaIcon: <FaTwitch size={12} />,
  },
  {
    socialNetName: "@smileup",
    socialNetURL: "facebook.com",
    socialMediaIcon: <FaDiscord size={12} />,
  },
];
