import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ProfileInfo from "../../../components/profileInfo/ProfileInfo";
import UserProfileCard from "../../../components/dreamerPage/userProfileCard/UserProfileCard";
import TopUserActions from "../../../components/dreamerPage/topUserActions/TopUserActions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FiChevronLeft } from "react-icons/fi";
import { useSelector } from "react-redux";

const EditProfileInfo = () => {
  const isDown1440 = useMediaQuery("(max-width:1439px)");
  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown580 = useMediaQuery("(max-width:580px)");

  const navigation = useNavigate();

  const userData = useSelector((state) => state.loggedUser.data);
  const { profileSettings, general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const friends = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
  ];

  return (
    <Grid
      item
      xl={11}
      lg={11}
      md={12}
      xs={12}
      mt={7}
      //sx={{ padding: { md: "20px 40px", xs: "20px 25px" } }}
    >
      <section
        style={{
          padding: isDown700 ? (isDown580 ? "0 20px" : "0 40px") : "0 40px",
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={isDown1440 ? 12 : 3} xs={12}>
            <Box
              maxWidth={isDown1440 ? "100%" : "320px"}
              margin={isDown1440 ? 0 : "0 auto"}
            >
              <Stack direction="row" alignItems="center">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => navigation(-1)}
                  sx={{
                    borderRadius: "12px",
                    backgroundColor: "#e2e3e6",
                    color: "#8F92A1",
                    width: "103px",
                    fontWeight: "bold",
                    my: 3,
                    mr: "auto",
                    py: 2,
                  }}
                >
                  <FiChevronLeft />
                  {general?.goBack}
                </Button>
                {/* <Box display={{ md: "none", xs: "block" }}>
                  <TopUserActions
                    openPopupMenu={openPopupMenu}
                    setOpenPopupMenu={setOpenPopupMenu}
                    setNotificationsMod={setOpenRightSidebar}
                  />
                </Box> */}
              </Stack>
              <Box>
                <UserProfileCard
                  friends={friends}
                  userData={userData}
                  profileSettings={profileSettings}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={isDown1440 ? 12 : 9} xs={12}>
            {/* Using a button just to push the profile form down */}
            <Button
              variant="contained"
              disableElevation
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                backgroundColor: "transparent",
                color: "transparent",
                cursor: "auto !important",
                my: 3,
                mr: "auto",
                py: 2,
              }}
            >
              <FiChevronLeft />
              {general?.goBack}
            </Button>
            <Card
              variant="outline"
              sx={{ borderRadius: "12px", padding: "25px 15px" }}
            >
              <CardContent>
                <ProfileInfo profileSettings={profileSettings} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
    </Grid>
  );
};

export default EditProfileInfo;
