import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import TopUserActions from "../topUserActions/TopUserActions";
import PostComment from "../postComment/PostComment";
import { useState, useEffect } from "react";
import {
  createComment,
  getOneComment,
  getPostComments,
} from "../../../store/actions/dreamers";
import InfiniteScroll from "react-infinite-scroll-component";
import CommentsInput from "../commentsInput/CommentsInput";
import { useFormik } from "formik";
import { timestampCreador } from "../../../firebase/firebase-config";
import { useSelector } from "react-redux";

const CommentsRightSection = ({
  setCommentsNumber,
  commentsNum,
  post,
  setNotificationsMod,
}) => {
  const { comments } = useSelector((state) => state.loggedUser.language);
  const theme = useTheme();
  const [lastComment, setLastComment] = useState(null);
  const [batchCommentsNumber, setBatchCommentsNumber] = useState(100);
  const [postComments, setPostComments] = useState([]);
  const [commentCreatedId, setCommentCreatedId] = useState(0);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);

  const currentUserData = useSelector((state) => state.loggedUser.data);
  const BATCH_SIZE = 6;

  const { getFieldProps, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: { comment: "" },
    onSubmit: async (values) => {
      const commentData = {
        cdate: timestampCreador.fromDate(new Date()),
        content: values.comment,
        userRef: currentUserData.id,
        isActive: true,
      };

      const newDocID = await createComment(
        post.userOwnerId,
        post.id,
        commentData
      );

      setCommentCreatedId(newDocID);
    },
  });

  const getComments = async () => {
    const comments = await getPostComments(
      post.userOwnerId,
      post.id,
      BATCH_SIZE,
      lastComment
    );
    setPostComments((prevComments) => [...prevComments, ...comments]);
    setLastComment(comments[comments.length - 1]);
    setBatchCommentsNumber(comments.length);
  };

  useEffect(() => {
    if (batchCommentsNumber >= BATCH_SIZE) getComments();
  }, []);

  useEffect(() => {
    const getRecentlyCreatedComment = async () => {
      const data = await getOneComment(
        post.userOwnerId,
        post.id,
        commentCreatedId
      );
      setPostComments((prevComments) => [
        { ...data, commentOwnerData: currentUserData },
        ...prevComments,
      ]);
      setCommentsNumber((prevValue) => prevValue + 1);
    };

    if (commentCreatedId) getRecentlyCreatedComment();
  }, [commentCreatedId]);

  return (
    <Box
      bgcolor="#ffffff"
      height={{ xs: "100%", lg: "100vh" }}
      padding="25px 40px"
      fontSize="14px"
      sx={{
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: { xs: 0, lg: "32px" },
        borderTopRightRadius: { xs: "32px", lg: 0 },
        [theme.breakpoints.down("sm")]: {
          borderRadius: "32px",
          display: "none",
        },
      }}
    >
      <Stack>
        <Box marginLeft="auto" my={2}>
          <TopUserActions
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
            setNotificationsMod={setNotificationsMod}
          />
        </Box>
      </Stack>

      <Typography variant="h5" mb={2}>
        {comments?.comments} ({commentsNum})
      </Typography>
      <Box
        id="scrollableStack"
        sx={{
          overflowY: "auto",
          maxHeight: "65vh",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
          },
        }}
      >
        <InfiniteScroll
          scrollableTarget="scrollableStack"
          dataLength={postComments.length}
          next={getComments}
          hasMore={batchCommentsNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="100px"
            >
              <CircularProgress />
            </Stack>
          }
          // endMessage={
          //   <Typography textAlign="center" mt={2}>
          //     <b>{comments?.noMoreComments} 🙃</b>
          //   </Typography>
          // }
        >
          {postComments.map((comment) => (
            <PostComment
              data={comment}
              postId={post.id}
              key={comment.id}
              hasLike={false}
            />
          ))}
        </InfiniteScroll>
      </Box>

      <Box mt={2}>
        <CommentsInput
          handleSubmit={handleSubmit}
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Box>
    </Box>
  );
};

export default CommentsRightSection;
