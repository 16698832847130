import Swal from "sweetalert2";

export const callDeleteAlert = (
  deleteFn,
  handleRefresh,
  successMsg,
  alertMessage,
  ...deleteParameters
) => {
  Swal.fire({
    title: "Alerta",
    text: alertMessage,
    icon: "warning",
    confirmButtonText: "Aceptar",
    showDenyButton: true,
    denyButtonText: "Volver",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteFn(...deleteParameters);
      Swal.fire(successMsg, "", "success");
      if (handleRefresh) {
        handleRefresh();
      }
    }
  });
};

//"¿Esta seguro que desea modifcar?"
