import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { Box, Typography } from "@mui/material";

const CauseCard = ({ title, causesNum, illustration }) => {
  return (
    <Card
      sx={{
        width: 270,
        height: 270,
        backgroundColor: "rgba(136, 40, 255, 0.25)",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "cover",
            width: 170,
            height: 170,
            margin: "0 auto",
          }}
        >
          {illustration}
        </Box>
        <Typography
          color="#fff"
          fontWeight="bold"
          variant="h5"
          sx={{
            position: "absolute",
            bottom: 16,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CauseCard;
