import * as Yup from "yup";

export const profileSchema = Yup.object({
  name: Yup.string(),
  lastname: Yup.string(),
  secondLastname: Yup.string(),
  user: Yup.string(),
  phone: Yup.number().typeError("Formato no valido"),
  email: Yup.string().email().typeError("Formato no valido"),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "La contraseña no coincide"
  ),
});
