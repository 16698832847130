import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import bgLeftCard from "../../../assets/img/bg-left-card.png";
import toledo from "../../../assets/img/toledo.png";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useSelector } from "react-redux";

const LeftCard = () => {
  const isDown1560 = useMediaQuery("(max-width:1560px)");
  const { landing, signUp } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  return (
    <Card
      variant="outline"
      sx={{
        backgroundImage: `url(${bgLeftCard})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        width: "100%",
        height: "700px",
        padding: "15px",
        display: { xl: "flex", xs: "none" },
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid container minHeight="350px" display="flex" alignItems="center">
        <Grid item xl="5">
          <CardContent>
            <Typography variant="h4" fontWeight="bold" sx={{ color: "#fff" }}>
              {signUp?.process}
            </Typography>
          </CardContent>
          {/* </Card> */}
        </Grid>

        <Grid item xl="6" position="relative">
          <img
            src={toledo}
            alt=""
            width="350px"
            style={{ position: "absolute", top: -210, right: "-20px" }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack height="175px" padding="0 90px" marginBottom="20px">
          <Typography variant="h5" fontWeight="bold" mb={3} position="relative">
            {signUp?.connectb}
            <div
              style={{
                position: "absolute",
                left: "55%",
                backgroundColor: "#36fb90",
                height: "2px",
                width: "120px",
              }}
            ></div>
          </Typography>
          <Typography>{signUp?.description}</Typography>
        </Stack>
        <Stack
          direction="row"
          height="100px"
          justifyContent="center"
          spacing={2}
          marginTop="30px"
        >
          <span
            style={{
              borderRadius: "50%",
              border: "1px solid #EAEAEA",
              width: "50px",
              height: "50px",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#36FB90",
            }}
          >
            <FiChevronLeft />
          </span>
          <span
            style={{
              borderRadius: "50%",
              border: "1px solid #EAEAEA",
              width: "50px",
              height: "50px",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#36FB90",
            }}
          >
            <FiChevronRight />
          </span>
        </Stack>
      </Box>
    </Card>
  );
};

export default LeftCard;
