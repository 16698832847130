import { Box, Stack, Typography } from "@mui/material";

import EventMap from "../../components/dreamerPage/eventMap/EventMap";
import InterestedDreamer from "../dreamerPage/interestedDreamer/InterestedDreamer";
import LocationDetails from "../dreamerPage/locationDetails/LocationDetails";
import TopUserActions from "../dreamerPage/topUserActions/TopUserActions";
import { useSelector } from "react-redux";

const RightMenuSoloEvent = ({
  userPhoto,
  openPopupMenu,
  setOpenPopupMenu,
  setNotificationsMod,
  interestedUsers,
  setOpenRightSidebar,
}) => {
  const { general } = useSelector((state) => state.loggedUser.language);
  return (
    <Box
      sx={{
        backgroundColor: "#1e1f20",
        height: "100vh",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        padding: "20px 20px",
        overflow: "hidden",
      }}
    >
      <Stack direction="row">
        <Box my={1} width="100%">
          <TopUserActions
            setOpenRightSidebar={setOpenRightSidebar}
            setNotificationsMod={setNotificationsMod}
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
          />
        </Box>
      </Stack>

      <Typography variant="h5" sx={{ color: "#fff" }} width="60%">
        {general?.place}
      </Typography>

      <Box width="100%" height="240px" margin="20px auto">
        <EventMap />
      </Box>

      <Box my={3}>
        <LocationDetails />
      </Box>

      <Box>
        <Typography variant="h5" color="#fff">
          Interesados{" "}
        </Typography>

        <Stack my={3} gap={2}>
          {interestedUsers.map((user) => {
            return <InterestedDreamer userData={user} key={user.id} />;
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default RightMenuSoloEvent;
