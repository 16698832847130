import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import loginBg from "../../assets/img/login-sloth.png";

import { LogInForm } from "../../components/loginForm/LogInForm";
import { useState } from "react";

export const LogIn = ({ logIn }) => {
  const [open, setOpen] = useState(false);
  const [openPassRecovery, setOpenPassRecovery] = useState(false);
  const [openSignUpSteps, setOpenSignUpSteps] = useState(false);

  return (
    <div className="d-flex align-items-center vh-100">
      <Grid container>
        <Grid
          item
          md="7"
          sx={{
            background: `url(${loginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto 100%",
            height: "100vh",
          }}
        ></Grid>
        <Grid item md={5}>
          <Typography fontWeight="bold" variant="h4" component="h1">
            Bienvenido
          </Typography>
          <Typography variant="body1" sx={{ color: "rgba(143, 146, 161, 1)" }}>
            Iniciar Sesión
          </Typography>
          <LogInForm
            handleOpen={setOpen}
            handleOpenPassRecovery={setOpenPassRecovery}
            handleOpenSingInStep={setOpenSignUpSteps}
          />
        </Grid>
      </Grid>
    </div>
  );
};
