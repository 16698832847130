import * as React from "react";
import Alert from "@mui/material/Alert";

const PasswordRecoveryAlert = ({ msg }) => {
  return (
    <Alert severity="success" color="info">
      {msg}
    </Alert>
  );
};

export default PasswordRecoveryAlert;
