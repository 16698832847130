import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { FiChevronRight } from "react-icons/fi";
import ReactPlayer from "react-player";
import VideoDescription from "../videoDescription/VideoDescription";

const MyVideos = ({ videos, language }) => {
  const handleViewMore = () => {
    console.log("View More clicked");
    // Add logic for loading more videos or navigation
  };
  return (
    <>
      <Stack
        gap={3}
        bgcolor="background.paper"
        borderRadius="12px"
        padding="30px"
        sx={{ minHeight: "500px", maxHeight: "500px" }}
      >
        <Typography variant="h6" fontWeight="bold">
          {language?.videos || "Videos"}
        </Typography>
        {videos.length > 0 ? (
          <>
            <Grid container rowSpacing={4} sx={{ flexGrow: 1 }}>
              {videos.slice(0, 9).map((item) => (
                <Grid
                  item
                  xs={6} // 2 columns on mobile
                  sm={4} // 3 columns on tablets
                  md={4} // 4 columns on larger screens
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  key={item.id}
                  sx={{
                    position: "relative",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "120px",
                        maxHeight: "120px",
                        aspectRatio: "4/3",
                        position: "relative",
                        borderRadius: "12px",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                    >
                      {item.videoURL ? (
                        <ReactPlayer
                          url={item.videoURL}
                          width="100%"
                          height="100%"
                          controls
                          style={{ borderRadius: "12px" }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "grey.300",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            {language?.videoNotAvailable ||
                              "Video no disponible"}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <VideoDescription videoData={item} />
                  </Stack>
                </Grid>
              ))}
            </Grid>
            {/* {videos.length > 9 && (
              <Box display="flex" justifyContent="center" mt="auto">
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "4px",
                    width: "120px",
                  }}
                  endIcon={<FiChevronRight />}
                  onClick={handleViewMore}
                >
                  {language?.viewMore || "Ver más"}
                </Button>
              </Box>
            )} */}
          </>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ flexGrow: 1 }}
            display="flex"
            alignItems="center"
            justifyContent="center "
            height={"100%"}
          >
            <Typography variant="h5" component="h3">
              {language?.noVideosAvailable || "No hay videos disponibles"}
            </Typography>
          </Grid>
        )}
      </Stack>
    </>
  );
};

export default MyVideos;
