import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import PopupProfileConfig from "../dreamerPage/popupProfileConfig/PopupProfileConfig";
import { Animated } from "react-animated-css";
import CloseIcon from "@mui/icons-material/Close";
import NotificationItem from "../dreamerPage/notificationItem/NotificationItem";
import noImagePlaceholder from "../../assets/img/avatar-no-image.png";
import { useSelector } from "react-redux";
import { useState } from "react";

const RightNotificationMenu = ({
  openPopupMenu,
  setOpenPopupMenu,
  setNotificationsMod,
}) => {
  const { general } = useSelector((state) => state.loggedUser.language);
  const userData = useSelector((state) => state.loggedUser.data);
  const { notifications } = useSelector((state) => state.loggedUser);

  // State to control the number of notifications displayed
  const [displayCount, setDisplayCount] = useState(12);

  // Function to load more notifications
  const loadMoreNotifications = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#79d0f1",
        height: "100vh",
        width: "100%",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        overflowY: "auto",
        maxHeight: "100vh",
        padding: "10px",
        scrollbarWidth: "thin", // Firefox
        "&::-webkit-scrollbar": {
          width: "6px", // WebKit
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ccc",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#bbb",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Stack direction="row" padding="20px 10px"></Stack>
      <Grid container py={1} px={2}>
        <Grid item xs={6} justifyContent={"flex-start"}>
          <span
            onClick={() => setNotificationsMod(false)}
            style={{
              width: "48px",
              height: "48px",
              backgroundColor: "#1e1f20",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "22px",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </span>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <div
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "12px",
              position: "relative",
            }}
          >
            <img
              src={userData?.imgURL || noImagePlaceholder}
              onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
              alt=""
              style={{
                objectFit: "cover",
                borderRadius: "12px",
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />

            <Animated
              animationIn="fadeInDown"
              animationOut="fadeOutUp"
              isVisible={openPopupMenu}
              animationInDuration={500}
              animationOutDuration={500}
              animateOnMount={false}
              style={{ position: "relative", zIndex: "100" }}
            >
              <PopupProfileConfig />
            </Animated>
          </div>
        </Grid>
      </Grid>

      <Box maxWidth="320px" margin="0 auto">
        <Stack direction="row" alignItems="center" gap={2} my={3}>
          <Typography variant="h5" sx={{ color: "white" }}>
            {general?.notifications}
          </Typography>
          <span
            style={{
              width: "35px",
              height: "20px",
              backgroundColor: "#ff4e4e",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            {userData.unreadNotificationsNum}
          </span>
        </Stack>

        {/* Notification items */}
        <Box sx={{}}>
          {notifications?.slice(0, displayCount).map((notification) => (
            <Box key={notification.id}>
              <NotificationItem item={notification} userId={userData.id} />
            </Box>
          ))}
        </Box>

        {/* Load More Button */}
        {notifications?.length > displayCount && (
          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={loadMoreNotifications}
            >
              {general ? general.loadMore : "Load More"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RightNotificationMenu;
