import { Box, Chip } from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "../../../store/actions/userActions";

const CausesChipRow = () => {
  const handleDragStart = (e) => e.preventDefault();
  const dispatch = useDispatch();

  const selectedCategory = useSelector(
    (state) => state.loggedUser.selectedCategory
  );
  const { general, makersPage } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const chipList = [
    <Box sx={{ width: "210px", display: "flex", justifyContent: "flex-start" }}>
      <Chip
        onClick={() => dispatch(setCategory("all"))}
        onDragStart={handleDragStart}
        label={"⭐" + makersPage?.categoriesList?.c0}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          mb: 3,
          mt: 3,
          ml: 0,
          mr: 3,
          width: "200px",
          //width: "80%",
          //marginRight: "20px",
          backgroundColor: selectedCategory === "all" && "#efebf5",
        }}
      />
    </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("enviroment"))}
        onDragStart={handleDragStart}
        label={"🌲 " + makersPage?.categoriesList?.c3}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          m: 3,
          width: "200px",
          backgroundColor: selectedCategory === "enviroment" && "#efebf5",
        }}
      />
    </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("shelter"))}
        onDragStart={handleDragStart}
        label={"🗳️ " + makersPage?.categoriesList?.c4}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          m: 3,
          width: "200px",
          backgroundColor: selectedCategory === "shelter" && "#efebf5",
        }}
      />
    </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("arts"))}
        onDragStart={handleDragStart}
        label={"✒️ " + makersPage?.categoriesList?.c5}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          m: 3,
          width: "200px",
          backgroundColor: selectedCategory === "arts" && "#efebf5",
        }}
      />
    </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("sports"))}
        onDragStart={handleDragStart}
        label={"🏃 " + makersPage?.categoriesList?.c6}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          m: 3,
          width: "200px",
          backgroundColor: selectedCategory === "sports" && "#efebf5",
        }}
      />
    </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("education"))}
        onDragStart={handleDragStart}
        label={"🧑‍🎓 " + makersPage?.categoriesList?.c7}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          m: 3,
          width: "200px",
          backgroundColor: selectedCategory === "education" && "#efebf5",
        }}
      />
    </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("health"))}
        onDragStart={handleDragStart}
        label={"🧑‍⚕️ " + makersPage?.categoriesList?.c2}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          m: 3,
          width: "200px",
          backgroundColor: selectedCategory === "health" && "#efebf5",
        }}
      />
    </Box>,
    // <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
    //   <Chip
    //     onClick={() => dispatch(setCategory("transport"))}
    //     onDragStart={handleDragStart}
    //     label={"🚗 " + makersPage?.categoriesList?.c1}
    //     variant="outlined"
    //     clickable
    //     sx={{
    //       border: "1px solid #eaeaea",
    //       userSelect: "none",
    //       m: 3,
    //       width: "200px",
    //       backgroundColor: selectedCategory === "transport" && "#efebf5",
    //     }}
    //   />
    // </Box>,
    <Box sx={{ width: "210px", display: "flex", justifyContent: "center" }}>
      <Chip
        onClick={() => dispatch(setCategory("others"))}
        onDragStart={handleDragStart}
        label={"👀 " + makersPage?.categoriesList?.c8}
        variant="outlined"
        clickable
        sx={{
          border: "1px solid #eaeaea",
          userSelect: "none",
          mb: 3,
          mt: 3,
          ml: 3,
          mr: 0,
          width: "200px",
          backgroundColor: selectedCategory === "others" && "#efebf5",
        }}
      />
    </Box>,
  ];

  const responsive = {
    1150: { items: 7 },
  };

  return (
    <AliceCarousel
      items={chipList}
      paddingRight={30}
      responsive={responsive}
      disableDotsControls
      disableButtonsControls
      autoWidth
      mouseTracking
    />
  );
};

export default CausesChipRow;
