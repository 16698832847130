import ReduxThunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, applyMiddleware } from "redux";
import { authReducer } from "./reducers/authReducer";
import { associationsReducer } from "./reducers/associationReducer";
import { menuReducer } from "./reducers/menuReducer";
import { userReducer } from "./reducers/userReducer";

import { persistReducer, persistStore } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { makersReducer } from "./reducers/makersReducer";
import { dreamerReducer } from "./reducers/dreamerReducer";
import { eventsReducer } from "./reducers/eventsReducer";
import { setLanguage } from "./actions/userActions";

// Import the action that updates the language

const rootPersistConfig = {
  key: "root",
  storage: localStorage,
  blacklist: ["events", "dreamers"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  associations: associationsReducer,
  sidebar: menuReducer,
  loggedUser: userReducer,
  makers: makersReducer,
  dreamers: dreamerReducer,
  events: eventsReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistedStore = persistStore(store, null, () => {
  // Ensure that loggedUser.language is available before dispatching
  const userLanguage = store.getState().loggedUser?.language?.lang;

  if (userLanguage) {
    // Wrap the dispatch in a setTimeout to ensure it runs in the next tick
    setTimeout(() => {
      // Dispatch an action to update the language state
      store.dispatch(setLanguage(userLanguage));
    }, 0);
  } else {
    store.dispatch(setLanguage("es"));
  }
});

export { store, persistedStore };
