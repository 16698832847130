import {
  Box,
  Button,
  Dialog,
  IconButton,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import registerBg from "../../assets/img/register-morpho.jpg";

import { RegisterForm } from "../registerForm/RegisterForm";
import Colors from "../../utils/Colors";
import { CloseButton } from "react-bootstrap";

const RegisterModal = ({
  //handleOpen,
  open,
  handleOpenLogIn,
  handleOpenPassRecovery,
  handleOpenSignUpSteps,
  handleOpenSignIn,
  handleOpenSignInStep,
  handleOpenSignUp,
  register,
}) => {
  const theme = useTheme();
  const isDown1200 = useMediaQuery("(max-width:1200px)");

  return (
    <Dialog
      open={open}
      onClose={() => handleOpenSignUp(false)}
      fullWidth
      maxWidth="lg"
      PaperComponent={(props) => (
        <Paper {...props} sx={{ borderRadius: "20px" }} />
      )}
      sx={{
        overflow: "scroll",
        background: "transparent",
        borderRadius: "36px !important",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
          p: 2,
        },
        [theme.breakpoints.down("sm")]: {
          p: 0,
        },
      }}
    >
      <IconButton
        onClick={() => handleOpenSignUp(false)}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#000",
        }}
      >
        <CloseButton size={24} />
      </IconButton>
      <Grid
        justifyContent={"center"}
        container
        sx={{
          [theme.breakpoints.up("md")]: {
            height: "800px",
          },
        }}
      >
        <Grid
          item
          lg={7}
          md={6}
          sx={{
            background: `url(${registerBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",

            borderRadius: "20px",
            position: "relative",
          }}
        >
          <Box
            position="absolute"
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              display: isDown1200 && "none",
            }}
          >
            <Typography
              sx={{ color: Colors.secondary, mb: 2 }}
              variant="h5"
              fontWeight={"bold"}
            >
              {register?.hello}
            </Typography>
            <Typography sx={{ color: "#fff", mb: 1 }} variant="body1">
              {register?.registerText}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                handleOpenSignUp(false);
                handleOpenLogIn(true);
              }}
              sx={{
                borderRadius: "8px",
                padding: "10px 15px",
                width: "100%",
                backgroundColor: "#0659fd",
              }}
            >
              {register?.login}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={5}
          sm={8}
          md={6}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            [theme.breakpoints.down("lg")]: {
              padding: "60px 20px",
            },
          }}
        >
          <Grid item xs={10} sm={12} md={10} lg={8}>
            <Typography fontWeight="bold" variant="h5" component="h1">
              {register?.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "rgba(143, 146, 161, 1)" }}
            >
              {register?.text}{" "}
              {isDown1200 && (
                <>
                  <span>o </span>
                  <span
                    onClick={() => {
                      handleOpenSignUp(false);
                      handleOpenLogIn(true);
                    }}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {register?.login}
                  </span>
                </>
              )}
            </Typography>
            <RegisterForm
              handleOpenLogIn={handleOpenLogIn}
              handleOpenSignIn={handleOpenSignIn}
              handleOpenSignInStep={handleOpenSignInStep}
              handleOpenSignUp={handleOpenSignUp}
              handleOpenSignUpSteps={handleOpenSignUpSteps}
              handleOpenPassRecovery={handleOpenPassRecovery}
              register={register}
              // handleOpen={handleOpen}
              // handleOpenLogIn={handleOpenLogIn}
              // handleOpenPassRecovery={handleOpenPassRecovery}
              // handleOpenSignUpSteps={handleOpenSignUpSteps}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RegisterModal;
