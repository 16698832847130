import { Button, List, ListItem, Typography } from "@mui/material";

import faqData from "./mockData.json";

const FaqTopics = ({ setTopic, lastTopic, setLastTopic, faqText }) => {
  const topics = faqData.map((item) => item.topicTranslate);
  const setFromTopics = new Set(topics);
  const topicsArr = Array.from(setFromTopics);
  topicsArr.push("Todos");

  const handleClick = (topic) => {
    if (topic === "Todos") {
      setTopic("all");
      setLastTopic("Todos");
      return;
    }
    const item = faqData.find((item) => item.topicTranslate === topic);
    setTopic(item.topic);
    setLastTopic(item.topicTranslate);
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" mb={4} sx={{ fontWeight: "bold" }}>
        {faqText?.title}
      </Typography>
    </div>
  );
};

export default FaqTopics;
