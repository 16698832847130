import { Drawer, Grid } from "@mui/material";
import Feed from "../../../components/dreamerPage/Feed/Feed";
import RightMenuMakers from "../../../components/RightMenu/RightMenuMakers";
import { Animated } from "react-animated-css";
import { useState } from "react";
import Searchbar from "../../../components/dreamerPage/searchbar/SearchBar";

import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";
import RightNotificationMenu from "../../../components/RightMenu/RightNotificationMenu";
import { useDateValue } from "../../../components/dreamerPage/searchbar/useDateValue";
import { useEffect } from "react";
import { searchQuery } from "../../../store/actions/dreamers";
import { useSelector } from "react-redux";
import SearchResults from "../../../components/dreamerPage/searchResults/SearchResults";

const Social = () => {
  const { general, dreamersPage } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [openRightSidebar, setOpenRightSidebar] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");

  const dateValue = useDateValue(filterType);

  const currentUser = useSelector((state) => state.loggedUser.data);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(null);
    }
  }, [searchTerm]);

  useEffect(() => {
    const searchData = async () => {
      const results = await searchQuery(searchTerm, currentUser.id);
      setSearchResults(results);
    };

    if (searchTerm) {
      searchData();
    }

    return () => setSearchResults(null);
  }, [searchTrigger]);

  const handleChange = (event) => {
    const val = event.target.value;

    setSearchTerm(val);
  };

  return (
    <>
      <Grid
        item
        xl={9}
        lg={8}
        md={12}
        xs={12}
        sx={{ padding: { md: "20px 40px", xs: "20px 25px" } }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setSearchTrigger((prevValue) => !prevValue);
          }}
        >
          <Searchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleChange={handleChange}
            btnAction={() => setOpenRightSidebar(true)}
            filterType={filterType}
            setFilterType={setFilterType}
          />
        </form>

        {searchResults && searchTerm ? (
          <SearchResults searchResults={searchResults} />
        ) : (
          <Feed
            dateValue={dateValue}
            filterType={filterType}
            general={general}
            dreamersPage={dreamersPage}
          />
        )}
      </Grid>
      <Grid item xl={2} lg={3} position="fixed" sx={{ right: 0 }}></Grid>
      <Drawer
        anchor="right"
        open={openRightSidebar}
        onClose={() => setOpenRightSidebar(false)}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "32px",
            borderBottomLeftRadius: "32px",
            width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
          },
        }}
      >
        {openNotificationsMod ? (
          <RightNotificationMenu
            userPhoto={noImagePlaceholder}
            openPopupMenu={openPopupMenu}
            setNotificationsMod={setNotificationsMod}
            setOpenPopupMenu={setOpenPopupMenu}
          />
        ) : (
          <RightMenuMakers
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
            setNotificationsMod={setNotificationsMod}
            theme={{
              bgColor: "#0659fd",
              fontColor: "white",
            }}
          />
        )}
      </Drawer>

      <Grid
        item
        xl={2}
        lg={3}
        position="fixed"
        sx={{ right: 0, width: "100%" }}
        display={{ xs: "none", lg: "block" }}
      >
        {openNotificationsMod && (
          <Animated
            animationIn="fadeInRight"
            animationOut="fadeOutLeft"
            isVisible={true}
            animationInDuration={500}
            animationOutDuration={500}
            //animateOnMount={false}
          >
            <RightNotificationMenu
              userPhoto={noImagePlaceholder}
              openPopupMenu={openPopupMenu}
              setNotificationsMod={setNotificationsMod}
              setOpenPopupMenu={setOpenPopupMenu}
            />
          </Animated>
        )}

        {!openNotificationsMod && (
          <Animated
            animationIn="fadeInRight"
            animationOut="fadeOutLeft"
            isVisible={true}
            animationInDuration={500}
            animationOutDuration={500}
            //animateOnMount={false}
          >
            <RightMenuMakers
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
            />
          </Animated>
        )}
      </Grid>
    </>
  );
};

export default Social;
