import React from "react";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";

const DreamTimer = ({ timeLeft, isExpired, transformDream, loadingTime }) => {
  if (loadingTime) {
    return (
      <Stack sx={{ position: "relative" }} direction={"column"}>
        <Typography variant="body1">{transformDream?.timeAvailable}</Typography>

        <Box
          sx={{
            width: "auto",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography variant="body1">{transformDream?.timeAvailable}</Typography>
      <Stack direction="row" gap={3}>
        {isExpired ? (
          <Typography variant="body1">Expirado</Typography>
        ) : (
          <>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.days).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.days}</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.hours).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.hours}</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.minutes).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.minutes}</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight="bold" fontSize="24px">
                {String(timeLeft.seconds).padStart(2, "0")}
              </Typography>
              <Typography>{transformDream?.seconds}</Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default DreamTimer;
