import React from "react";
import AboutUs from "../components/aboutUsPage/aboutUs/AboutUs";
import styles from "../assets/scss/landing.module.scss";
import buttonStyles from "../assets/scss/buttons.module.scss";

import { Container } from "react-bootstrap";

const AboutUsPage = () => {
  return (
    <Container fluid className="landingWrapper">
      <AboutUs />
    </Container>
  );
};

export default AboutUsPage;
