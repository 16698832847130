import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { useSelector } from "react-redux";

const UserRedirect = () => {
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.loggedUser.isLoadingUserData);
  const userData = useSelector((state) => state.loggedUser.data);
  const userSelection = useSelector((state) => state.auth.userSelection);

  useEffect(() => {
    const defineUserType = () => {
      if (!isLoading) {
        if (userSelection === "maker") {
          navigate("/home/maker");
        } else if (userSelection === "dreamer") {
          navigate("/home/dreamer");
        } else {
          if (userData.isMaker) {
            navigate("/home/maker");
          } else {
            navigate("/home/dreamer");
          }
        }
      }
    };

    if (userData) defineUserType();
  }, [isLoading, userData]);

  return <>{!isLoading ? <Loading /> : null}</>;
};

export default UserRedirect;
