import Grid from "@mui/material/Grid";

import { useNavigate } from "react-router-dom";

import { FaFacebookF } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";

import { useFormik } from "formik";
import { logInSchema } from "../../schema/logInForm/logInForm";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initWithGoogle, login, setAuthError } from "../../store/actions/auth";
import authErrors from "../../firebase/firebaseAuthError";

export const LogInForm = ({
  handleOpen,
  handleOpenPassRecovery,
  handleOpenSingInStep,
}) => {
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.auth.errorCode);

  const [showPassword, setShowPassword] = useState(false);
  const [isDoingGoogleLogin, setIsDoingGoogleLogin] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const doAfterLogIn = () => {
    handleOpenSingInStep(true);
    handleOpen(false);
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: logInSchema,

    onSubmit: (values) => {
      dispatch(setAuthError("no-error"));
      const doAfterLogIn = () => {
        handleOpenSingInStep(true);
        handleOpen(false);
      };

      dispatch(login(values.email, values.password, doAfterLogIn));
    },
  });

  useEffect(() => {
    const checkForErrors = () => {
      if (authErrors[errorCode] !== "no-error") {
        const msg = authErrors[errorCode].msg;
        const errorType = authErrors[errorCode].type;

        if (authErrors[errorCode] === "auth/user-not-found") {
          setFieldTouched(errorType, true);
        }

        setFieldError(errorType, msg);
      }
    };

    checkForErrors();
  }, [errorCode]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container  mt={3}>
        <Grid item xs={12} lg={12}>
          <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
            <label
              htmlFor="outlined-adornment-email"
              style={{ fontWeight: "bold" }}
            >
              EMAIL
            </label>
            <OutlinedInput
              id="outlined-adornment-email"
              name="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              type={"text"}
              endAdornment={
                <InputAdornment position="end" sx={{ color: "#53D769" }}>
                  {true ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
                </InputAdornment>
              }
            />

            <FormHelperText error id="outlined-adornment-email">
              {!isDoingGoogleLogin && touched.email && errors.email
                ? errors.email
                : null}
            </FormHelperText>
            <FormHelperText error id="outlined-adornment-email">
              {isDoingGoogleLogin && errors.email ? errors.email : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12}>
          <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
            <label
              htmlFor="outlined-adornment-password"
              style={{ fontWeight: "bold" }}
            >
              PASSWORD
            </label>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              value={values.password}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error id="outlined-adornment-password">
              {touched.password && errors.password ? errors.password : " "}
            </FormHelperText>
          </FormControl>

          <Button
            variant="text"
            sx={{ marginTop: "10px", color: "#8F92A1" }}
            onClick={() => {
              handleOpen(false);
              handleOpenPassRecovery(true);
            }}
          >
            {login?.forgotPassword}
          </Button>

          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              backgroundColor: "#0659fd",
              borderRadius: "8px",
              padding: "10px 15px",
            }}
          >
            Login
          </Button>
          <p className="text-center my-4" style={{ color: "#8F92A1" }}>
            {login?.socials}
          </p>

          <Button
            variant="outlined"
            onClick={() => {
              setIsDoingGoogleLogin(true);
              dispatch(initWithGoogle(doAfterLogIn, true));
            }}
            sx={{
              borderRadius: "8px",
              padding: "10px 15px",
              width: "100%",
              mb: 2,
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <FaGoogle />
            </span>{" "}
            Connect with Google
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
