import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Box, List } from "@mui/material";
import { getFilterType } from "../../../store/actions/dreamers";
import { useDispatch, useSelector } from "react-redux";

const SearchFilters = ({ filterType, setFilterType }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.loggedUser.language);

  const { lang } = language;

  const translations = {
    en: {
      all: "All",
      lastHour: "Last Hour",
      today: "Today",
      thisWeek: "This Week",
      thisMonth: "This Month",
      thisYear: "This Year",
    },
    es: {
      all: "Todos",
      lastHour: "Última hora",
      today: "Hoy",
      thisWeek: "Esta semana",
      thisMonth: "Este mes",
      thisYear: "Este año",
    },
  };

  const t = translations[lang]; // Dynamically select the translation set

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: "12px",
      }}
    >
      <List dense>
        <ListItem sx={{ color: filterType === "all" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("all")}>
            <ListItemText primary={t.all} />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "lastHour" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("lastHour")}>
            <ListItemText primary={t.lastHour} />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "today" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("today")}>
            <ListItemText primary={t.today} />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "thisWeek" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("thisWeek")}>
            <ListItemText primary={t.thisWeek} />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "thisMonth" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("thisMonth")}>
            <ListItemText primary={t.thisMonth} />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ color: filterType === "thisYear" ? "#a73ef5" : "" }}>
          <ListItemButton onClick={() => setFilterType("thisYear")}>
            <ListItemText primary={t.thisYear} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default SearchFilters;
