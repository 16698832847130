import Swal from "sweetalert2";

export const callSuccessAlert = (msg) => {
  Swal.fire({
    title: msg,
    icon: "success",
    confirmButtonText: "Aceptar",
  });
};

/**
 * .then((result) => {
    if (result.isConfirmed) {
      setOpen(false);
    }
  });
 */
