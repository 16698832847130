import { Box } from "@mui/material";

import { BiPlus } from "react-icons/bi";

const AddStory = () => {
  const handleDragStart = (e) => e.preventDefault();

  return (
    <Box
      borderRadius="14px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="65px"
      height="65px"
      onDragStart={handleDragStart}
    >
      <Box
        padding="10px"
        borderRadius="12px"
        width="45px"
        height="45px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "rgba(121, 208, 241, 0.20)" }}
      >
        <BiPlus color="#79D0F1" size="22" />
      </Box>
    </Box>
  );
};

export default AddStory;
