const useFilterCategDreams = (categoryToFilter, dreams) => {
  let filteredDreams;

  switch (categoryToFilter) {
    case "all":
      return dreams;

    case "enviroment":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "enviroment"
        );

        return existCategory;
      });

      return filteredDreams;

    case "shelter":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "shelter"
        );

        return existCategory;
      });

      return filteredDreams;

    case "arts":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "arts"
        );

        return existCategory;
      });

      return filteredDreams;

    case "sports":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "sports"
        );

        return existCategory;
      });

      return filteredDreams;

    case "education":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "education"
        );

        return existCategory;
      });

      return filteredDreams;

    case "health":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "health"
        );

        return existCategory;
      });

      return filteredDreams;

    case "transport":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "transport"
        );

        return existCategory;
      });

      return filteredDreams;

    case "others":
      filteredDreams = dreams.filter((dream) => {
        const categories = dream.categories;
        const existCategory = categories.some(
          (category) => category === "others"
        );

        return existCategory;
      });

      return filteredDreams;

    default:
      return dreams;
  }
};

export default useFilterCategDreams;
