import { Modal } from "@mui/material";
import DreamerFriends from "../dreamerFriends/DreamerFriends";

const FriendsModal = ({ open, setOpen, friends }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        className="d-flex align-items-center bg-white"
        style={{
          position: "absolute",
          //width: isDown1200 ? "50%" : "85%",
          maxWidth: 1200,
          minWidth: 325,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "20px",
        }}
      >
        <DreamerFriends friends={friends} />
      </div>
    </Modal>
  );
};

export default FriendsModal;
