import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import faqData from "./mockData.json";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const Faq = ({ currentTopic, faq }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const questionsToDisplay = faqData.filter((item) => {
  //   if (currentTopic === "all") {
  //     return true;
  //   } else {
  //     return currentTopic === item.topic;
  //   }
  // });
  const questionsToDisplay = faq;
  const CustomAccordion = styled((props) => (
    <Accordion elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },

    ".custom-accordion": {
      transition: "height 0.3s ease-in-out, opacity 0.3s ease-in-out",
      "&.Mui-expanded": {
        height: "auto", // Expand to auto height
        opacity: 1, // Show content
      },
      "&.Mui-collapsed": {
        height: 0, // Collapse to zero height
        opacity: 0, // Hide content
      },
    },
  }));

  return (
    <Box>
      {questionsToDisplay.map((element, index) => {
        const panelId = `panel${index + 1}`;
        return (
          <CustomAccordion
            className="custom-accordion"
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
            style={{
              borderRadius: "14px",
              marginBottom: 15,
              border: "none",
              boxShadow: "none",

              //backgroundColor: "#f7f7f7",
            }}
            key={panelId}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              key={element.id}
              sx={{ border: "none" }}
            >
              <Typography
                sx={{
                  flexShrink: 0,
                  maxWidth: "70%",
                  fontSize: 16,
                  color: theme.palette.primary.main,
                }}
                variant="h6"
              >
                {element.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{element.answer}</Typography>
            </AccordionDetails>
          </CustomAccordion>
        );
      })}
    </Box>
  );
};

export default Faq;
