import { Grid } from "@mui/material";
import DreamerCard from "../dreamersCard/DreamerCard";

const DreamGrid = ({ dreams }) => {
  return (
    <Grid container rowGap={3} mt="20px" spacing={3}>
      {dreams?.map((dream) => {
        return (
          <Grid key={dream.id} item lg={4} md={6} xs={12}>
            <DreamerCard dream={dream} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DreamGrid;
