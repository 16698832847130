import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";
import { FiChevronRight } from "react-icons/fi";
import VisualizerB from "../../visualizer/VisualizerB";

const MyPhotos = ({ images, language }) => {
  const [openVisualizer, setOpenVisualizer] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleOpenVisualizer = (index) => {
    setSelectedImageIndex(index);
    setOpenVisualizer(true);
  };

  const handleCloseVisualizer = () => {
    setOpenVisualizer(false);
  };

  return (
    <Stack
      gap={3}
      bgcolor="background.paper"
      borderRadius="12px"
      padding="30px"
      sx={{ minHeight: "500px", maxHeight: "500px" }}
    >
      <Typography variant="h6" fontWeight="bold">
        {language?.photos || "Fotos"}
      </Typography>
      {images.length > 0 ? (
        <>
          <Grid
            container
            sx={{
              flexGrow: 1,
            }}
          >
            {images.slice(0, 9).map((item, index) => (
              <Grid
                item
                xs={6} // 2 columns on mobile
                sm={4} // 3 columns on tablets
                md={4} // 4 columns on larger screens
                key={item.id}
                sx={{
                  position: "relative",
                  padding: "5px",
                  overflow: "hidden",
                }}
              >
                <Box
                  onClick={() => handleOpenVisualizer(index)}
                  sx={{
                    width: "100%",
                    height: "120px",
                    maxHeight: "120px",
                    aspectRatio: "4/3",
                    position: "relative",
                    borderRadius: "12px",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={item.imgURL || noImagePlaceholder}
                    alt={item.description || "User-uploaded image"}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>

          {images.length > 9 && (
            <Box display="flex" justifyContent="center" mt="auto">
              <Button
                variant="outlined"
                sx={{ borderRadius: "4px", width: "150px" }}
                onClick={() => handleOpenVisualizer(0)}
                endIcon={<FiChevronRight />}
              >
                {language?.viewMore || "Ver más"}
              </Button>
            </Box>
          )}
          {openVisualizer && (
            <VisualizerB
              slides={images}
              openVisualizer={openVisualizer}
              handleClose={handleCloseVisualizer}
              post={{
                name: "Photo Gallery",
                userOwnerId: "123",
              }}
            />
          )}
        </>
      ) : (
        <Grid
          container
          spacing={2}
          sx={{ flexGrow: 1 }}
          display="flex"
          alignItems="center"
          justifyContent="center "
          height={"100%"}
        >
          <Typography variant="h5" component="h3">
            {language?.noPhotosAvailable || "No hay fotos disponibles"}
          </Typography>
        </Grid>
      )}
    </Stack>
  );
};

export default MyPhotos;
