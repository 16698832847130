import { types } from "../types/types";
import { addMakers } from "../utils/dreamer";

const initialState = {
  dreams: [],
  dreamers: [],
  lastDreamsBatchSize: 100,
  makersSupportDreams: [],
  posts: [],
  followers: [],
  filterType: "all",
  justCreatedPostId: 0,
  isCreatingImages: false,
  isLoadingDreams: false,
  isLoadingFollowers: false,
};

export const dreamerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getAllDreams:
      const dreamsToCheck = action.payload.dreams;
      const newDreams = dreamsToCheck.filter(
        (newDream) =>
          !state.dreams.some(
            (existingDream) => existingDream.id === newDream.id
          )
      );
      return {
        ...state,
        dreams: state.dreams.concat(newDreams),
      };

    case types.getDreamers:
      return {
        ...state,
        dreamers: state.dreamers.concat(action.payload.dreamers),
      };

    case types.getDreamsBatchSize:
      return {
        ...state,
        lastDreamsBatchSize: action.payload.batchSize,
      };

    case types.getPosts:
      return {
        ...state,
        posts: state.posts.concat(action.payload.posts),
      };

    case types.isCreatingImages:
      return {
        ...state,
        isCreatingImages: action.payload.state,
      };

    case types.getJustCreatedPost:
      return {
        ...state,
        justCreatedPostId: action.payload.id,
      };

    case types.isLoadingDreams:
      return {
        ...state,
        isLoadingDreams: action.payload.isLoading,
      };

    case types.getFilterType:
      return {
        ...state,
        filterType: action.payload.type,
      };

    case types.getFollowers:
      return {
        ...state,
        followers: action.payload.followers,
      };

    case types.isLoadingFollowers:
      return {
        ...state,
        isLoadingFollowers: action.payload.isLoading,
      };

    case types.getMakersByDream:
      return {
        ...state,
        makersSupportDreams: addMakers(
          state.makersSupportDreams,
          action.payload.makers
        ),
      };

    default:
      return state;
  }
};
