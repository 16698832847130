import { Box, CircularProgress, Drawer, Grid, Stack } from "@mui/material";
import MyPhotos from "../../../components/dreamerPage/myPhotos/MyPhotos";
import MyVideos from "../../../components/dreamerPage/myVideos/MyVideos";
import PhotoCollectionPostCard from "../../../components/dreamerPage/photoCollectionPost/PhotoCollectionPostCard";
import Searchbar from "../../../components/dreamerPage/searchbar/SearchBar";
import VideoPostCard from "../../../components/dreamerPage/videoPost/VideoPostCard";

import RightMenuAccount from "../../../components/RightMenu/RightMenuAccount";
import {
  getOneUserPosts,
  getUserImages,
  getUserVideos,
} from "../../../store/actions/dreamers";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Animated } from "react-animated-css";
import RightNotificationMenu from "../../../components/RightMenu/RightNotificationMenu";

const Account = () => {
  const BATCH_SIZE = 5;

  const currentUser = useSelector((state) => state.loggedUser.data);
  const { dreamersPage } = useSelector((state) => state.loggedUser.language);

  const [userPosts, setUserPosts] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [postNumber, setPostNumber] = useState(100);
  const [userImages, setUserImages] = useState([]);
  const [userVideoSource, setUserVideoSource] = useState([]);

  const [openNotificationsMod, setNotificationsMod] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [openRightSidebar, setOpenRightSidebar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [searchResults, setSearchResults] = useState(null);

  const getUserPosts = async () => {
    const postList = await getOneUserPosts(
      lastPost,
      BATCH_SIZE,
      currentUser.id
    );
    setUserPosts((prevPosts) => [...prevPosts, ...postList]);
    setPostNumber(postList.length);
    setLastPost(postList[postList.length - 1]);

    let images = [];
    postList.forEach((post) => {
      if (post.type === "image") {
        images = images.concat(post.postImages);
      }
    });

    let videos = [];
    postList.forEach((post) => {
      if (post.type === "video") {
        videos = videos.concat(post.videoURL);
      }
    });
  };

  useEffect(() => {
    const getData = async () => {
      const images = await getUserImages(currentUser.id);
      const videos = await getUserVideos(currentUser.id);
      setUserImages(images);
      setUserVideoSource(videos);
    };

    getData();
  }, [currentUser]);

  useEffect(() => {
    if (BATCH_SIZE <= postNumber) {
      getUserPosts();
    }
  }, []);

  const handleChange = (event) => {
    const val = event.target.value;

    setSearchTerm(val);
  };

  return (
    <>
      <Grid
        item
        minHeight="100vh"
        xl={9}
        lg={8}
        md={12}
        xs={12}
        sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
      >
        <Box mb="40px">
          <Searchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filterType={filterType}
            setFilterType={setFilterType}
            handleChange={handleChange}
            btnAction={() => setOpenRightSidebar((prevValue) => !prevValue)}
          />
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <MyPhotos images={userImages} language={dreamersPage} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MyVideos videos={userVideoSource} language={dreamersPage} />
          </Grid>
        </Grid>

        <InfiniteScroll
          style={{ scrollbarWidth: "none" }}
          scrollableTarget="scrollableBox"
          dataLength={userPosts.length} //This is important field to render the next data
          next={getUserPosts}
          hasMore={postNumber >= BATCH_SIZE}
          loader={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="100px"
            >
              <CircularProgress />
            </Stack>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "200px" }}>
              <b>
                {dreamersPage?.reachEnd || "Parece que has llegado al final"}
              </b>
            </p>
          }
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 800: 1, 900: 2 }}
            style={{ width: "100%" }}
          >
            <Masonry
              gutter="30px"
              style={{
                width: "100%",
              }}
            >
              {userPosts.map((post) => {
                // <ArticlePostCard />;
                return post.type === "image" ? (
                  // <Grid item lg={6}>
                  <PhotoCollectionPostCard
                    likesNumber={325}
                    data={post}
                    key={post.id}
                  />
                ) : (
                  // </Grid>
                  // <Grid item lg={6}>
                  <VideoPostCard likesNumber={325} data={post} key={post.id} />
                  // </Grid>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      </Grid>
      <Grid item xl={2} lg={3} position="fixed" sx={{ right: 0 }}>
        <Drawer
          anchor="right"
          open={openRightSidebar}
          onClose={() => setOpenRightSidebar(false)}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "32px",
              borderBottomLeftRadius: "32px",
              width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
            },
          }}
        >
          {openNotificationsMod ? (
            <RightNotificationMenu
              setOpenRightSidebar={setOpenRightSidebar}
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setNotificationsMod={setNotificationsMod}
            />
          ) : (
            <RightMenuAccount
              setNotificationsMod={setNotificationsMod}
              openPopupMenu={openPopupMenu}
              setOpenPopupMenu={setOpenPopupMenu}
              setOpenRightSidebar={setOpenRightSidebar}
            />
          )}
        </Drawer>
        <Grid
          item
          xl={2}
          lg={3}
          position="fixed"
          sx={{ right: 0, width: "100%" }}
          display={{ xs: "none", lg: "block" }}
        >
          {openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightNotificationMenu
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
                setNotificationsMod={setNotificationsMod}
              />
            </Animated>
          )}

          {!openNotificationsMod && (
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              isVisible={true}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <RightMenuAccount
                setNotificationsMod={setNotificationsMod}
                openPopupMenu={openPopupMenu}
                setOpenPopupMenu={setOpenPopupMenu}
              />
            </Animated>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
