import { BiHappyAlt, BiSend } from "react-icons/bi";
import { Box, InputAdornment } from "@mui/material";
import Input from "@mui/material/Input";
import Picker from "@emoji-mart/react";
import { useState } from "react";
import { useSelector } from "react-redux";

const CommentsInput = ({
  handleSubmit,
  getFieldProps,
  outlined,
  setFieldValue,
  values,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { comments } = useSelector((state) => state.loggedUser.language);

  const onFormSubmit = (event) => {
    event.preventDefault();

    handleSubmit(values.comment); // Pass comment data to handleSubmit directly
  };

  return (
    <form style={{ position: "relative" }} onSubmit={onFormSubmit}>
      <Input
        {...getFieldProps("comment")}
        disableUnderline
        id="outlined-adornment-comment"
        fullWidth
        type="text"
        placeholder={comments?.reply}
        sx={{
          width: "100%",
          padding: "7px",
          backgroundColor: outlined ? "#fff" : "#f7f7f7",
          border: outlined ? "2px solid rgba(143, 146, 161, 0.2)" : "none",
        }}
        endAdornment={
          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            <BiHappyAlt
              color="8F92A1"
              size={24} // Adjust size here for larger icon
              onClick={() => setShowEmojiPicker((prevValue) => !prevValue)}
              style={{ marginRight: "8px" }} // Add spacing between icons
            />
            <span onClick={onFormSubmit}>
              <BiSend color="#0659FD" size={24} /> {/* Adjust size here */}
            </span>
          </InputAdornment>
        }
      />

      <Box
        position="absolute"
        right={0}
        zIndex={100}
        display={showEmojiPicker ? "block" : "none"}
      >
        <Picker
          locale="es"
          perLine={7}
          onEmojiSelect={(data) =>
            setFieldValue("comment", values.comment + data.native)
          }
        />
      </Box>
    </form>
  );
};

export default CommentsInput;
