export const responsiveBreakpoints = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 360 },
    items: 1,
  },
  little: {
    breakpoint: { max: 360, min: 0 },
    items: 1,
  },
};
