import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AliceCarousel from "react-alice-carousel";

import { BiChevronRight } from "react-icons/bi";

import "./userFollowedCarousel.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanFollows,
  getUserFollows,
} from "../../../store/actions/userActions";
import Story from "../../story/Story";
import AddStory from "../../addStory/AddStory";

const UserFollowedCarousel = ({ dreamersPage, general }) => {
  const dispatch = useDispatch();

  const followedUsers = useSelector((state) => state.loggedUser.followedUsers);
  const currentUser = useSelector((state) => state.loggedUser.data);

  const isDown700 = useMediaQuery("(max-width:700px)");

  const [items, setItems] = useState([]);

  useEffect(() => {
    const getFollowers = () => {
      dispatch(getUserFollows(currentUser.id, null));
    };

    getFollowers();

    return () => dispatch(cleanFollows());
  }, []);

  if (!followedUsers) {
    <CircularProgress />;
  }

  useEffect(() => {
    const setMakers = () => {
      const items = followedUsers.map((user) => {
        return (
          <Box width="100px">
            <Story user={user} />
          </Box>
        );
      });

      items.unshift(
        <Box width="100px">
          <AddStory />
        </Box>
      );
      setItems(items);
    };

    if (followedUsers?.length) {
      setMakers();
    }
  }, [followedUsers]);

  const responsive = {
    300: { items: 3 },
    400: { items: 4 },
    550: { items: 5 },
    800: { items: 6 },
    1200: { items: 5 },
    1450: { items: 9 },
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        marginTop: "40px",
        padding: { md: "40px", xs: "20px" },
        borderRadius: "12px",
      }}
    >
      <Stack
        direction={isDown700 ? "column" : "row"}
        gap={2}
        marginBottom="40px"
        justifyContent="space-between"
        alignItems={isDown700 ? "center" : ""}
      >
        <Typography variant="h4" fontWeight="bold">
          {dreamersPage?.followedUsers}
        </Typography>
        {/* <Button
          variant="contained"
          disableElevation
          sx={{
            backgroundColor: "#f6f6f7",
            display: { md: "inline", xs: "none" },
            borderRadius: "4px",
            color: "#000",
            fontWeight: "bold",
          }}
        >
          Ver más <BiChevronRight />
        </Button> */}
      </Stack>

      {items.length ? (
        <AliceCarousel
          items={items}
          autoWidth
          disableDotsControls
          disableButtonsControls
          mouseTracking
          responsive={responsive}
        />
      ) : (
        <Typography variant="body1">
          {" "}
          {dreamersPage?.notYetFollowing}
        </Typography>
      )}

      {/* <Button
        variant="contained"
        disableElevation
        sx={{
          backgroundColor: "#f6f6f7",
          borderRadius: "4px",
          color: "#000",
          fontWeight: "bold",
          display: { md: "none", xs: "block" },
          margin: "0 auto",
          marginTop: "30px",
        }}
      >
        {general?.viewMore} <BiChevronRight />
      </Button> */}
    </Box>
  );
};

export default UserFollowedCarousel;
