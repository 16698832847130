import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Support from "../makerPage/support/Support";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions/auth";

const CloseSessionModal = ({ open, handleOpen }) => {
  const dispatch = useDispatch();

  const endSession = () => {
    handleOpen(false);
    dispatch(logoutUser());
  };

  return (
    <div>
      <Dialog open={open} onClose={() => {}} maxWidth="md">
        <DialogTitle>
          <Typography variant="h4">
            ¡Oh no! Tu cuenta ha sido inhabilitada temporalmente
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lamentamos informarte que tu cuenta ha sido inhabilitada
            temporalmente. Entendemos que esto puede ser frustrante, y estamos
            aquí para ayudarte a resolver este problema.
          </DialogContentText>
          <DialogContentText my={2}>
            Por favor, comunícate con nuestro equipo de soporte a través del
            siguiente formulario de contacto:
          </DialogContentText>
          <Support />
        </DialogContent>
        <DialogActions>
          <Button onClick={endSession}>Aceptar y enviar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CloseSessionModal;
