import { Button, Card, Stack, Typography } from "@mui/material";

import { FiCheck } from "react-icons/fi";

import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";

const DreamerCard = ({ dreamerData }) => {
  return (
    <Card variant="outlined" sx={{ padding: "30px", border: "none" }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <span style={{ width: "40px", height: "40px" }}>
            <img
              src={noImagePlaceholder}
              alt=""
              width="100%"
              height="100%"
              style={{ borderRadius: "12px" }}
            />
          </span>
          <Stack>
            <Typography variant="body1" fontWeight="bold">
              {dreamerData?.name}
            </Typography>
            <Typography variant="body2">{dreamerData?.email}</Typography>
          </Stack>
        </Stack>

        <Button
          variant="contained"
          disableElevation
          sx={{
            backgroundColor: "#0659fd",
            borderRadius: "4px",
            color: "#fff",
            fontWeight: "bold",
            marginLeft: "auto",
          }}
        >
          <FiCheck size={22} />
          Agregar
        </Button>
      </Stack>
    </Card>
  );
};

export default DreamerCard;
