import * as Yup from "yup";

export const logInSchema = Yup.object({
  email: Yup.string()
    .email("Email no valido")
    .typeError("Email no valido")
    .required("Email es requerido"),
  password: Yup.string()
    .min(6, "Contraseña debe contener al menos 6 caracteres")
    .required("Contraseña requerida"),
});
