import { Animated } from "react-animated-css";
import PopupProfileConfig from "../popupProfileConfig/PopupProfileConfig";

import { Badge, Box, IconButton, Stack } from "@mui/material";
import noImagePlaceholder from "../../../assets/img/avatar-no-image.png";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";

const TopUserActions = ({
  selectedIcon,
  setNotificationsMod,
  setOpenPopupMenu,
  openPopupMenu,
  setOpenRightSidebar,
  general,
}) => {
  const userData = useSelector((state) => state.loggedUser.data);
  return (
    <Stack direction="row" gap={2} alignItems="center" width="100%">
      <Box
        width="30px"
        component="span"
        onClick={() =>
          setOpenRightSidebar ? setOpenRightSidebar(false) : null
        }
        //onClick={() => dispatch(toggleLeftMenu())}
        display={{ xs: "block", lg: "none" }}
        sx={{ color: { xs: "#f7f7f7", md: "#fff" } }}
      >
        <CloseIcon />
      </Box>
      <IconButton
        onClick={() => (setNotificationsMod ? setNotificationsMod(true) : null)}
        sx={{
          backgroundColor: "#79d0f1",
          width: "48px", // Adjust IconButton size
          height: "48px",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          color: "white",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#67bfe4", // Slightly darker shade for hover
          },
        }}
      >
        <Badge
          badgeContent={
            userData.unreadNotificationsNum > 0
              ? userData.unreadNotificationsNum
              : 0
          }
          color="error"
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <NotificationsIcon sx={{ fontSize: "32px" }} />{" "}
          {/* Set icon size here */}
        </Badge>
      </IconButton>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: "48px",
          height: "48px",
          borderRadius: "12px",
          position: "relative",
        }}
      >
        {selectedIcon ? (
          <>{selectedIcon}</>
        ) : (
          // <img
          //   src={icon}
          //   onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
          //   alt=""
          //   style={{
          //     objectFit: "contain",
          //     borderRadius: "12px",
          //     width: "100%",
          //     height: "100%",
          //     cursor: "pointer",
          //   }}
          // />
          <img
            src={userData?.imgURL || noImagePlaceholder}
            onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          />
        )}
        <Animated
          animationIn="fadeInDown"
          animationOut="fadeOutUp"
          isVisible={openPopupMenu || false}
          animationInDuration={500}
          animationOutDuration={500}
          animateOnMount={false}
          style={{ position: "relative", zIndex: "100" }}
        >
          <PopupProfileConfig />
        </Animated>
      </Stack>
    </Stack>
  );
};

export default TopUserActions;
