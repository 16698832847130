import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";

const CustomTab = styled(Tab)(({ theme }) => ({
  minWidth: "auto", // Adjust the tab width to your liking
  padding: 0,
  color: "#8F92A1",
  margin: theme.spacing(0, 2), // Adjust the space between tabs
  "&.Mui-selected": {
    background: "transparent", // Remove the default background color
    color: "#000",
    "&::before": {
      content: '""',
      width: "5px", // Adjust the dot size
      height: "5px",
      borderRadius: "50%", // Make it a circle
      backgroundColor: "#000", // Change the dot color
      position: "absolute",
      bottom: "0",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
}));

export default CustomTab;
