import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";

const ConfigMyAccount = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  general,
}) => {
  return (
    <>
      <Typography variant="h4" mb={4}>
        {general?.myAccount}
      </Typography>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}> {general?.language}</label>
            <Select
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.language}
              name="language"
            >
              <MenuItem value={"es"}>{general?.spanish}</MenuItem>
              <MenuItem value={"en"}>{general?.english}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}> {general?.currency}</label>
            <Select
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currency}
              name="currency"
            >
              <MenuItem value={"CRC"}>{general?.CRC}</MenuItem>
              <MenuItem value={"USD"}>{general?.USD}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={2}>
        <Grid item sm={4} xs={12}>
          <FormControl>
            <label style={{ fontWeight: "bold" }}>{general?.showEmail}</label>

            <RadioGroup
              //defaultValue={true}
              name="showEmail"
              onChange={handleChange}
              value={values.showEmail}
            >
              <FormControlLabel
                value={"Si"}
                control={<Radio />}
                label={general?.yes}
              />
              <FormControlLabel
                value={"No"}
                control={<Radio />}
                label={general?.no}
              />
            </RadioGroup>

            <FormHelperText error id="outlined-showEmail">
              {touched.showEmail && errors.showEmail ? errors.showEmail : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl>
            <label style={{ fontWeight: "bold" }}>{general?.showProfile}</label>
            <RadioGroup
              //defaultValue={true}
              name="showProfiles"
              onChange={handleChange}
              value={values.showProfiles}
            >
              <FormControlLabel
                value={"Si"}
                control={<Radio />}
                label={general?.yes}
              />
              <FormControlLabel
                value={"No"}
                control={<Radio />}
                label={general?.no}
              />
            </RadioGroup>

            <FormHelperText error id="outlined.showProfiles">
              {touched.showProfiles && errors.showProfiles
                ? errors.showProfiles
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl>
            <label style={{ fontWeight: "bold" }}>
              {general.showSocialProfile}
            </label>

            <RadioGroup
              //defaultValue={true}
              name="showSocialProfiles"
              onChange={handleChange}
              value={values.showSocialProfiles}
            >
              <FormControlLabel
                value={"Si"}
                control={<Radio />}
                label={general?.yes}
              />
              <FormControlLabel
                value={"No"}
                control={<Radio />}
                label={general?.no}
              />
            </RadioGroup>

            <FormHelperText error id="outlined-showSocialProfiles">
              {touched.showSocialProfiles && errors.showSocialProfiles
                ? errors.showSocialProfiles
                : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigMyAccount;
