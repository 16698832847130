import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { FiArrowLeft } from "react-icons/fi";
import { useParams } from "react-router-dom";

import placeHolder from "../../../assets/img/face-placeholder.jpg";

import {
  FaWhatsapp,
  FaInstagram,
  FaFacebook,
  FaTelegram,
  FaCopy,
} from "react-icons/fa";
import { useEffect, useMemo, useState, useCallback } from "react";
import TabPanel from "../tabPanel/TabPanel";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOneMaker } from "../../../store/actions/makers";
import DreamTimer from "../dreamTimer/DreamTimer";

import Hero from "../hero/Hero";
import { getDream, getDreamMakers } from "../../../store/actions/dreamers";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";

import ConfirmHelp from "../confirmDream/ConfirmHelp";

const DreamDetails = () => {
  const navigate = useNavigate();
  const { dreamId, userId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const currentUser = useSelector((state) => state.loggedUser.data);
  const { general, transformDream } = useSelector(
    (state) => state.loggedUser.language
  );
  const dreams = useSelector((state) => state.dreamers.dreams);
  const makersSupportDreams = useSelector(
    (state) => state.dreamers.makersSupportDreams
  );

  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown580 = useMediaQuery("(max-width:580px)");

  const [value, setValue] = useState(0);
  const [currentDream, setCurrentDream] = useState(null);
  const [similarDreams, setSimilardreams] = useState([]);
  const [makersData, setMakersData] = useState([]);
  const [makersSupportCurrentDream, setMakersSupportCurrentDream] = useState(
    []
  );
  const [dreamMakers, setDreamMakers] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shareUrl, setShareUrl] = useState("");
  const [shareTitle, setShareTitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [loadingTime, setLoadingTime] = useState(true);

  const calculateTimeLeft = useMemo(() => {
    if (!currentDream?.availableTime || !currentDream?.startDate?.seconds) {
      setLoadingTime(false);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const currentTimestamp = Date.now(); // Get current time in milliseconds
    const startTimestamp = currentDream.startDate.seconds * 1000; // Convert Firebase timestamp to milliseconds
    const startDateObj = new Date(startTimestamp); // Create Date object from Firebase timestamp

    const targetTimestamp =
      startTimestamp + currentDream.availableTime * 30 * 24 * 60 * 60 * 1000; // Add months in milliseconds
    const targetDateObj = new Date(targetTimestamp); // Convert targetTimestamp to Date object

    const timeDifference = targetTimestamp - currentTimestamp;

    // Console logs for debugging
    // console.log(
    //   "🚀 Current Time:",
    //   new Date(currentTimestamp).toISOString(),
    //   `(Timestamp: ${currentTimestamp})`
    // );
    // console.log(
    //   "📌 Firebase startDate:",
    //   startDateObj.toISOString(),
    //   `(Timestamp: ${startTimestamp})`
    // );
    // console.log(
    //   "🎯 Target Date:",
    //   targetDateObj.toISOString(),
    //   `(Timestamp: ${targetTimestamp})`
    // );
    // console.log("⏳ Time Difference:", timeDifference, "ms");

    if (timeDifference <= 0) {
      setLoadingTime(false);
      console.log("🔥 The time has already passed!");
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    setLoadingTime(false);
    return {
      days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeDifference / (1000 * 60)) % 60),
      seconds: Math.floor((timeDifference / 1000) % 60),
    };
  }, [currentDream]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);
  const isExpired = useMemo(
    () => Object.values(timeLeft).every((t) => t === 0),
    [timeLeft]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateTimeLeft]);

  useEffect(() => {
    setDisabled(currentDream?.userOwnerId === currentUser?.id);
  }, [currentDream, currentUser]);

  useEffect(() => {
    if (currentDream) {
      setShareUrl(window.location.href);
      setShareTitle(currentDream.name);
    }
  }, [currentDream]);

  const fetchDreamMakers = useCallback(async () => {
    try {
      const dreamMakersData = await getDreamMakers(userId, dreamId);
      setDreamMakers(dreamMakersData.docs.map((doc) => doc.data()));
    } catch (error) {
      console.error("Error fetching dream makers:", error);
    }
  }, [userId, dreamId]);

  useEffect(() => {
    fetchDreamMakers();
  }, [fetchDreamMakers]);

  useEffect(() => {
    if (currentDream) {
      const filteredMakers = makersSupportDreams.filter(
        (maker) => maker.dreamId === currentDream.id
      );
      setMakersSupportCurrentDream(filteredMakers);
    }
  }, [currentDream, makersSupportDreams]);

  useEffect(() => {
    const fetchMakersData = async () => {
      const makers = await Promise.all(
        makersSupportCurrentDream.map((maker) => getOneMaker(maker.userRef))
      );
      setMakersData(makers);
    };
    fetchMakersData();
  }, [makersSupportCurrentDream]);

  useEffect(() => {
    const getSimilarDreams = () => {
      if (!currentDream) return;
      const similar = dreams.filter(
        (dream) =>
          dream.id !== currentDream.id &&
          dream.categories?.some((category) =>
            currentDream.categories?.includes(category)
          )
      );
      setSimilardreams(similar);
    };
    getSimilarDreams();
  }, [currentDream, dreams]);

  const getCurrentDream = async () => {
    try {
      setLoading(true);
      const selectedDream = await getDream(userId, dreamId);
      setCurrentDream(selectedDream || null);
    } catch (error) {
      console.error(`Error fetching dream with ID ${dreamId}`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentDream();
    fetchDreamMakers();
  }, [dreamId]);

  const handleRefresh = () => {
    getCurrentDream();
    fetchDreamMakers();
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      console.log("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy link to clipboard:", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <Grid item xl={9} lg={11} md={12} xs={12} mt={0}>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ mb: 20 }} />
        </Box>
      </Grid>
    );
  }
  return (
    <Grid item xl={9} lg={11} md={12} xs={12} mt={7}>
      <Box
        component="main"
        //mt={10}
        sx={{
          padding: isDown700 ? (isDown580 ? "0 20px" : "0 40px") : "0 40px",
        }}
      >
        <Box
          component={"a"}
          onClick={() => navigate("/home/maker")}
          style={{
            cursor: "pointer",
            display: "block",
            mb: 2,
            width: "20px",
            zIndex: "3000",
          }}
        >
          <FiArrowLeft size={24} />
        </Box>
        <Hero />
        <Grid container>
          <Grid item xs={12} md={6} mt={2}>
            <Typography variant="h2" fontWeight="bold" sx={{ fontSize: 36 }}>
              {currentDream?.name}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={6}
            direction={isDown700 ? "column" : "row"} // Change direction to column on small screens
            alignItems={isDown700 ? "center" : "flex-start"} // Center items on small screens
            justifyContent={isDown700 ? "center" : "flex-end"}
            my={2}
            gap={2}
            ml="auto"
          >
            {/* Avatar */}
            <Grid item>
              <Avatar
                alt="Remy Sharp"
                src={placeHolder}
                sx={{ height: "60px", width: "60px", objectFit: "cover" }}
              />
            </Grid>

            {/* User information */}
            <Grid item>
              <div>
                <Typography variant="h5" fontWeight="bold">
                  {currentDream?.userOwnerName}
                </Typography>
                <Typography variant="body1">{currentDream?.email}</Typography>
              </div>
            </Grid>
            {/* Follow button (if needed) */}
          </Grid>
        </Grid>
        <Box sx={{ width: 336 }}>
          <img
            src={currentDream?.imgURL}
            alt=""
            width="100%"
            height="100%"
            style={{ borderRadius: "12px" }}
          />
        </Box>
        <Grid
          container
          spacing={5}
          mt={3}
          alignItems="center"
          justifyContent={{ md: "space-between", xs: "flex-start" }}
        >
          <Grid
            item
            md={6}
            xs={12}
            paddingRight={7}
            borderRight={{ md: "1px solid rgba(37, 40, 49, 0.10)", xs: "none" }}
          >
            <Typography component={"small"}>
              {transformDream?.needsLabel}
            </Typography>

            <Typography variant="body1" fontWeight="bold">
              {currentDream?.typeOfHelp}
            </Typography>
          </Grid>

          <Grid item md={3} xs={12}>
            <DreamTimer
              timeLeft={timeLeft}
              isExpired={isExpired}
              transformDream={transformDream}
              loadingTime={loadingTime}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <Stack direction="row" spacing={2}>
              <WhatsappShareButton url={shareUrl} title={shareTitle}>
                <FaWhatsapp
                  style={{ color: "#25D366", cursor: "pointer" }}
                  size={24}
                />
              </WhatsappShareButton>

              <InstapaperShareButton url={shareUrl} title={shareTitle}>
                <FaInstagram
                  style={{ color: "#E4405F", cursor: "pointer" }}
                  size={24}
                />
              </InstapaperShareButton>

              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FaFacebook
                  style={{ color: "#1877F2", cursor: "pointer" }}
                  size={24}
                />
              </FacebookShareButton>

              <TelegramShareButton url={shareUrl} title={shareTitle}>
                <FaTelegram
                  style={{ color: "#0088cc", cursor: "pointer" }}
                  size={24}
                />
              </TelegramShareButton>

              <IconButton
                color="primary"
                aria-label="copy"
                onClick={handleCopyLink}
              >
                <FaCopy size={24} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Box component="article" width={{ sm: "80%", xs: "90%" }}>
          <Typography variant="h5" fontWeight="bold" my={4}>
            {transformDream?.description}
          </Typography>
          <Typography>{currentDream?.description}</Typography>
        </Box>
        <Stack direction="row" gap={3} alignItems="center" marginTop="30px">
          <Button
            variant="contained"
            onClick={() => setOpenConfirmModal(true)}
            sx={{
              width: "70%",
              maxWidth: "500px",
              paddingTop: "10px",
              paddingBottom: "10px",
              background:
                "linear-gradient(90deg, #6345ED 0%, #7F42F0 52.60%, #DC39FC 100%)",
              // Add specific styles for the disabled state
              "&:disabled": {
                background: "grey", // Change to your desired disabled background color
                cursor: "not-allowed",
                // Add any other styles for the disabled state
              },
            }}
            disabled={isExpired || disabled}
          >
            {transformDream?.transform}
          </Button>
        </Stack>
        {/* <Box minHeight={530}> */}
        <Box minHeight={330}>
          <Box sx={{ borderColor: "divider", mt: 4 }}>
            <Tabs value={value} onChange={handleChange} sx={{ color: "#000" }}>
              <Tab label="Transformadores" value={0} />
              {/* <Tab label="Detalles adicionales" value={1} /> */}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            {dreamMakers.length > 0 ? (
              <>
                <Stack
                  direction="row"
                  overflow="auto"
                  gap={2}
                  marginBottom="40px"
                  alignItems="center"
                  mt={2}
                >
                  {/* Display only the first 5 dream makers */}
                  {dreamMakers.slice(0, 5).map((maker, index) => (
                    <Box key={index}>
                      <Avatar
                        alt={maker.name}
                        src={maker.imgURL || placeHolder}
                        sx={{
                          height: "60px",
                          width: "60px",
                          objectFit: "cover",
                        }}
                      />
                      <Typography variant="body2" textAlign="center">
                        {maker.name ?? "Usuario"}
                      </Typography>
                    </Box>
                  ))}

                  {/* Display "and this many more" if there are more than 5 dream makers */}
                  {dreamMakers.length > 5 && (
                    <Typography variant="body2" textAlign="center">
                      {`y ${dreamMakers.length - 5} más`}
                    </Typography>
                  )}
                </Stack>
              </>
            ) : (
              <Typography variant="body2" textAlign="center">
                {transformDream?.noTransformers}
              </Typography>
            )}
          </TabPanel>

          <TabPanel value={value} index={1}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </TabPanel>
        </Box>
        {/* <SimilarHelpGrid dreams={similarDreams} /> */}
        <ConfirmHelp
          open={openConfirmModal}
          handleOpen={setOpenConfirmModal}
          content={transformDream?.confirmDreamMessage}
          userId={userId}
          dreamId={dreamId}
          makerId={currentUser?.id}
          makerName={currentUser?.name}
          imgURL={currentUser?.imgURL}
          refresh={handleRefresh}
          transformDream={transformDream}
        />
      </Box>
    </Grid>
  );
};

export default DreamDetails;
