import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Popper from "@mui/material/Popper";

import { FiBell } from "react-icons/fi";

import noImagePlaceHolder from "../../assets/img/avatar-no-image.png";

import { BiDotsVerticalRounded } from "react-icons/bi";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TopUserActions from "../dreamerPage/topUserActions/TopUserActions";
import ToggleAccount from "../toggleAccount/ToggleAccount";
import { useEffect } from "react";
import { getUserFollows } from "../../store/actions/userActions";

const RightMenuAccount = ({
  openPopupMenu,
  setOpenPopupMenu,
  setNotificationsMod,
  setOpenRightSidebar,
  theme: { bgColor, fontColor } = false,
}) => {
  const userData = useSelector((state) => state.loggedUser.data);
  const { general } = useSelector((state) => state.loggedUser.language);
  const followedUsers = useSelector((state) => state.loggedUser.followedUsers);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const getFollows = () => {
      dispatch(getUserFollows(userData.id, 15));
    };

    getFollows();
  }, []);

  const handleFollowedUser = (user) => {
    if (location.pathname.includes("maker")) {
      //go to maker profile
      const url = `/home/maker/profile/${user.id}`;
      navigate(url);
    } else {
      //go to dreamer profile
      const url = `/home/dreamer/profile/${user.id}`;
      navigate(url);
    }
  };

  if (!followedUsers) {
    <CircularProgress />;
  }

  return (
    <Box
      sx={{
        backgroundColor: bgColor ? bgColor : "#1e1f20",
        height: "100vh",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        padding: "20px 40px",
        overflow: "hidden",
      }}
    >
      <Stack direction="row" gap={4} alignItems="center">
        <Box ml="auto" my={1}>
          <TopUserActions
            selectedIcon={<FiBell size={37} color="#fff" />}
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
            setNotificationsMod={setNotificationsMod}
            setOpenRightSidebar={setOpenRightSidebar}
          />
        </Box>
      </Stack>

      <Stack gap={3} mt={7}>
        <Stack alignItems="center">
          <img
            src={userData?.imgURL || noImagePlaceHolder}
            alt=""
            width="108px"
            height="108px"
            style={{ borderRadius: "36px" }}
          />
          <Typography variant="h4" mt={2} sx={{ color: "#fff" }}>
            {userData.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: fontColor ? fontColor : "#8F92A1" }}
          >
            {userData.email}
          </Typography>
        </Stack>

        <ToggleAccount
          fontColor={fontColor}
          currentType={
            location.pathname.includes("dreamer") ? "dreamer" : "maker"
          }
        />

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={3}
        >
          <Typography variant="body1">
            <span style={{ fontWeight: "bold", color: "#fff" }}>
              {userData?.postsCount ?? 0}
            </span>{" "}
            <span style={{ color: fontColor ? fontColor : "#8F92A1" }}>
              Posts
            </span>
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold", color: "#fff" }}>
              {userData?.followersCount ?? 0}
            </span>{" "}
            <span style={{ color: fontColor ? fontColor : "#8F92A1" }}></span>
          </Typography>
        </Stack>

        <Stack
          gap={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            disableElevation
            variant="contained"
            onClick={() => navigate("profile-info")}
            sx={{
              borderRadius: "12px",
              bgcolor: "#53d769",
              width: "149px",
              py: "12px",
            }}
          >
            {general?.editProfile}
          </Button>
          <span
            onClick={(event) => {
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
            style={{
              border: "2px solid rgb(143, 146, 161, 0.2)",
              borderRadius: "12px",
              padding: "7px 7px",
              cursor: "pointer",
            }}
          >
            <BiDotsVerticalRounded size={18} color="#fff" />
            <Popper open={open} anchorEl={anchorEl}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "background.paper",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
              >
                <small
                  onClick={() =>
                    navigate("/home/dreamer/account/profile-config")
                  }
                >
                  {general?.config}
                </small>
              </Box>
            </Popper>
          </span>
        </Stack>

        <Box>
          <Typography variant="h6" fontWeight="bold" color="#fff">
            {general?.aboutMe}
          </Typography>

          <Typography sx={{ color: fontColor ? fontColor : "#8F92A1" }}>
            {userData?.description}
          </Typography>
        </Box>
      </Stack>

      <Stack my={3}>
        <Typography variant="h6" fontWeight="bold" color="#fff" mb={1}>
          {general?.following}
        </Typography>
        <Grid container gap={2}>
          {followedUsers?.length ? (
            followedUsers.map((user) => {
              return (
                <Grid item lg={2} key={user.id}>
                  <Box
                    width="45px"
                    height="45px"
                    component={"div"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleFollowedUser(user)}
                  >
                    <img
                      src={user?.imgURL || noImagePlaceHolder}
                      width="100%"
                      height="100%"
                      alt=""
                      style={{ borderRadius: "14px" }}
                    />
                  </Box>
                </Grid>
              );
            })
          ) : (
            <Typography variant="body1" color="#fff">
              {general?.noOne} 🤨
            </Typography>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default RightMenuAccount;
