import {
  db,
  firebaseFieldVal,
  storageRef,
  timestampCreador,
} from "../../firebase/firebase-config";
import { types } from "../types/types";

import * as ES from "../../../languages/es.json";
import * as EN from "../../../languages/en.json";

export const getOneUser = async (id) => {
  try {
    const user = await db.collection("users").doc(id).get();
    return user.data();
  } catch (error) {
    console.log(error);
  }
};
export const setLanguage = (language) => {
  return (dispatch) => {
    let langData;

    // Load language data based on the selected language
    if (language === "en") {
      langData = EN;
    } else if (language === "es") {
      langData = ES;
    }

    dispatch({
      type: types.setLanguage,
      payload: {
        langData,
      },
    });
  };
};
// export const getOneUserData = async (id) => {
// try {
//   const result = await db.collection("users").doc(id).get();
//   const userData = result.data();
//   return userData;
// } catch (error) {
//   console.log(error);
// }
// };

// export const dispatchUserData = (userData) => {
//   return async (dispatch) => {
//     try {
//       dispatch(setIsLoadingUserData(true));
//       const unsubscribe = db
//         .collection("users")
//         .doc(id)
//         .onSnapshot((snapshot) => {
//           if (snapshot.exists) {
//             const userData = snapshot.data();
//             dispatch(getUserData({ id, ...userData }));
//           } else {
//             console.log("Document does not exist");
//           }

//           dispatch(setIsLoadingUserData(false));
//         });
//     } catch (error) {
//       dispatch(setIsLoadingUserData(false));
//       console.log(error);
//     }
//   };
// };

const firestoreUserListeners = {};

export const getOneUserData = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoadingUserData(true));
      const unsubscribe = db
        .collection("users")
        .doc(id)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const userData = snapshot.data();
            dispatch(getUserData({ id, ...userData }));
          } else {
            console.log("Document does not exist");
            //dispatch(setAuthError("auth/user-not-found"));
          }

          dispatch(setIsLoadingUserData(false));
        });

      firestoreUserListeners[id] = unsubscribe;
      //dispatch(getUserNotifications(id));
      //dispatch(setIsLoadingUserData(false));
    } catch (error) {
      dispatch(setIsLoadingUserData(false));
      console.log(error);
    }
  };
};

export const getAllUsers = () => {
  return async (dispatch) => {
    const users = [];
    dispatch(isLoading(true));
    db.collection("users")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          users.push({ id: item.id, ...item.data() })
        );
        dispatch(getUsers(users));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};

export const getAllMakers = async () => {
  return async (dispatch) => {
    try {
      const makers = [];
      const querySnapshot = await db
        .collection("users")
        .where("isMaker", "==", true)
        .get();

      querySnapshot.docs.forEach((doc) => {
        makers.push({ id: doc.id, ...doc.data() });
      });

      return makers;
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateProfile = async (id, userData, executeAction) => {
  let skipFinally = false;

  try {
    db.collection("users").doc(id).set(userData, { merge: true });
  } catch (error) {
    skipFinally = true;
    console.log(error);
  } finally {
    if (executeAction && !skipFinally) {
      executeAction();
    }
  }
};

export const createImageForUser = async (imageUpload, userId) => {
  try {
    const path = `users/${userId}/profile/${imageUpload.name}`;
    const fileUrl = await uploadFile(path, imageUpload);
    const newImg = { imgURL: fileUrl };

    await db.collection("users").doc(userId).update(newImg);
  } catch (error) {
    console.log(error);
  }
};

export const uploadFile = async (path, file) => {
  if (file === null) return;

  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log("No se puede subir el archivo seleccionado");
    console.log(error);
  }
};

export const updateOneUser = async (id, userData, executeAction) => {
  let skipFinally = false;

  try {
    db.collection("users").doc(id).update(userData);
  } catch (error) {
    skipFinally = true;
    console.log(error);
  } finally {
    if (executeAction && !skipFinally) {
      executeAction();
    }
  }
};

export const createCommentLike = async (
  userId,
  postId,
  commentData,
  likeData,
  fullName
) => {
  try {
    const data = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentData.id)
      .collection("likes")
      .add(likeData);

    const increment = firebaseFieldVal.increment(1);

    db.collection("users")
      .doc(commentData.userRef)
      .set({ unreadNotificationsNum: increment }, { merge: true });

    const notificationData = {
      cdate: timestampCreador.fromDate(new Date()),
      fromUserId: likeData.userRef,
      fromUserName: fullName,
      isRead: false,
      subject: "Le gusta tu comentario",
      type: "commentLike",
    };

    createNotification(commentData.userRef, notificationData);

    return data.id;
  } catch (error) {
    console.log(error);
  }
};

export const createPostLike = async (userId, postId, likeData, fullName) => {
  try {
    const data = await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("likes")
      .add(likeData);

    const likesIncrement = firebaseFieldVal.increment(1);
    const unreadIncrement = firebaseFieldVal.increment(1);

    db.collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .set({ likesCount: likesIncrement }, { merge: true });

    db.collection("users")
      .doc(userId)
      .set({ unreadNotificationsNum: unreadIncrement }, { merge: true });

    const notificationData = {
      cdate: timestampCreador.fromDate(new Date()),
      fromUserId: likeData.userRef,
      fromUserName: fullName,
      isRead: false,
      subject: "Le gusta tu publicación",
      type: "postLike",
    };

    createNotification(userId, notificationData);

    return data.id;
  } catch (error) {
    console.log(error);
  }
};

export const deletePostLike = async (userId, postId, likeId) => {
  try {
    await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("likes")
      .doc(likeId)
      .delete();

    const increment = firebaseFieldVal.increment(-1);

    db.collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .set({ likesCount: increment }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCommentLike = async (userId, postId, commentId, likeId) => {
  try {
    await db
      .collection("users")
      .doc(userId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId)
      .collection("likes")
      .doc(likeId)
      .delete();

    // const increment = firebaseFieldVal.increment(-1);

    // db.collection("users")
    //   .doc(userId)
    //   .collection("posts")
    //   .doc(postId)
    //   .set({ unreadNotificationsNum: increment }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const getUserFollows = (userId, limitNum) => {
  return async (dispatch) => {
    try {
      let query = db
        .collectionGroup("followers")
        .orderBy("cdate", "desc")
        .where("userRef", "==", userId);

      if (limitNum) {
        query = query.limit(limitNum);
      }

      const data = await query.get();
      const follows = [];

      for (const doc of data.docs) {
        const userId = doc.ref.parent.parent.id;
        const follower = await getOneUser(userId);
        follows.push({ id: userId, ...follower });
      }

      dispatch(getFollows(follows));
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkUserIsAFollower = async (userId, userToCheckId) => {
  try {
    const users = await db
      .collection("users")
      .doc(userId)
      .collection("followers")
      .where("userRef", "==", userToCheckId)
      .get();

    const exist = users.docs.some((user) => user.exists);
    let docId;

    users.docs.forEach((doc) => (docId = doc.id));

    return { exist, docId };
  } catch (error) {
    console.log(error);
  }
};

export const createFollower = (userId, followerData) => {
  try {
    db.collection("users")
      .doc(userId)
      .collection("followers")
      .add(followerData);

    const increment = firebaseFieldVal.increment(1);

    db.collection("users")
      .doc(userId)
      .set(
        { followersCount: increment, unreadNotificationsNum: increment },
        { merge: true }
      );

    db.collection("users")
      .doc(followerData.userRef)
      .set({ followedCount: increment }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const deleteFollower = async (userId, userToDeleteId, followerId) => {
  try {
    await db
      .collection("users")
      .doc(userId)
      .collection("followers")
      .doc(userToDeleteId)
      .delete();

    const increment = firebaseFieldVal.increment(-1);

    db.collection("users")
      .doc(userId)
      .set({ followersCount: increment }, { merge: true });

    db.collection("users")
      .doc(followerId)
      .set({ followedCount: increment }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const createNotification = (userId, notificationData) => {
  try {
    db.collection("users")
      .doc(userId)
      .collection("notifications")
      .add(notificationData);
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification = (userId, notificationId) => {
  try {
    db.collection("users")
      .doc(userId)
      .collection("notifications")
      .doc(notificationId)
      .delete();
  } catch (error) {
    console.log(error);
  }
};

export const createOneUserInnerCollection = async (
  id,
  userData,
  innerCollection
) => {
  try {
    db.collection("users").doc(id).collection(innerCollection).add(userData);
  } catch (error) {
    console.log(error);
  }
};

const firestoreNotificationListeners = {};

export const getUserNotifications = (userId) => {
  return async (dispatch) => {
    try {
      const unsubscribe = db
        .collection("users")
        .doc(userId)
        .collection("notifications")
        .onSnapshot((data) => {
          const notifications = [];

          data.docs.forEach((notification) => {
            if (notification.exists) {
              notifications.push({
                id: notification.id,
                ...notification.data(),
              });
            }
          });

          dispatch(setUserNotifications(notifications));
        });

      firestoreNotificationListeners[userId] = unsubscribe;
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateOneNotification = async (
  userId,
  notificationId,
  notificationData
) => {
  try {
    db.collection("users")
      .doc(userId)
      .collection("notifications")
      .doc(notificationId)
      .update(notificationData);

    const increment = firebaseFieldVal.increment(-1);

    db.collection("users")
      .doc(userId)
      .set({ unreadNotificationsNum: increment }, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const stopUserNotificationListeners = () => {
  for (const userId in firestoreNotificationListeners) {
    if (firestoreNotificationListeners.hasOwnProperty(userId)) {
      const unsubscribe = firestoreNotificationListeners[userId];
      if (unsubscribe) {
        unsubscribe();
      }
      delete firestoreNotificationListeners[userId];
    }
  }
};

export const stopUserListeners = () => {
  for (const userId in firestoreUserListeners) {
    if (firestoreUserListeners.hasOwnProperty(userId)) {
      const unsubscribe = firestoreUserListeners[userId];
      if (unsubscribe) {
        unsubscribe();
      }
      delete firestoreUserListeners[userId];
    }
  }
};

export const setUserNotifications = (notifications) => ({
  type: types.getUserNotifications,
  payload: {
    notifications,
  },
});

export const getFollows = (follows) => ({
  type: types.getFollows,
  payload: {
    follows,
  },
});

export const getOneFollow = (follow) => ({
  type: types.getOneFollow,
  payload: {
    follow,
  },
});

export const cleanFollows = () => ({
  type: types.cleanFollows,
});

export const setCategory = (category) => ({
  type: types.setUserCategory,
  payload: {
    selectedCategory: category,
  },
});

export const setIsLoadingUserData = (isLoading) => ({
  type: types.isLoadingUserData,
  payload: {
    isLoadingUserData: isLoading,
  },
});

export const isLoading = (isLoading) => ({
  type: types.isLoading,
  payload: {
    isLoading,
  },
});

export const getUsers = (users) => ({
  type: types.getAllUsers,
  payload: {
    users,
  },
});

export const getMakers = (makers) => ({
  type: types.getMakerUsers,
  payload: {
    makers,
  },
});

export const getUserData = (userData) => ({
  type: types.getUserData,
  payload: {
    data: userData,
  },
});

export const resetUserData = () => ({
  type: types.resetUserData,
});

export const resetUserNotifications = () => ({
  type: types.resetUserNotifications,
});
