import { types } from "../types/types";

const initialState = {
  user: null,
  loggedIn: false,
  errorCode: "no-error",
  userSelection: "",
  isDoingLogin: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.logInUser:
      return {
        ...state,
        user: action.payload.user,
        loggedIn: action.payload.loggedIn,
      };

    case types.isDoingLogIn:
      return {
        ...state,
        isDoingLogIn: action.payload.userSelection,
      };
    case types.isLoadingAuth:
      return {
        ...state,
        isLoadingAuth: action.payload.isLoadingAuth,
      };

    case types.setUserSelection:
      return {
        ...state,
        userSelection: action.payload.userSelection,
      };

    case types.errorMsg:
      return {
        ...state,
        errorCode: action.payload.code,
      };

    case types.logout:
      return initialState;

    default:
      return state;
  }
};
