import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftCard from "./LeftCard";
import DreamerCard from "./DreamerCard";
import MakerCard from "./MakerCard";
import { logInUser, setUserSelection } from "../../store/actions/auth";

const SignInFirstStep = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const { signIn, general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const currentUser = useSelector((state) => state.auth.user);
  const userData = useSelector((state) => state.loggedUser.data);
  const isLoadingUserData = useSelector(
    (state) => state.loggedUser.isLoadingUserData
  );

  const isDown900 = useMediaQuery("(max-width:900px)");

  const handleSubmit = () => {
    dispatch(setUserSelection(selected));
    dispatch(logInUser(currentUser, true));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        maxWidth="xl"
        sx={{
          boxShadow: "none",
          height: "auto",
          borderRadius: "20px",
        }}
      >
        <Grid container>
          <Grid item md={5} padding="40px">
            <LeftCard />
          </Grid>
          <Box width="70px"></Box>
          <Grid
            item
            xl={6}
            xs={12}
            padding={{ lg: "40px", xs: "20px 5px 40px 5px" }}
            display="flex"
            justifyContent="center"
          >
            <Box width={{ xl: "100%", md: "70%", sm: "100%" }}>
              <Stack
                direction="row"
                width={{ md: "60%", sm: "65%" }}
                gap={{ sm: "", xs: 2 }}
                justifyContent={{ lg: "space-between", md: "" }}
              >
                <span
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "1px solid #EAEAEA",
                    width: "30px",
                    height: "30px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: isDown900 ? "" : "auto",
                  }}
                >
                  <FiChevronLeft />
                </span>

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  {signIn?.whoYouAre}
                </Typography>
              </Stack>

              <Typography
                textAlign="center"
                width={{ lg: "50%", sm: "100%" }}
                margin="10px auto"
                sx={{ color: "#6D6D78" }}
              >
                {signIn?.select}
              </Typography>
              {isLoadingUserData ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <DreamerCard
                    selected={selected}
                    setSelected={setSelected}
                    userData={userData}
                    signIn={signIn}
                  />
                  <MakerCard
                    selected={selected}
                    setSelected={setSelected}
                    signIn={signIn}
                  />
                </>
              )}
              {/* <DreamerCard
                selected={selected}
                setSelected={setSelected}
                userData={userData}
              />
              <MakerCard selected={selected} setSelected={setSelected} /> */}

              <Stack mt="20px">
                {/* <MobileStepper
                  variant="dots"
                  itemProp=""
                  steps={2}
                  position="static"
                  activeStep={activeStep}
                  sx={{ maxWidth: 400, flexGrow: 1, margin: "0 auto" }}
                /> */}
                <Button
                  type="submit"
                  disabled={!selected}
                  variant="contained"
                  sx={{
                    background: "#9168FB",
                    width: "100%",
                    padding: "10px",
                    marginTop: "20px",
                    display: "flex",
                  }}
                >
                  {signIn?.continue}
                  <FiChevronRight />
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default SignInFirstStep;
