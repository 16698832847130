import { Box, useMediaQuery } from "@mui/material";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const CustomArrow = ({ direction, ...otherProps }) => {
  const isDown750 = useMediaQuery("(max-width:751px)");

  return (
    <Box
      position="absolute"
      sx={
        direction === "left"
          ? { left: isDown750 ? "40px" : "80px" }
          : { right: isDown750 ? "40px" : "80px" }
      }
    >
      <span
        {...otherProps}
        style={{
          position: "relative",
          zIndex: 100,
          cursor: "pointer",
          color: "#fff",
          borderRadius: "12px",
          padding: "12px",
          width: "48px",
          height: "48px",
          border: "2px solid rgba(143, 146, 161, 0.40)",
        }}
      >
        {direction === "left" ? (
          <FiChevronLeft size={26} />
        ) : (
          <FiChevronRight size={26} />
        )}
      </span>
    </Box>
  );
};

export default CustomArrow;
