import { Box, Stack } from "@mui/material";
import PostComment from "../postComment/PostComment";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { timestampCreador } from "../../../firebase/firebase-config";
import { createComment } from "../../../store/actions/dreamers";
import CommentsInput from "../commentsInput/CommentsInput";

const PostCommentSection = ({ postFirstComments, postId, postOwner }) => {
  const currentUser = useSelector((state) => state.loggedUser.data);

  // const [hasLike, setHasLike] = useState(false);

  const { getFieldProps, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: (values) => {
      const commentData = {
        cdate: timestampCreador.fromDate(new Date()),
        content: values.comment,
        userRef: currentUser.id,
        isActive: true,
      };

      createComment(postOwner, postId, commentData);
    },
  });

  return (
    <>
      <Box marginTop="20px">
        <CommentsInput
          handleSubmit={handleSubmit}
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Box>

      <Stack gap={3}>
        {postFirstComments.map((comment) => {
          const img = comment?.commentOwnerData?.imgURL;
          const name = comment?.commentOwnerData?.name;

          return (
            <PostComment
              data={comment}
              postId={postId}
              key={comment.id}
              // hasLike={hasLike}
              // setHasLike={setHasLike}
              postOwnerId={postOwner}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default PostCommentSection;
