import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FinancialCard from "../financialCard/FinancialCard";

import bulb from "../../../assets/img/bulb.svg";
import clock from "../../../assets/img/clock.svg";
import medal from "../../../assets/img/medal.svg";
import group from "../../../assets/img/group.svg";

import elipse from "../../../assets/img/financial-ellipse.png";
import Colors from "../../../utils/Colors";

const FinancialSection = ({ setOpenRegister, aboutPage }) => {
  const isDown1390 = useMediaQuery("(max-width:1390px)");
  const isDown1199 = useMediaQuery("(max-width:1199px)");
  const isDown600 = useMediaQuery("(max-width:600px)");

  const cardsInfo = [
    {
      iconSrc: bulb,
      title: aboutPage?.section4.cardText.text1,
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
    },
    {
      iconSrc: clock,
      title: aboutPage?.section4.cardText.text2,
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
    },
    {
      iconSrc: medal,
      title: aboutPage?.section4.cardText.text3,
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
    },
    {
      iconSrc: group,
      title: aboutPage?.section4.cardText.text4,
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
    },
  ];

  return (
    <Box
      component={"section"}
      display="flex"
      justifyContent="center"
      paddingLeft={{ xs: "20px", sm: "40px", md: "90px" }}
      paddingRight={{ xs: "20px", sm: "40px", md: "90px", lg: "90px" }}
      //sx={isDown1199 && { paddingLeft: "20px", paddingRight: "20px" }}
    >
      <Grid container>
        <Grid item lg={6} xs={12}>
          <Typography
            variant={isDown600 ? "h4" : "h3"}
            fontWeight="bold"
            width={isDown1390 ? "100%" : "60%"}
            textAlign={isDown1199 && "center"}
          >
            {aboutPage?.section4.title}
          </Typography>
          <Typography
            variant="body1"
            width={isDown1390 ? "100%" : "60%"}
            lineHeight="28px"
            my={3}
            sx={{ color: "#666666" }}
            textAlign={isDown1199 && "center"}
          >
            {aboutPage?.section4.text}
          </Typography>

          <Button
            onClick={() => setOpenRegister(true)}
            variant="contained"
            sx={{
              backgroundColor: Colors.primary,
              display: isDown1199 && "block",
              margin: "auto",
            }}
          >
            {aboutPage?.section4.button}
          </Button>
        </Grid>
        <Grid item lg={6} xs={12} marginTop={isDown1199 && "70px"}>
          <Container
            sx={{
              backgroundImage: !isDown600 && `url(${elipse})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% auto",
              backgroundPosition: "center",
              width: isDown1199 ? (isDown600 ? "100%" : "500px") : "auto",
              //height: "500px",
              margin: "auto",
            }}
          >
            <Grid
              container
              rowSpacing={"40px"}
              columnSpacing={!isDown600 && "40px"}
              width="100%"
            >
              {cardsInfo.map((card, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm="6"
                    display="flex"
                    justifyContent="center"
                    position={!isDown600 && "relative"}
                    bottom={index === 2 || index === 0 ? "30px" : ""}
                  >
                    <FinancialCard
                      iconSource={card.iconSrc}
                      title={card.title}
                      content={card.content}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialSection;
