import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import MobileStepper from "@mui/material/MobileStepper";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useEffect, useState } from "react";
import { getOneUser, updateOneUser } from "../../../store/actions/userActions";
import { useSelector } from "react-redux";
import LeftCard from "./LeftCard";
import DreamerCard from "./DreamerCard";
import MakerCard from "./MakerCard";

const SignUpFirstStep = ({ handleNext, currentStep }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selected, setSelected] = useState("");

  const currentUser = useSelector((state) => state.auth.user);

  const isDown900 = useMediaQuery("(max-width:900px)");
  const { signUp } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  useEffect(() => {
    const getUserType = async () => {
      const user = await getOneUser(currentUser?.uid);
      let userType = "";

      if (user?.isDreamer) {
        userType = "dreamer";
      } else if (user?.isMaker) {
        userType = "maker";
      }

      setSelected(userType);
    };

    getUserType();
  }, [currentStep]);

  const handleSubmit = () => {
    let userData = { isMaker: false, isDreamer: false };

    if (selected === "maker") {
      userData.isMaker = true;
    } else {
      userData.isDreamer = true;
    }

    updateOneUser(currentUser?.uid, userData);
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        maxWidth="xl"
        sx={{
          boxShadow: "none",
          height: "auto",
          borderRadius: "20px",
        }}
      >
        <Grid container>
          <Grid item md={5} padding="40px">
            <LeftCard />
          </Grid>
          {/* <Grid item md="1" /> */}
          <Box width="70px"></Box>
          <Grid
            item
            xl={6}
            xs={12}
            padding={{ lg: "40px", xs: "20px 5px 40px 5px" }}
            display="flex"
            justifyContent="center"
          >
            <Box width={{ xl: "100%", md: "70%", sm: "100%" }}>
              <Stack
                direction="row"
                width={{ md: "60%", sm: "65%" }}
                gap={{ sm: "", xs: 2 }}
                justifyContent={{ lg: "space-between", md: "" }}
              >
                <span
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "1px solid #EAEAEA",
                    width: "30px",
                    height: "30px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: isDown900 ? "" : "auto",
                  }}
                >
                  <FiChevronLeft />
                </span>

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  {signUp?.whoAreYou}
                </Typography>
              </Stack>

              <Typography
                textAlign="center"
                width={{ lg: "50%", sm: "100%" }}
                margin="10px auto"
                sx={{ color: "#6D6D78" }}
              >
                {signUp?.tellUs}
              </Typography>
              <DreamerCard
                selected={selected}
                setSelected={setSelected}
                signUp={signUp}
              />
              <MakerCard
                selected={selected}
                setSelected={setSelected}
                signUp={signUp}
              />

              <Stack mt="20px">
                <MobileStepper
                  variant="dots"
                  itemProp=""
                  steps={2}
                  position="static"
                  activeStep={activeStep}
                  sx={{ maxWidth: 400, flexGrow: 1, margin: "0 auto" }}
                />
                <Button
                  type="submit"
                  disabled={!selected}
                  variant="contained"
                  sx={{
                    background: "#9168FB",
                    width: "100%",
                    padding: "10px",
                    marginTop: "20px",
                    display: "flex",
                  }}
                >
                  {signUp?.continue}
                  {/* <span style={{ marginLeft: "auto" }}> */}
                  <FiChevronRight />
                  {/* </span>{" "} */}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default SignUpFirstStep;
