import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Grid, Stack } from "@mui/material";

import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../store/actions/makers";
import useFilterDreams from "../../../customHooks/useFilterDreams";
import { useEffect, useRef, useState } from "react";

const Filters = () => {
  const dispatch = useDispatch();
  const dreamFilters = useSelector((state) => state.makers.dreamFilters);
  //const filteredDreams = useFilterDreams(dreamFilters[0])
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  useEffect(() => {
    if (!dreamFilters || dreamFilters.length === 0) {
      dispatch(setFilters(["recent", "close", "dreams", "ascending"]));
    }
  }, [dreamFilters, dispatch]);

  const [filtersState, setFiltersState] = useState(dreamFilters || []);

  // console.log(dreamFilters);
  useEffect(() => {
    setFiltersState(dreamFilters);
  }, [dreamFilters]);

  const handleChange = (event, index) => {
    const copyArr = dreamFilters.slice();
    const newFilter = event.target.value;
    copyArr.splice(index, 1, newFilter);

    setFiltersState(copyArr);
    //dispatch(setFilters(copyArr));
  };
  const dropdownList = [
    {
      options: [
        {
          label: general?.sort?.recent,
          value: "recent",
        },
        {
          label: general?.sort?.old,
          value: "old",
        },
        {
          label: general?.sort?.abc,
          value: "alphabetic",
        },
      ],
    },
  ];
  if (!filtersState) {
    return;
  }

  return (
    <Box
      sx={{
        border: "1px solid #eaeaea",
        borderRadius: "20px",
        padding: "15px",
      }}
    >
      <Stack sx={{ minWidth: 150 }} direction="row" gap={2} alignItems="center">
        <Grid container spacing={1}>
          {/* <Grid item lg={4}> */}
          {dropdownList.map((dropdown, index) => {
            return (
              <Grid item lg={3} key={index}>
                <FormControl>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={filtersState[index]}
                    onChange={(event) => handleChange(event, index)}
                    sx={{
                      borderRadius: 20,
                      padding: "5px 15px",
                      width: 200,
                    }}
                    IconComponent={FiChevronDown}
                  >
                    {dropdown.options.map((option, index) => {
                      return (
                        <MenuItem value={option.value} key={index}>
                          {option.label}
                        </MenuItem>
                      );
                    })}

                    {/* {filterOption.filterItem.map((text) => {
                      return <MenuItem value={text}>{text}</MenuItem>;
                    })} */}
                  </Select>
                </FormControl>
              </Grid>
            );
          })}
        </Grid>

        <Button
          onClick={() => dispatch(setFilters(filtersState))}
          variant="contained"
          sx={{ backgroundColor: "#8828ff", width: 125 }}
        >
          {general?.filterDreams}
        </Button>
      </Stack>
    </Box>
  );
};

export default Filters;
