import { types } from "../types/menuTypes";

const initialState = {
  leftSidebarHidden: true,
  rightSidebarHidden: true,
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.toggleLeftSidebar:
      return {
        ...state,
        leftSidebarHidden: !state.leftSidebarHidden,
      };

    case types.toggleRightSidebar:
      return {
        ...state,
        rightSidebarHidden: action.payload,
      };
    default:
      return state;
  }
};
