import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const PrivateRoute = ({ isLoggedIn, children }) => {
  const location = useLocation();
  const userData = useSelector((state) => state.loggedUser.data);

  //Avoids makers leaking inside dreamer writting URL
  if (location.pathname.includes("dreamer")) {
    if (userData?.isDreamer) {
      return children;
    } else {
      return <Navigate to="/home/maker/" />;
    }
  }

  return isLoggedIn ? children : <Navigate to="/landing" />;
};

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};
