import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

import paco from "../../../assets/img/help-paco.png";
import { useSelector } from "react-redux";
import LanguageDropdown from "../../general/LanguageDropdown";
import Colors from "../../../utils/Colors";

const Hero = ({ item }) => {
  const isDown700 = useMediaQuery("(max-width:700px)");
  const isDown580 = useMediaQuery("(max-width:580px)");
  const { general, makersPage, register } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const userData = useSelector((state) => state.loggedUser.data);

  return (
    <Box
      component="section"
      sx={{
        marginBottom: 2,
        mt: 2,
        padding: isDown700 ? (isDown580 ? 0 : 0) : "0 0", //"0 20px" "0 40px"
      }}
    >
      <Box marginBottom="10px" width="100%">
        <Typography variant="h5">{register?.hello},</Typography>
        <Typography fontWeight="bold" variant="h3">
          {userData.name} 👋
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#efebf5",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Grid container>
          <Grid item md={5}>
            {/* <Stack direction="row">
              <Typography>Inicio</Typography>
              <span className="mx-2"> | </span>
              <Typography>Causas</Typography>
            </Stack> */}
            <Typography
              variant={isDown580 ? "h4" : "h3"}
              component="h1"
              height="90%"
              width="40%"
              display="flex"
              alignItems="center"
              fontWeight="600"
            >
              {item?.name ? item.name : general?.transform}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box padding="20px">
              <img
                src={paco}
                alt=""
                width="100%"
                style={{ maxWidth: "422px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Hero;
