import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import * as EN from "../../../../languages/en.json";
import * as ES from "../../../../languages/es.json";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setLanguage } from "../../../store/actions/userActions";
import LanguageDropdown from "../../general/LanguageDropdown";

const HomeNavBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language, navbar } = useSelector(
    (state) => state.loggedUser.language
  ); // Assuming your language state is stored in Redux

  const pages = [
    { label: navbar?.home, url: "/" },
    { label: navbar?.about, url: "/about-us" },
    { label: navbar?.contact, url: "/contact" },
    { label: navbar?.faq, url: "/frequently-ask" },
    // { label: "Blog", url: "#" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (lang) => {
    setAnchorEl(null);
    dispatch(setLanguage(lang));
  };
  //const isDown1200 = useMediaQuery("(max-width:1200px)");

  return (
    <AppBar
      position="static"
      sx={{
        background: "transparent",
        boxShadow: 0,
        paddingY: 2,
        [theme.breakpoints.down("md")]: {
          mt: 1,
        },
      }}
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "flex" },
            justifyContent: "center",
            [theme.breakpoints.down("md")]: {
              mt: 1,
            },
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => navigate(page.url)}
              sx={{
                my: 2,
                color: "#fff",
                display: "block",
                fontWeight: 700,
              }}
            >
              {page.label}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            [theme.breakpoints.down("md")]: {
              top: -20,
            },
          }}
        >
          <LanguageDropdown />
        </Box>

        {/* <Menu
          id="language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleLanguageClose(language.lang)}
        >
          <MenuItem onClick={() => handleLanguageClose("en")}>English</MenuItem>
          <MenuItem onClick={() => handleLanguageClose("es")}>Español</MenuItem>
        </Menu> */}
        {/* <Menu>
          {pages.map((page) => (
            <MenuItem key={page.label} onClick={() => navigate(page.url)}>
              <Typography
                textAlign="center"
                sx={{ color: "#fff" }}
                // sx={{
                //   color:
                //     location.pathname !== "/about-us"
                //       ? theme.palette.primary.main
                //       : "#C08DFF",
                // }}
                // color={location.pathname === "/about-us" ? "#C08DFF" : "#fff"}
              >
                {page.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu> */}
      </Toolbar>
    </AppBar>
  );
};

export default HomeNavBar;
