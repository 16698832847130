import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { BiChevronRight } from "react-icons/bi";

import noImagePlaceHolder from "../../assets/img/avatar-no-image.png";
import ToggleAccount from "../toggleAccount/ToggleAccount";
import { Animated } from "react-animated-css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PopupProfileConfig from "../../components/dreamerPage/popupProfileConfig/PopupProfileConfig";
import CreateDreamModal from "../../components/modals/CreateDreamModal";
import { getFollowersWithLimit } from "../../store/actions/dreamers";
import AddUser from "../addUser/AddUser";
import { useLocation } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";

const RightMenu = ({
  openPopupMenu,
  setOpenPopupMenu,
  setNotificationsMod,
  theme: { bgColor, fontColor } = false,
}) => {
  const [openCreateDream, setOpenCreateDream] = useState(false);
  const followers = useSelector((state) => state.dreamers.followers);
  const followedUsers = useSelector((state) => state.loggedUser.followedUsers);
  const { general } = useSelector((state) => state.loggedUser.language);
  const isLoadingFollowers = useSelector(
    (state) => state.dreamers.isLoadingFollowers
  );
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.loggedUser.data);
  const location = useLocation();
  useEffect(() => {
    const getFollowersPreview = () => {
      dispatch(getFollowersWithLimit(3, currentUser.id));
    };

    if (followers && followers.length < 3) {
      getFollowersPreview();
    }
  }, []);

  if (!followers) {
    <CircularProgress />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#1e1f20",
        height: "100vh",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        padding: "20px",
        overflow: "hidden",
      }}
    >
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <IconButton
          onClick={() => setNotificationsMod(true)}
          sx={{
            backgroundColor: "#79d0f1",
            width: "48px", // Adjust IconButton size
            height: "48px",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            color: "white",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#67bfe4", // Slightly darker shade for hover
            },
          }}
        >
          <Badge
            badgeContent={
              currentUser.unreadNotificationsNum > 0
                ? currentUser.unreadNotificationsNum
                : 0
            }
            color="error"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationsIcon sx={{ fontSize: "32px" }} />{" "}
            {/* Set icon size here */}
          </Badge>
        </IconButton>
        <div
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <img
            src={currentUser?.imgURL || noImagePlaceHolder}
            onClick={() => setOpenPopupMenu((prevValue) => !prevValue)}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          />

          <Animated
            animationIn="fadeInDown"
            animationOut="fadeOutUp"
            isVisible={openPopupMenu || false}
            animationInDuration={500}
            animationOutDuration={500}
            animateOnMount={false}
            style={{ position: "relative", zIndex: "100" }}
          >
            <PopupProfileConfig />
          </Animated>
        </div>
      </Stack>

      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#ff4e4e",
          color: "#fff",
          padding: "15px",
          marginTop: "45px",
        }}
      >
        <Typography fontWeight="bold">{general?.premium}</Typography>
        <Typography>{general?.tryPremium}</Typography>

        <Button
          variant="contained"
          disableElevation
          sx={{
            color: "#fff",
            backgroundColor: "rgba(30, 31, 32, 0.2)",
            borderRadius: "4px",
            marginTop: "20px",
          }}
        >
          {general?.viewMore} <BiChevronRight />
        </Button>
      </Box>

      <Stack sx={{ marginTop: "60px" }} gap={3}>
        <ToggleAccount
          general={general}
          fontColor={fontColor}
          currentType={
            location.pathname.includes("dreamer") ? "dreamer" : "maker"
          }
        />
        <Typography variant="h5" color="#fff" fontWeight="bold">
          {general?.followers}
        </Typography>

        {isLoadingFollowers ? (
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : followers?.length ? (
          followers.slice(0, 4).map((follower, index) => {
            return (
              <AddUser
                user={follower}
                followedUsers={followedUsers}
                location={"San José"}
                key={index}
              />
            );
          })
        ) : (
          <Typography variant="body1" color="#fff">
            No hay nadie por aqui 🤨
          </Typography>
        )}

        {/* <AddUser photo={anastasia} name={"Anastasia"} location={"San José"} />
        <AddUser photo={kevin} name={"Kevin"} location={"San José"} />
        <AddUser photo={anna} name={"Ana"} location={"San José"} /> */}
      </Stack>

      {/* <Button
        variant="text"
        sx={{
          color: "#8F92A1",
          fontWeight: "bold",
          marginTop: "20px",
          paddingLeft: 0,
        }}
      >
        Ver mas <BiChevronRight />
      </Button> */}

      {/* <Stack sx={{ marginTop: "60px" }} gap={3}>
        <Typography variant="h5" color="#fff" fontWeight="bold">
          Sueños
        </Typography>

        <Dream photo={anna} content={"Vasos de papel para usuarios"} />
        <Dream photo={anastasia} content={"Pasta de dientes"} />
        <Dream photo={kevin} content={"Hilo dental"} />
      </Stack> */}

      <CreateDreamModal
        openModal={openCreateDream}
        handleOpen={setOpenCreateDream}
      />
    </Box>
  );
};

export default RightMenu;
