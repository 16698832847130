import Container from "@mui/material/Container";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

import sectionBg from "../../../assets/img/whoYouAre-bg.png";
import UserTypeCard from "../userTypeCard/UserTypeCard";
import transformerImg from "../../../assets/img/world-in-your-hands.png";
import dayDreamerImg from "../../../assets/img/rocket.png";
import greenBall from "../../../assets/img/little-green-ball.png";
import yellowBall from "../../../assets/img/medium-yellow-ball.png";

import { ReactComponent as ThinRibbon } from "../../../assets/img/thin-ribbon.svg";
import { ReactComponent as GreenRibbon } from "../../../assets/img/greenRibbon.svg";
import { useSelector } from "react-redux";

const WhoYouAreSection = ({ openRegister, landing }) => {
  const makerContent = landing.makerSection.text3;
  const dreamerContent = landing.dreamerSection.text3;
  const { general } = useSelector((state) => state.loggedUser.language);
  const theme = useTheme();

  return (
    <Box
      component={"section"}
      sx={{
        backgroundImage: `url(${sectionBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "150% 100%",
        backgroundPosition: "center",
        height: "100vh",
        paddingBottom: "120px",
        paddingLeft: "20px",
        paddingRight: "20px",
        // When screen is lg (1200px) or below, set height to auto
        [theme.breakpoints.down("lg")]: {
          height: "auto",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          [theme.breakpoints.down("sm")]: {
            padding: "0 !important",
          },
        }}
      >
        <Typography
          variant="h4"
          component="h3"
          marginBottom="70px"
          className="text-center py-4 fw-bold"
        >
          {landing.makerSection.text1}
        </Typography>

        <Grid container spacing={7} justifyContent="center" position="relative">
          {/* Left decorative ball */}
          <Grid
            item
            alignItems="center"
            display={{ xs: "none", sm: "flex" }}
            lg={1}
            sx={{
              position: "absolute",
              left: "15%",
              top: "25%",
              // For screens smaller than lg (<=1200px), adjust position
              [theme.breakpoints.down("lg")]: {
                left: 0,
                top: "25%",
              },
              // For screens smaller than md (<=900px), adjust again
              [theme.breakpoints.down("md")]: {
                left: "-15%",
                top: "25%",
              },
            }}
          >
            <img
              src={greenBall}
              alt=""
              width={40}
              height={40}
              style={{ marginBottom: "100px" }}
            />
          </Grid>

          <Grid item sx={{ position: "relative" }} lg={5}>
            {/* Blue circle behind the first card */}
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                borderRadius: "50%",
                width: "325px",
                height: "325px",
                background: "#18acfe",
                position: "absolute",
                left: "-80px",
                top: "-90px",
                [theme.breakpoints.down("lg")]: {
                  width: "250px",
                  height: "250px",
                  left: "-50px",
                  top: "-50px",
                },
                [theme.breakpoints.down("md")]: {
                  width: "170px",
                  height: "170px",
                  left: "-85px",
                  top: "-85px",
                },
              }}
            ></Box>

            <UserTypeCard
              imgSource={transformerImg}
              title={landing.makerSection.text2}
              content={makerContent}
              openRegister={openRegister}
              landing={landing}
              general={general}
            />
          </Grid>

          <Grid item sx={{ position: "relative" }} lg={5}>
            {/* Purple circle behind the second card */}
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                borderRadius: "50%",
                width: "300px",
                height: "300px",
                background: "#8828FF",
                position: "absolute",
                right: "-110px",
                bottom: "-70px",
                [theme.breakpoints.down("lg")]: {
                  width: "250px",
                  height: "250px",
                },
                [theme.breakpoints.down("md")]: {
                  width: "160px",
                  height: "160px",
                },
              }}
            ></Box>

            <ThinRibbon
              style={{
                position: "absolute",
                right: "25%",
                zIndex: 300,
                bottom: "-350px",
              }}
            />
            <GreenRibbon
              style={{
                position: "absolute",
                right: "25%",
                zIndex: 300,
                bottom: "-350px",
              }}
            />

            <UserTypeCard
              imgSource={dayDreamerImg}
              title={landing.dreamerSection.text2}
              content={dreamerContent}
              openRegister={openRegister}
              landing={landing}
              general={general}
            />
          </Grid>

          {/* Right decorative ball */}
          <Grid
            item
            alignItems="center"
            display={{ xs: "none", sm: "flex" }}
            lg={1}
            sx={{
              position: "absolute",
              right: "15%",
              bottom: "25%",
              [theme.breakpoints.down("lg")]: {
                right: 0,
                bottom: "25%",
              },
              [theme.breakpoints.down("md")]: {
                right: "-15%",
                bottom: "25%",
              },
            }}
          >
            <img
              src={yellowBall}
              alt=""
              width={48}
              height={48}
              style={{ marginTop: "40px" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhoYouAreSection;
