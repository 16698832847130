import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { FiArrowLeft } from "react-icons/fi";
import CustonSwitch from "./CustomSwitch";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { updateProfile } from "../../../store/actions/userActions";
import { useSelector } from "react-redux";
import { callUpdateAlert } from "../../../customAlert/UpdateElement";

const NotificationConfig = () => {
  const navigate = useNavigate();
  const isDown600 = useMediaQuery("(max-width:600px)");
  const currentUser = useSelector((state) => state.loggedUser.data);
  const userData = useSelector((state) => state.loggedUser.data);
  const { configSettings } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });
  const { handleChange, handleSubmit, values, handleBlur } = useFormik({
    initialValues: {
      emailEachNotification: userData?.notifications ?? "",
      lists: userData?.notificationSettings?.lists ?? "",
      assistance: userData?.notificationSettings?.assistance ?? "",
      messajes: userData?.notificationSettings?.messajes ?? "",
      likes: userData?.notificationSettings?.likes ?? "",
      follows: userData?.notificationSettings?.follows ?? "",
      others: userData?.notificationSettings?.others ?? "",
    },
    //validationSchema: profileConfigSchema,
    onSubmit: (values) => {
      const profileConfig = {
        notifications: values.emailEachNotification,
        notificationSettings: {
          lists: values.lists,
          assistance: values.assistance,
          messajes: values.messajes,
          likes: values.likes,
          follows: values.follows,
          others: values.others,
        },
      };

      const updateNotificationsConfig = () => {
        updateProfile(currentUser.id, profileConfig);
      };

      callUpdateAlert(
        updateNotificationsConfig,
        undefined,
        "Perfil actualizado",
        "¿Desea modifcar su configuración?"
      );

      //callSuccessAlert("Perfil actualizado");
    },
  });

  return (
    <Grid
      xl={9}
      lg={8}
      md={11}
      xs={12}
      item
      minHeight="100vh"
      bgcolor="#f7f7f7"
      sx={{ padding: { md: "20px 60px", xs: "20px 25px" } }}
    >
      <Grid
        item
        container
        xs={12}
        gap={2}
        alignItems={"center"}
        justifyContent={"start"}
        flexDirection={"row"}
        my={{ xs: 0, md: 4 }}
        sx={{ flexWrap: { xs: "nowrap", md: "wrap" } }}
      >
        <span
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
            display: "block",
          }}
        >
          <FiArrowLeft size={24} />
        </span>

        <Typography variant={isDown600 ? "h4" : "h3"} fontWeight="bold">
          {configSettings?.title}{" "}
          <span style={{ color: "#864bf1" }}>{configSettings?.title2}</span>
        </Typography>
      </Grid>
      <Box mt={2}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <Typography variant={isDown600 ? "h5" : "h4"} mb={4}>
            {configSettings?.notificationsEmails}
          </Typography>

          <FormControl fullWidth>
            <FormControlLabel
              sx={{ display: "flex", marginLeft: 0 }}
              control={
                <CustonSwitch
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="emailEachNotification"
                  checked={values.emailEachNotification}
                  value={values.emailEachNotification}
                  sx={{ ml: "auto" }}
                />
              }
              labelPlacement="start"
              label={configSettings?.sendEmail}
            />
          </FormControl>

          <FormControl sx={{ my: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">
              {configSettings?.summaryText}
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.lists}
                    value={values.lists}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="lists"
                  />
                }
                label={configSettings?.list?.label1}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.assistance}
                    value={values.assistance}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="assistance"
                  />
                }
                label={configSettings?.list?.label2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.messajes}
                    value={values.messajes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="messajes"
                  />
                }
                label={configSettings?.list?.label3}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.likes}
                    value={values.likes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="likes"
                  />
                }
                label={configSettings?.list?.label4}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.follows}
                    value={values.follows}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="follows"
                  />
                }
                label={configSettings?.list?.label5}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.others}
                    value={values.others}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="others"
                  />
                }
                label={configSettings?.list?.label6}
              />
            </FormGroup>
          </FormControl>

          <Stack direction="row">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#000",
                padding: "10px 25px",
                marginTop: "20px",
                marginLeft: "auto",
              }}
            >
              {configSettings?.saveBtn}
            </Button>
          </Stack>
        </form>
      </Box>
    </Grid>
  );
};

export default NotificationConfig;
