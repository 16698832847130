import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import avatarChat3 from "../../../assets/img/avatar-chat-3.png";
import chatBubble from "../../../assets/img/white-left-chat-bubble.png";
import rectangle from "../../../assets/img/speech-bubble-rectangle.png";

const LeftBubble = ({ name, text ,subtitle}) => {
  const theme = useTheme();

  const containerStyle = {
    backgroundImage: `url(${chatBubble})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "325px",
    minWidth: "325px",
    height: "150px",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      width: "275px",
      minWidth: "275px",
    },
  };

  const textStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#ffffff", // Set the text color
    fontSize: "24px", // Adjust the font size as needed
    textAlign: "center",
    // Add more text styles as needed
  };
  const countLetters = (str) => str.replace(/[^a-zA-Z]/g, "").length;

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid
        item
        xl={2}
        lg={3}
        md={1}
        sm={1}
        xs={1}
        container
        justifyContent={"center"}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end" ,   [theme.breakpoints.down("sm")]: {
           
                pr:2
        
              }, }}>
          <img src={avatarChat3} alt="Avatar" width={40} />
        </Box>
      </Grid>
      <Grid
        item
        container
        md={8}
        sm={10}
        xs={11}
        sx={{
          display: "flex",
          justifyContent: "center",

          position: "relative",

          height: "100%",
          minHeight: "150px",
        }}
      >
        <Box sx={containerStyle}>
          <Box
            src={rectangle}
            component={"img"}
            alt=""
            sx={{
              position: "absolute",
              width: "44px",
              left: 15,
              bottom: 5,
              // [theme.breakpoints.down("lg")]: {
              //   position: "absolute",
              //   right: 40,
              //   bottom: 0,
              // },
              [theme.breakpoints.down("sm")]: {
                position: "absolute",
                width: "44px",
                left: 15,
                bottom: 6,
              },
            }}
          />
          <Box
            sx={{
              width: "275px",
              minWidth: "275px",
              zIndex: "100",
              display: "flex",
              height: 200,
              ml: 4,
              flexDirection: "column",
              flexWrap: "wrap",
              mt: 1.5,
              [theme.breakpoints.down("sm")]: {
                width: "225px",
                minWidth: "225px",
                ml: 1.5,
              },
            }}
          >
            <Typography
              sx={{
                color: "#ADABB7",
                fontSize: 13,
                width: "100%",
                bottom: 4,
                pl: 1,
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                  pl:0.5,
                  height:50
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: 10,
                  pl: 2,
                },
              }}
            >
              {text}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flexDirection:"row",
                mt: 1,
                pl: 0.5,
                pr: 0,
                mb: 1,

                height: 36,
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                  mb:0
                },
                [theme.breakpoints.down("sm")]: {
                  mt: 0,
                  pb: 0.5,
                },
              }}
            >
              <Typography
                sx={{
                  width: countLetters(name) > 15 ? subtitle ?  "100%" : '80%' : "100%",
                  fontWeight: "bold",
                  fontSize: countLetters(name) > 15 ? 11 : 14,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: countLetters(name) > 15 ? 10 : 12,
                    width: countLetters(name) > 15 ? "75%" : "100%",
                    pl: 1,
                  },
                }}
              >
                {name}
              </Typography>
            {/* {subtitle &&  <Typography
                sx={{
                  width: countLetters(name) > 15 ? "65%" : "100%",
                  fontWeight: "bold",
                  fontSize: countLetters(name) > 15 ? 11 : 14,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: countLetters(name) > 15 ? 10 : 12,
                    width: countLetters(name) > 15 ? "75%" : "100%",
                    pl: 1,
                  },
                }}
              >
                {subtitle}
              </Typography>} */}
         
            </Box>
            
            <Typography
                sx={{
                  position:'absolute',
                  color: "#ADABB7",
                  fontSize: 10,
                  fontWeight: "bold",
                  textAlign: "right",
                  bottom:35,
                  right:15,
                  [theme.breakpoints.down("md")]: {
             
                    bottom:45,
                  },
                  [theme.breakpoints.down("sm")]: {
             
                    bottom:55,
                  },
                }}
              >
                {new Date().toLocaleDateString("ES")}
              </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <img
            src={avatarChat3}
            alt="Avatar"
            width={40}
            style={{ marginTop: 10 }}
          />
        </Box> */}
        {/* <Box
          sx={{
            position: "absolute",
            backgroundImage: `url(${chatBubble})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            width: "100%",

            height: "100%",

            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        /> */}
      </Grid>
    </Grid>
  );
};

export default LeftBubble;
{
  /* <Typography
className="ms-auto"
style={{ color: "#ADABB7", fontSize: 10 }}
>
{new Date().toLocaleDateString("ES")}
</Typography> */
}
