import { Box } from "@mui/material";

const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    panelWidth = { lg: "65%", xs: "100%" },
    ...otherProps
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...otherProps}
    >
      {value === index && <Box sx={{ width: panelWidth }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
//py: 3
