import Grid from "@mui/material/Grid";

import { FaFacebookF } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";

import { useFormik } from "formik";
import { registerSchema } from "../../schema/registerForm/registerForm";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import {
  initWithGoogle,
  registerWithEmailAndPassword,
  setAuthError,
} from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import authErrors from "../../firebase/firebaseAuthError";

export const RegisterForm = ({
  handleOpenLogIn,
  handleOpenSignIn,
  handleOpenSignInStep,
  handleOpenSignUp,
  handleOpenSignUpSteps,
  handleOpenPassRecovery,
  register,
}) => {
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.auth.errorCode);
  const isDoingLogIn = useSelector((state) => state.auth.isDoingLogIn);

  const [showPassword, setShowPassword] = useState(false);
  const [isDoingGoogleRegister, setIsDoingGoogleRegister] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const isDown1200 = useMediaQuery("(max-width:1200px)");

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const doAfterRegistration = () => {
  //   handleOpenSignUpSteps(true);
  //   handleOpen(false);
  // };

  let doAfter;

  if (isDoingLogIn) {
    doAfter = () => {
      handleOpenSignInStep(true);
      handleOpenSignIn(false);
    };
  } else {
    doAfter = () => {
      handleOpenSignUpSteps(true);
      handleOpenSignUp(false);
      //navigate("/redirect");
    };
  }

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      dispatch(setAuthError("no-error"));

      dispatch(
        registerWithEmailAndPassword(values.email, values.password, doAfter, {
          name: values.name,
        })
      );
    },
  });

  useEffect(() => {
    const checkForErrors = () => {
      if (authErrors[errorCode] !== "no-error") {
        const msg = authErrors[errorCode].msg;
        const errorType = authErrors[errorCode].type;
        setFieldError(errorType, msg);
      }
    };

    checkForErrors();
  }, [errorCode]);

  return (
    <form
      style={{ marginBottom: "35px" }}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container>
        <Grid item xs={12} lg={12}>
          <FormHelperText error id="outlined-adornment-email">
            {isDoingGoogleRegister && errors.email ? errors.email : " "}
          </FormHelperText>

          <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
            <label
              htmlFor="outlined-adornment-name"
              style={{ fontWeight: "bold" }}
            >
              {register?.name}
            </label>
            <OutlinedInput
              id="outlined-adornment-name"
              name="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              type="text"
            />

            <FormHelperText error id="outlined-adornment-name">
              {touched.name && errors.name ? errors.name : " "}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
            <label
              htmlFor="outlined-adornment-email"
              style={{ fontWeight: "bold" }}
            >
              {register?.email}
            </label>
            <OutlinedInput
              id="outlined-adornment-email"
              name="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              type={"email"}
              endAdornment={
                <InputAdornment position="end" sx={{ color: "#53D769" }}>
                  {errors.email ? (
                    <ErrorOutlineIcon />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                </InputAdornment>
              }
            />

            <FormHelperText error id="outlined-adornment-email">
              {touched.email && errors.email ? errors.email : " "}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={12}>
          <FormControl variant="outlined" sx={{ width: "100%", padding: 0 }}>
            <label
              htmlFor="outlined-adornment-password"
              style={{ fontWeight: "bold" }}
            >
              {register?.password}
            </label>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              value={values.password}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error id="outlined-adornment-password">
              {touched.password && errors.password ? errors.password : " "}
            </FormHelperText>
          </FormControl>

          <Button
            variant="text"
            sx={{ marginTop: "10px", color: "#8F92A1" }}
            onClick={() => {
              handleOpenSignIn(false);
              handleOpenPassRecovery(true);
            }}
          >
            {register?.forgotPassword}
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "100%",
              backgroundColor: "#79d0f1",
              borderRadius: "8px",
              padding: "10px 15px",
            }}
          >
            {register?.title}
          </Button>
          <p className="text-center my-4" style={{ color: "#8F92A1" }}>
            {register?.socials}
          </p>

          <Button
            variant="outlined"
            onClick={() => {
              setIsDoingGoogleRegister(true);
              dispatch(initWithGoogle(doAfter));
            }}
            sx={{
              borderRadius: "8px",
              padding: "10px 15px",
              width: "100%",
              mb: 2,
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <FaGoogle />
            </span>{" "}
            {register?.google}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
