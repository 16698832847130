import * as Yup from "yup";

const urlPattern =
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;

export const profileConfigSchema = Yup.object({
  language: Yup.string(),
  currency: Yup.string(),
  showEmail: Yup.string(),
  showProfiles: Yup.string(),
  showSocialProfiles: Yup.string(),
  facebook: Yup.string().matches(urlPattern, "Formato de URL no valido"),
  twitter: Yup.string().matches(urlPattern, "Formato de URL no valido"),
  twitch: Yup.string().matches(urlPattern, "Formato de URL no valido"),
  instagram: Yup.string().matches(urlPattern, "Formato de URL no valido"),
  youtube: Yup.string().matches(urlPattern, "Formato de URL no valido"),
  discord: Yup.string().matches(urlPattern, "Formato de URL no valido"),
});
