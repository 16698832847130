import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";

const ConfigSocialMedia = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  general,
}) => {
  return (
    <>
      <Typography variant="h4" mb={4}>
        {general?.socialMedia}
      </Typography>

      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Facebook</label>
            <OutlinedInput
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.facebook}
              name="facebook"
            />

            <FormHelperText error id="outlined-facebook">
              {touched.facebook && errors.facebook ? errors.facebook : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Twitter</label>
            <OutlinedInput
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.twitter}
              name="twitter"
            />

            <FormHelperText error id="outlined-twitter">
              {touched.twitter && errors.twitter ? errors.twitter : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Instagram</label>
            <OutlinedInput
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.instagram}
              name="instagram"
            />

            <FormHelperText error id="outlined-instagram">
              {touched.instagram && errors.instagram ? errors.instagram : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Twitch</label>
            <OutlinedInput
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.twitch}
              name="twitch"
            />

            <FormHelperText error id="outlined-twitch">
              {touched.twitch && errors.twitch ? errors.twitch : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>YouTube</label>
            <OutlinedInput
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.youtube}
              name="youtube"
            />

            <FormHelperText error id="outlined-youtube">
              {touched.youtube && errors.youtube ? errors.youtube : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <label style={{ fontWeight: "bold" }}>Discord</label>
            <OutlinedInput
              sx={{ borderRadius: "50px" }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.discord}
              name="discord"
            />

            <FormHelperText error id="outlined-lastname">
              {touched.discord && errors.discord ? errors.discord : " "}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigSocialMedia;
