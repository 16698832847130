import {
  Box,
  Container,
  Stack,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";

import ReactPlayer from "react-player";

import { debounce } from "lodash";

import { BiComment } from "react-icons/bi";
import { FaShare } from "react-icons/fa";

import { useEffect, useState } from "react";
import {
  getPostComments,
  getRecentComments,
} from "../../../store/actions/dreamers";
import LikeHeartBtn from "../../likeHeartBtn/LikeHeartBtn";
import {
  createPostLike,
  deletePostLike,
} from "../../../store/actions/userActions";
import Visualizer from "../../visualizer/Visualizer";
import PostCommentSection from "../postCommentSection/PostCommentSection";
import PostHeader from "../postHeader/PostHeader";
import "./embedVideo.scss";
import { useSelector } from "react-redux";
import ShareModal from "../../share/ShareModal";
import Colors from "../../../utils/Colors";

const VideoPostCard = ({ data }) => {
  const [postFirstComments, setPostFirstComments] = useState([]);
  const [postData, setPostData] = useState({ postImages: [] });
  const [commentsNumber, setCommentsNumber] = useState(0);
  const [likesNumber, setLikesNumber] = useState(0);
  const [openVisualizer, setOpenVisualizer] = useState(false);
  const [hasLike, setHasLike] = useState(false);
  const [postOwnerData, setPostOwnerData] = useState(null);
  const currentUser = useSelector((state) => state.loggedUser.data);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const { general } = useSelector((state) => {
    // console.log(state); // Log the entire state
    return state.loggedUser.language;
  });

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const getComments = async () => {
      const comments = await getPostComments(data.userOwnerId, data.id, 2);
      setPostFirstComments(comments);
    };

    if (data) {
      getComments();
    }
  }, [data]);

  useEffect(() => {
    setPostData(data);
    setCommentsNumber(data?.commentsCount || 0);
    setLikesNumber(data?.likesCount || 0);

    const { postLikes } = data;

    if (postLikes?.length) {
      setHasLike(true);
    } else {
      setHasLike(false);
    }
  }, [data]);

  useEffect(() => {
    const postId = data.id;
    const setNewComments = (comments) => {
      setPostFirstComments(comments);
    };

    const unsubscribe = getRecentComments(
      postId,
      setNewComments,
      data.userOwnerId,
      2,
      currentUser.id
    );

    return () => {
      unsubscribe();
    };
  }, [postData]);

  const handleLike = debounce(() => {
    const postId = postData.id;
    const userId = currentUser.id;

    if (hasLike) {
      const likeId = postData.postLikes[0].id;

      deletePostLike(userId, postId, likeId);
      setHasLike(false);
      setLikesNumber((prevValue) => prevValue - 1);
    } else {
      createPostLike(userId, postId, { userRef: userId });
      setHasLike(true);
      setLikesNumber((prevValue) => prevValue + 1);
    }
  }, 500);
  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Close the modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  const shareUrl = `https://foundation-smileup.web.app/home/maker/profile/${postOwnerData?.id}`;
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        marginTop: data?.type === "video" ? "40px" : "20px",
        padding: { md: "40px", xs: "25px" },
        borderRadius: "12px",
      }}
    >
      <PostHeader
        postData={data}
        postOwnerData={postOwnerData}
        setPostOwnerData={setPostOwnerData}
      />

      <Box
        sx={{
          position: "relative",
          pt: 2,
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        {data.videoURL ? (
          <Container className="responsive-video" sx={{ borderRadius: "12px" }}>
            <ReactPlayer
              url={postData?.videoURL}
              width="100%"
              controls={true}
            />
          </Container>
        ) : null}
      </Box>
      <Grid item xs={12} container>
        <Typography
          onClick={() => setIsExpanded((prev) => !prev)}
          sx={{
            color: "#8F92A1",
            fontWeight: "bold",
            margin: "21px 0",
            overflow: isExpanded ? "visible" : "hidden",
            display: isExpanded ? "block" : "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isExpanded ? "none" : 3,
            textOverflow: isExpanded ? "unset" : "ellipsis",
            lineHeight: "1.5",
            cursor: "pointer", // Add a pointer cursor for interactivity
            wordWrap: "break-word",
            fontSize: { xs: "14px", md: "16px" },
          }}
          variant="body1"
        >
          {postData?.description}
        </Typography>
      </Grid>
      <Stack direction="row" justifyContent="space-between" marginTop="40px">
        <Stack direction="row" gap={3}>
          <Stack direction="row" alignItems="center">
            <Box onClick={handleLike} component={"span"}>
              <LikeHeartBtn hasLike={hasLike} />
            </Box>

            <Typography fontWeight="bold" ml={1}>
              {likesNumber}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenVisualizer(true);
              }}
            >
              <BiComment />
            </span>
            <Typography fontWeight="bold" ml={1}>
              {commentsNumber}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Typography
            component={"a"}
            fontWeight="bold"
            sx={{ color: Colors.secondary, cursor: "pointer", pr: 1 }}
            onClick={handleOpenShareModal}
          >
            {general?.share}
          </Typography>
          <FaShare color={Colors.secondary} />
        </Stack>
      </Stack>

      <PostCommentSection
        postFirstComments={postFirstComments}
        postId={data.id}
        postOwner={data.userOwnerId}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={handleCloseShareModal}
        shareUrl={shareUrl}
        shareTitle="Check out this awesome content!"
      />
      {openVisualizer && (
        <Visualizer
          openVisualizer={openVisualizer}
          handleClose={setOpenVisualizer}
          slides={[{ videoSource: postData?.videoURL }]}
          post={postData}
          setCommentsNumber={setCommentsNumber}
          commentsNum={commentsNumber}
        />
      )}
    </Box>
  );
};

export default VideoPostCard;
