import { useState } from "react";
import TabPanel from "../../makerPage/tabPanel/TabPanel";
import CustomTab from "./CustomTab";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Stack,
  Tabs,
} from "@mui/material";
import TopUserActions from "../topUserActions/TopUserActions";
import ReactPlayer from "react-player";
import { useEffect } from "react";
import {
  getOneUserPosts,
  getUserEvents,
  getUserImages,
  getUserVideos,
} from "../../../store/actions/dreamers";
import PhotoCollectionPostCard from "../photoCollectionPost/PhotoCollectionPostCard";
import VideoPostCard from "../videoPost/VideoPostCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import EventsCard from "../eventCard/EventCard";
import RightNotificationMenu from "../../RightMenu/RightNotificationMenu";

const UserContent = ({
  userId,
  openRightSidebar,
  setOpenRightSidebar,
  general,
  makersPage,
}) => {
  const [value, setValue] = useState(0);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [postsVideoSource, setPostsVideoSource] = useState([]);
  const [events, setEvents] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [postNumber, setPostNumber] = useState(100);
  const [fetchPosts, setFetchPosts] = useState(false);

  const BATCH_SIZE = 5;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUserPosts = async () => {
    const postList = await getOneUserPosts(lastPost, BATCH_SIZE, userId);
    console.log(postList); // Add this line to check the value of postList

    setUserPosts((prevPosts) => [...prevPosts, ...postList]);
    setPostNumber(postList?.length);
    setLastPost(postList[postList?.length - 1]);

    let images = [];
    postList.forEach((post) => {
      if (post.type === "image") {
        images = images.concat(post.postImages);
      }
    });

    let videos = [];
    postList.forEach((post) => {
      if (post.type === "video") {
        videos = videos.concat(post.videoURL);
      }
    });
  };

  useEffect(() => {
    const getImages = async () => {
      const images = await getUserImages(userId);
      const videos = await getUserVideos(userId);
      const events = await getUserEvents(userId);
      setPostImages(images);
      setPostsVideoSource(videos);
      setEvents(events);
    };

    getImages();
  }, [userId]);

  // useEffect(() => {
  //   setUserPosts([]);
  //   setLastPost(null);
  //   setPostNumber(100);
  //   setFetchPosts((prevValue) => !prevValue);
  // }, [userId]);

  useEffect(() => {
    let ignore = false;

    if (BATCH_SIZE <= postNumber) {
      if (!ignore) {
        getUserPosts();
      }
    }

    return () => {
      ignore = true;
    };
  }, [fetchPosts]);

  return (
    <>
      <Stack direction="row" alignItems="center" height="110px">
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={handleChange}
          indicatorColor="none" // Remove the default indicator
        >
          <CustomTab label={makersPage?.posts} value={0} />
          <CustomTab label={makersPage?.photos} value={1} />
          <CustomTab label={makersPage?.videos} value={2} />
          <CustomTab label={makersPage?.events} value={3} />
        </Tabs>

        <Box marginLeft="auto" display={{ md: "block", xs: "none" }}>
          <TopUserActions
            openPopupMenu={openPopupMenu}
            setOpenPopupMenu={setOpenPopupMenu}
            setNotificationsMod={setOpenRightSidebar}
          />
        </Box>
      </Stack>

      <TabPanel value={value} index={0} panelWidth={{ lg: "100%", xs: "100%" }}>
        <Box
          width="100%"
          id="scrollableBox"
          overflow={userPosts ? "hidden" : "scroll"}
          maxHeight="85vh"
          justifyContent="center"
        >
          <InfiniteScroll
            style={{ scrollbarWidth: "none" }}
            scrollableTarget="scrollableBox"
            dataLength={userPosts.length} //This is important field to render the next data
            next={getUserPosts}
            hasMore={postNumber >= BATCH_SIZE}
            loader={
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="100px"
              >
                <CircularProgress />
              </Stack>
            }
            endMessage={
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <b>{general?.reachEnd} 🙃 </b>
              </p>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 800: 1, 900: 2 }}
              style={{ width: "100%" }}
            >
              <Masonry
                gutter="30px"
                style={{
                  width: "100%",
                }}
              >
                {userPosts.map((post, index) => {
                  return (
                    <Box
                      width="100%"
                      border="2px solid rgb(143, 146, 161, 0.2)"
                      marginBottom="40px"
                      key={index}
                      borderRadius="12px"
                    >
                      <>
                        {post.type === "image" ? (
                          <PhotoCollectionPostCard
                            likesNumber={326}
                            //setSelectedPost={setSelectedPostId}
                            //setPostOwner={setPostOwnerId}
                            //handleOpenModal={setOpenCommentsModal}
                            data={post}
                            key={post.id}
                          />
                        ) : (
                          <VideoPostCard
                            likesNumber={326}
                            //setSelectedPost={setSelectedPostId}
                            //setPostOwner={setPostOwnerId}
                            //handleOpenModal={setOpenCommentsModal}
                            data={post}
                            key={post.id}
                          />
                        )}
                      </>
                    </Box>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1} panelWidth={{ lg: "100%", xs: "100%" }}>
        <Grid
          container
          spacing={2}
          overflow={postImages ? "hidden" : "scroll"}
          maxHeight="85vh"
        >
          {postImages.length ? (
            postImages.map((imgItem, index) => {
              return (
                <Grid item xl={3} lg={4} md={6} xs={12} key={index}>
                  <Box>
                    <img
                      src={imgItem.imgURL}
                      alt=""
                      style={{ borderRadius: "12px" }}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              );
            })
          ) : (
            <p
              style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
            >
              <b>{general?.noImages}</b>
            </p>
          )}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2} panelWidth={{ lg: "100%", xs: "100%" }}>
        <Grid
          container
          spacing={2}
          overflow={postsVideoSource ? "hidden" : "scroll"}
          maxHeight="85vh"
        >
          {postsVideoSource.length ? (
            postsVideoSource.map((video, index) => {
              return (
                <Grid item xl={3} lg={4} md={6} xs={12} key={index}>
                  <Box>
                    <ReactPlayer
                      url={video.videoURL}
                      width="100%"
                      controls={true}
                    />
                  </Box>
                </Grid>
              );
            })
          ) : (
            <p
              style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
            >
              <b> {general?.noVideos}</b>
            </p>
          )}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={3} panelWidth={{ lg: "100%", xs: "100%" }}>
        <Grid
          container
          spacing={2}
          overflow={events ? "hidden" : "scroll"}
          maxHeight="85vh"
        >
          {events.length ? (
            events.map((event, index) => {
              return (
                <Grid item md={6} xs={12} key={index}>
                  <EventsCard eventData={event} />
                </Grid>
              );
            })
          ) : (
            <p
              style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
            >
              <b>{general?.noEvents}</b>
            </p>
          )}
        </Grid>
      </TabPanel>

      <Drawer
        anchor="right"
        open={openRightSidebar}
        onClose={() => setOpenRightSidebar(false)}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "32px",
            borderBottomLeftRadius: "32px",
            //maxWidth: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
            minWidth: { xl: "15%", lg: "21%", md: "25%", sm: "35%", xs: "40%" },
            //width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
          },
        }}
      >
        <RightNotificationMenu
          openPopupMenu={openPopupMenu}
          setOpenPopupMenu={setOpenPopupMenu}
          setNotificationsMod={setOpenRightSidebar}
        />
      </Drawer>
    </>
  );
};

export default UserContent;
