import React from "react";
import FirstPage from "../components/landingPage/firstPage/FirstPage";
import styles from "../assets/scss/landing.module.scss";
import buttonStyles from "../assets/scss/buttons.module.scss";

import { Container } from "react-bootstrap";

const LandingPage = () => {
  return (
    <Container fluid className="landingWrapper">
      <FirstPage styles={styles} buttonStyles={buttonStyles} />
    </Container>
  );
};

export default LandingPage;
