import { Box, Divider, Stack, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

import CustonSwitch from "../customSwitch/CustomSwitch";

import { BiUser, BiNotification } from "react-icons/bi";
import { BiExit } from "react-icons/bi";
import { FiSettings, FiChevronRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../store/actions/auth";
import { callUpdateAlert } from "../../../customAlert/UpdateElement";
import { updateProfile } from "../../../store/actions/userActions";
import { callLogOutAlert } from "../../../customAlert/LogOutAlert";

const PopupProfileConfig = () => {
  const [profileURL, setProfileURL] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [isOnline, setIsOnline] = useState(false); // NEEDS IMPLEMENTATION
  const { general, swals } = useSelector((state) => state.loggedUser.language);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.loggedUser.data);

  const handleClick = (goTo) => {
    switch (goTo) {
      case "profile":
        navigate(profileURL);
        break;

      case "configuration":
        navigate("/home/maker/notifications-config");
        break;

      case "logout":
        const endSession = () => {
          dispatch(logoutUser());
        };

        callLogOutAlert(endSession, swals.logoutWarning);
        break;

      case "toggleNotifications":
        let alertMsg = "";

        if (isDisable) {
          alertMsg = "¿Desea desactivar las notificaciones por email?";
        } else {
          alertMsg = "¿Desea activar las notificaciones por email?";
        }

        const updateConfig = () => {
          const profileConfig = {
            notifications: !isDisable,
          };

          updateProfile(userData.id, profileConfig);
        };

        callUpdateAlert(updateConfig, undefined, "Cambio completado", alertMsg);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const prepareURL = () => {
      if (location.pathname.includes("maker")) {
        //go to maker profile
        const url = `/home/maker/profile/${userData.id}`;
        setProfileURL(url);
      } else {
        //go to dreamer profile
        const url = "/home/dreamer/account";
        setProfileURL(url);
      }
    };

    if (userData) {
      setIsDisable(userData.notifications || false); // Fallback to false if undefined

      prepareURL();
    }
  }, [userData]);

  return (
    <Box
      width="220px"
      position="absolute"
      right={0}
      top={10}
      bgcolor="#ffffff"
      borderRadius="12px"
      padding="10px"
      zIndex="100"
    >
      <Stack
        direction="row"
        padding="10px"
        alignItems="center"
        gap={1}
        color="#8F92A1"
      >
        <Typography>{general?.state}</Typography>
        <Stack direction="row" marginLeft="auto" gap={1}>
          <Typography>Online</Typography>
          <CustonSwitch
            checked={isOnline}
            onChange={(e) => setIsOnline(e.target.checked)}
          />
        </Stack>
      </Stack>
      <Divider />
      <ListItemButton
        sx={{ padding: "10px" }}
        onClick={() => handleClick("profile")}
      >
        <Stack direction="row" alignItems="center" gap={1} width="100%">
          <BiUser />
          <Typography>{general?.myProfile}</Typography>{" "}
          <span style={{ marginLeft: "auto", color: "#0659FD" }}>
            <FiChevronRight />
          </span>
        </Stack>
      </ListItemButton>
      <ListItemButton
        sx={{ padding: "10px" }}
        onClick={() => handleClick("configuration")}
      >
        <Stack direction="row" alignItems="center" gap={1} width="100%">
          <FiSettings />
          <Typography>{general?.config}</Typography>{" "}
          <span style={{ marginLeft: "auto", color: "#0659FD" }}>
            <FiChevronRight />
          </span>
        </Stack>
      </ListItemButton>
      <ListItemButton
        sx={{ padding: "10px" }}
        onClick={() => handleClick("toggleNotifications")}
      >
        <Stack direction="row" alignItems="center" gap={1} width="100%">
          <BiNotification />

          <Typography>{general?.notifications}</Typography>
          <span style={{ marginLeft: "auto", color: "#0659FD" }}>
            <CustonSwitch
              checked={isDisable}
              onChange={(e) => setIsDisable(e.target.checked)}
            />
          </span>
        </Stack>
      </ListItemButton>

      <ListItemButton
        sx={{ padding: "10px" }}
        onClick={() => handleClick("logout")}
      >
        <Stack direction="row" alignItems="center" gap={1} width="100%">
          <BiExit />
          <Typography>{general?.logout}</Typography>
          <span style={{ marginLeft: "auto", color: "#0659FD" }}>
            <FiChevronRight />
          </span>
        </Stack>
      </ListItemButton>
    </Box>
  );
};

export default PopupProfileConfig;
