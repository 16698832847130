import { Box, Modal, useMediaQuery } from "@mui/material";
import UploadImageForm from "../dreamerPage/createPostForm/UploadImageForm";

const CreateImagePostModal = ({
  openModal,
  handleOpen,
  setPostType,
  setImgFiles,
}) => {
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const isDown500 = useMediaQuery("(max-width:500px)");

  return (
    <Modal open={openModal} onClose={() => handleOpen(false)}>
      <Box
        className="d-flex justify-content-center align-items-center bg-white"
        sx={{
          width: isDown1200 ? "70%" : "60%",
          maxWidth: 1000,
          minWidth: 325,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: isDown500 ? "30px" : "40px",
          borderRadius: "20px",
        }}
      >
        <UploadImageForm
          handleOpen={handleOpen}
          setPostType={setPostType}
          setImgFiles={setImgFiles}
          imgLimit={5}
          title={"Creando una publicación"}
        />
      </Box>
    </Modal>
  );
};

export default CreateImagePostModal;
